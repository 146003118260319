import React, { Component } from 'react';

// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
// import { createFilterOptions } from '@mui/material/Autocomplete';
// import Select from 'react-select'

import withRouter from '../../../src/WithRouter';
import SideNav from '../../layouts/SideNav';
import TopNav from '../../layouts/TopNav';
import NextIcon from '../../layouts/NextIcon';
import PrevIcon from '../../layouts/PrevIcon';
import PageTitle from '../../layouts/PageTitle';

import NonPOForm from '../../components/NonPOForm';
import SelectBank from '../../components/SelectBank';

import { GetPreviouslyUsedInvoiceReferenceIds, handleInvoiceReferenceInputChange, ajaxCall, confirmPrompt, errorAction, successAlert, Preloader, closeAlert, errorAlert, alertPrompt, tinyAlert, alertMessage } from '../../helpers/Helpers'


// const filterOptions = createFilterOptions({ limit: 100 });

class UpdateFIInvoice extends Component {
  state = {
    form: {
      description: '',
      amount: '',
      vat: true,
      fiCostObject:{label:'', value:null},
    },  
    invoice:{
      invoiceCurrency: { label: 'Select invoice currency', value: '', name: '' },
      fiCategory: { label: 'Select non-po category', value: '', name: '', costObjectRequired: false },
      tepNgCompany: { label: 'Select TotalEnergies company', value: '', name: '' },
      invoiceReferenceId:''
    },

    selectedVendor: { name: '', vendorCode: '' },

    FICategories: [],
    currencies: [],
    TepgCompany: [],
    bankDetails: [],
    checkedBankDetails:{ accountNumber: '' },
    invoiceItems: [],
    VendorList:[],

    TepgCompanyData : [],
    sortedByCostObject : [],
    costObjects: [],
    FICostObjects:[],

    isShowBankRecord: false,
    isShowInvoiceTable : true,
    isSortCostObject : false,
    referenceReponseStatus:true,
    referenceshortMsg:'Available!',
    createMultipleInvoiceCounter : null,
    Setting:{},
    
    UsedInvoice: new Set([]),

    
    multiJVInvoice: []
  }

  componentDidMount() {
    this.getInvoiceByID();
  }

  
  handleFormInputsChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value } })) }
  handleFormSelectChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.name]: [e] } })) }
  handleFormAutocompleteChange = (e, v) => { this.setState(prevState => ({ form: { ...prevState.form, [v.name]: v } })) }

  handleInvoiceInputsChange = (e) => { this.setState(prevState => ({ invoice: { ...prevState.invoice, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value } })) }
  handleInvoiceSelectChange = (e) => { this.setState(prevState => ({ invoice: { ...prevState.invoice, [e.name]: e } })) }





  getInvoiceByID = () => {
    Preloader();

    ajaxCall('Invoice/'+this.props.routerParams.id, 'json').then(res => {
      let data = res.data.data
      let invoice = this.state.invoice;

      Object.keys(data).forEach(key => {
        invoice[key] = data[key];
      });


      this.setState({invoice : invoice}, this.GetAllFICostObjects);

      let vendorCode = data.vendorCode;

      this.GetVendorsForLoggedOnVendorUser(vendorCode);
      this.GetPreviouslyUsedInvoiceReferenceIds(vendorCode,invoice.invoiceReferenceId);
      this.GetBankDetails(vendorCode);
      this.GetFICategories();
      this.GetTepngCompanies();
      this.GetAllSetting();

    })
    .catch(error => {
      errorAction(error)
    })
  }

  // !vendor_invoices reference
  GetPreviouslyUsedInvoiceReferenceIds = (vendorCode,invoiceReferenceId) => { GetPreviouslyUsedInvoiceReferenceIds(vendorCode, this, invoiceReferenceId) }

  // *! form input reference controller - binds data into state
  /** @param {any} e */
  handleInvoiceReference = (e) => { handleInvoiceReferenceInputChange(e, this) }



  GetAllFICostObjects = () =>{
    Preloader();    

    ajaxCall('Invoice/GetAllFICostObjects').then(res => {
      closeAlert();
      let FICostObjects = res.data.data 
      this.setState({ FICostObjects : FICostObjects});

      let sortedData = FICostObjects.filter(g=>g.companyCode === this.state.invoice.subsidiaryId);
      let costObjects = sortedData.map((r) => ({ value: r.costObjectNo, label: r.costObjectNo, jointVentureCode: r.jointVentureCode, name:'fiCostObject' }));

      this.setState({ costObjects: costObjects }, this.GetFIInvoiceItems);
    })
    .catch(error => {
      errorAction(error);
    })
  }

  GetFIInvoiceItems = () => {
    ajaxCall('Invoice/GetFIInvoiceItems/' + this.state.invoice.id).then(res => {
      let data = res.data.data;
      let Items = [];

      for (let i = 0; i < data.length; i++) {
        let Item = {};
        Item.ItemNo = data[i].itemNo;
        Item.Description = data[i].description;
        Item.Amount = Number(data[i].amount);
        Item.VATTaxable = data[i].vatTaxable;
        
        let filteredbyCostObject = this.state.FICostObjects.filter(g => g.costObjectNo === data[i].fiCostObject);
        let costObjects = filteredbyCostObject.map((r) => ({ value: r.costObjectNo, label: r.costObjectNo, jointVentureCode: r.jointVentureCode, name:'fiCostObject' }));
      
        Item.FICostObject = costObjects[0];
        Items.push(Item);
      }
      
      this.setState({ invoiceItems: Items });
      closeAlert();
    })
    .catch(error => {
      errorAction(error)
    })
  }

  GetTepngCompanies = () => {
    ajaxCall('TepngCompany').then(res => {       
      // save original file;
      let tepgCompanyData = res.data.data;
      this.setState({ TepgCompanyData: tepgCompanyData });

      // filter record
      let activeTepngCompany = tepgCompanyData.filter(g=>g.active === true);
      let TepgCompany = activeTepngCompany.map((r) => ({ value: r.companyCode, name: 'tepNgCompany', label: r.name }));

      this.setState({ TepgCompany: TepgCompany });

      let selectedtepNgCompany = TepgCompany.filter(g => g.value === this.state.invoice.subsidiaryId)[0];
      this.setState(prevState => ({ invoice: { ...prevState.invoice, tepNgCompany : selectedtepNgCompany } }));
    })
    .catch(error => {
      errorAction(error)
    })  
  }


  GetFICategories = () => {
    ajaxCall('FICategory').then(res => {
      let data = res.data.data.filter(g => (g.disabled === false))
      let FICategories = data.map((r) => ({ value: r.id, name: 'fiCategory', label: r.name, costObjectRequired: r.costObjectRequired }));
      this.setState({ FICategories: FICategories });

      
      let selectedFICategory = FICategories.filter(g => g.value === this.state.invoice.fiCategoryId)[0];

      this.setState(prevState => ({ invoice: { ...prevState.invoice, fiCategory : selectedFICategory } }));
    })
      .catch(error => {
        errorAction(error)
      })
  }
  /** 
   * @param {String} vendorCode */
  GetBankDetails = (vendorCode) => {
    Preloader()
    ajaxCall('Invoice/GetVendorBankDetails/' + vendorCode).then(res => {  
      let data = res.data.data;    
      this.setState({ bankDetails: data });

      let currency = [...new Map(data.map(item => [item['currency'], item])).values()];
      let currencies = currency.map((r) => ({ value: r.currency, name: 'invoiceCurrency', label: r.currency }));
      let selectedCurency = currencies.filter(g => g.value === this.state.invoice.invoiceCurrency)[0];

      this.setState(prevState => ({ invoice: { ...prevState.invoice, invoiceCurrency : selectedCurency } }));
      this.setState({ currencies: currencies });

      let selectedBank = data.filter(g => g.accountNumber === this.state.invoice.bankAccountNumber)[0];
      this.setState({ checkedBankDetails: selectedBank });

      closeAlert();
    })
    .catch(error => {
      errorAction(error);
    });
  }

  /** 
   * @param {String} vendorCode */
  GetVendorsForLoggedOnVendorUser = (vendorCode) =>{
    Preloader();        
    ajaxCall('Vendor/GetVendorsForLoggedOnVendorUser').then(res => {
      closeAlert();
      let selectedVendor = res.data.data.filter(g => g.vendorCode === vendorCode);

      if(selectedVendor[0].vendorCode === vendorCode){
        this.setState({ selectedVendor: selectedVendor[0] });
      }
      else{
        errorAlert('Error detected in your vendor code, please select the correct vendor code.');
      }
    })
    .catch(error => {
      errorAction(error);
    })
  }

  filterFICostObject = (e) =>{
    this.handleInvoiceSelectChange(e);

    let sortedData = this.state.FICostObjects.filter(g=>g.companyCode === e.value);
    let costObjects = sortedData.map((r) => ({ value: r.costObjectNo, label: r.costObjectNo, jointVentureCode: r.jointVentureCode, name:'fiCostObject' }));
    this.setState({ costObjects: costObjects });
  }
  pushIntoInvoice = () => {
    if (this.state.referenceReponseStatus !== true) { alertPrompt('Please enter a valid invoice reference number!'); return; }
    if (this.state.invoice.invoiceCurrency.value === '') { alertPrompt('Please select your invoice currency'); return; }
    if (this.state.invoice.fiCategory.value === '') { alertPrompt('Please select Non-PO category'); return; }
    if (this.state.invoice.tepNgCompany.value === '') { alertPrompt('Please select TotalEnergies company'); return; }

    let allItems = this.state.invoiceItems;
    let newItem = this.state.form;

    let Item = {};
    Item.ItemNo = (allItems.length + 1).toString();
    Item.Description = newItem.description;
    Item.Amount = Number(newItem.amount);
    Item.VATTaxable = newItem.vat;
    Item.FICostObject = newItem.fiCostObject.value !== '' ? newItem.fiCostObject : { "label": 'Select TotalEnergies Company First', "value": '' };

    if (Item.Description === '' || Item.Amount === 0) { alertPrompt('Fields cannot be empty'); return; }
    
    if (this.state.invoice.fiCategory.costObjectRequired && this.state.form.fiCostObject.value === null) {
      alertPrompt('Cost Object is required'); return;
    }

    allItems.push(Item);
    this.setState({ invoiceItems: allItems });
    this.setState(prevState => ({ form: { ...prevState.form, 'vat': true, 'amount': '', 'description': '', 'fiCostObject': { label: 'Select TotalEnergies Company First', value: null } } }));

    this.setState({ showNewItemRow: false });
  }
  

  addNewItemRow = () => {
    this.setState({ showNewItemRow: true });
  }

  removeInvoice = (id) => {
    this.setState({ invoiceItems: this.state.invoiceItems.filter(g => !(g.ItemNo === id)) }, this.refreshItems);
  }

  showAllTepngCompany = () =>{
    let tepgCompanyData = this.state.TepgCompanyData;
    let TepgCompany = tepgCompanyData.map((r) => ({ value: r.companyCode, name: 'tepNgCompany', label: r.name}));
    this.setState({ TepgCompany: TepgCompany });
  }

  handleChangeInfo = (e, x) => {
    if(e.name === 'fiCostObject'){
      this.setState({
        invoiceItems: this.state.invoiceItems.map(g => g.ItemNo === x.ItemNo ? { ...g, 'FICostObject' : e } : g)
      })
    }
    else{
      this.setState({
        invoiceItems: this.state.invoiceItems.map(g => g.ItemNo === x.ItemNo ? { ...g, [e.target.name] : e.target.type === 'checkbox' ? e.target.checked : e.target.value } : g)
      })
    }
  }

  ShowBank = () => {
    this.setState({ isShowBankRecord: true, isShowInvoiceTable: false });
  }


  GetAllSetting= () =>{
    Preloader();    

    ajaxCall('Setting').then(res => {
      closeAlert();
      this.setState({ Setting: res.data.data });
    })
    .catch(error => {
      errorAction(error);
    })
  }

  ShowSortedCostObject2 = () => {
    let invoiceItems = this.state.invoiceItems.reduce((acc, item) => {
      let foo = acc[item.FICostObject[0].jointVentureCode] ? acc[item.FICostObject[0].jointVentureCode] : [];
      return {
        ...acc,
        [item.FICostObject[0].jointVentureCode] : [
          ...foo,
          item
        ],
      };
    }, {});

    let data = Object.values(invoiceItems);

    let dataRef = data.map((e, i) => {
      e.map((x) => {
        x.invoiceReferenceId = i === 0 ? this.state.invoice.invoiceReferenceId : this.state.invoice.invoiceReferenceId + '-' + i;
        return null
      })

      return [...e]
    });

    this.setState({ isShowInvoiceTable : false, isSortCostObject : true, isShowBankRecord : false, sortedByCostObject : dataRef });
  }

  ShowSortedCostObject = () => {
    console.log(this.state.invoiceItems);

    let invoiceItems = this.state.invoiceItems.reduce((acc, item) => {
      let foo = acc[item.FICostObject.jointVentureCode] ? acc[item.FICostObject.jointVentureCode] : [];
      return {
        ...acc,
        [item.FICostObject.jointVentureCode]: [
          ...foo,
          item
        ],
      };
    }, {});

    let data = Object.values(invoiceItems);

    let dataRef = data.map((e, i) => {
      e.map((x) => {
        x.invoiceReferenceId = i === 0 ? this.state.invoice.invoiceReferenceId : this.state.invoice.invoiceReferenceId + '-' + i;
        return null
      })

      return [...e]
    });


    this.setState({ isShowInvoiceTable: false, isSortCostObject: true, isShowBankRecord: false, sortedByCostObject: dataRef });
  }


    /**
   * @param {any} x
   * @param {String} y
   * @return {boolean}
 */
    validateInvoiceCurrencies = (x, y) => {
      let currency = x?.value

      console.log(currency, y)
  
      let invoiceCurrency = currency.replace(/[0-9]/g, '');
      let currencyView = y.replace(/[0-9]/g, '');
  
      if (invoiceCurrency === currencyView)
        return false;
  
      else if ((invoiceCurrency !== currencyView) && (invoiceCurrency === "NGN"))
        return true;
  
      else {
        if (currencyView !== "NGN")
          return false;
  
        return true;
      }
    }
    
  
  HideBank = () => {
    this.setState({ isShowBankRecord: false, isShowInvoiceTable: true });
  }

  setBankAccount = (e) => {
    if (this.state.invoice.invoiceCurrency.value === '') {
      errorAlert('Please select invoice currency first');

      /** @type HTMLInputElement */
      let input = document.querySelector('#' + e.target.id);
      input.checked = false;

      return;
    }

    let bankinfo = this.state.bankDetails[e.target.value];

    let currency = this.state.invoice.invoiceCurrency.value.replace(/[0-9]/g, '');
    let selected_currency = bankinfo.currency.replace(/[0-9]/g, '');

    if (selected_currency !== currency)
      alertMessage('You have selected ' + selected_currency + ' for an invoice with ' + currency + ' currency. <br> Are you sure you want to continue with ' + selected_currency + ' currency?');

    this.setState({ checkedBankDetails: bankinfo });
  }
  
  HideSortCostObject = () => {
    this.setState({ isShowInvoiceTable: false, isSortCostObject: false, isShowBankRecord: true });
  }

  refreshItems = () => {
    this.setState({ invoiceItems: this.state.invoiceItems.map((r, i) => ({ ...r, ItemNo: i + 1 })) })
  }

  UpdateFIInvoice = (cansign) => {
    if (this.state.invoiceItems.length < 1) {
      confirmPrompt('Please complete your form to continue.')
      return;
    }

    let data = {
      InvoiceReferenceId: this.state.invoiceRef,
      InvoiceCurrency: this.state.form.invoiceCurrency[0].value,

      BankName: this.state.checkedBankDetails[0].bankName,
      BankAccountNumber: this.state.checkedBankDetails[0].accountNumber,

      FICategoryId: this.state.form.fiCategory[0].value,
      SubsidiaryId: this.state.form.tepNgCompany[0].value,
      Items: this.state.invoiceItems,
      costObject:this.state.form.costObject
    };

    Preloader();
      ajaxCall('Invoice/UpdateFIInvoice/' + this.state.invoice.id, 'json', 'patch', data).then(res => {
        if (cansign === 'saveSign') {
          successAlert('Invoice was successfully updated');
          closeAlert();
          this.props.routerNavigate('/sign-fi-invoice?id=' + res.data.data.invoice.id)
        }
        else {
          successAlert('Invoice was successfully updated');
          closeAlert();
          this.props.routerNavigate('/sign-fi-invoice?id=' + res.data.data.invoice.id)
        }
      })
      .catch(error => {
        errorAction(error)
      })
  }

  UpdateFIInvoiceMultiJV = (cansign) => {
    if (this.state.sortedByCostObject.length < 1) { errorAlert('Please complete your form to continue.'); return; }
    
    let i;
    if(this.state.createMultipleInvoiceCounter === null){
      this.setState({createMultipleInvoiceCounter: this.state.sortedByCostObject.length - 1});
      i = this.state.sortedByCostObject.length - 1;
    }
    else{
      i = this.state.createMultipleInvoiceCounter - 1;
    }

    let items = this.state.sortedByCostObject[i].map((row, i) => {
      return {
        "ItemNo": (i + 1).toString(),
        "Description": row.Description,
        "Amount": row.Amount,
        "VATTaxable": row.VATTaxable,
        "FICostObject": row.FICostObject.value
      }
    });

    console.log(items)

    let data = {
      InvoiceReferenceId: this.state.sortedByCostObject[i][0].InvoiceReferenceId,
      InvoiceCurrency: this.state.form.invoiceCurrency.value,
      BankName: this.state.checkedBankDetails.bankName,
      BankAccountNumber: this.state.checkedBankDetails.accountNumber,
      FICategoryId: this.state.form.fiCategory.value,
      SubsidiaryId: this.state.form.tepNgCompany.value,
      vendorCode: this.state.selectedVendor.vendorCode,
      Items: items,
    };

    Preloader();
    ajaxCall('Invoice/UpdateFIInvoice/' + this.state.invoice.id,'json', 'patch', data).then(res => {
      
      if (cansign === 'saveSign'){
        tinyAlert('success', 'Invoice ' + data.InvoiceReferenceId + ' is created successfully, please upload relevant documents?');
      }
      else{
        tinyAlert('success', 'Invoice ' + data.InvoiceReferenceId + ' is saved to draft!');
      }


      if(i === 0){
        this.setState({createMultipleInvoiceCounter: null});
        if (cansign === 'saveSign'){
          this.props.routerNavigate('/sign-fi-invoice/' + res.data.data.id);
        }
        else{
          this.props.routerNavigate("/history");
        }
      }
      else{
        this.UpdateFIInvoiceMultiJV(cansign);
      }

      closeAlert();
    })
    .catch(error => {
      errorAction(error);
    })
  }

  CreateFIInvoiceMultiJV = (cansign) => {
    if (this.state.sortedByCostObject.length < 1) { errorAlert('Please complete your form to continue.'); return; }

    let incremental;
    if (this.state.createMultipleInvoiceCounter === null) {
      incremental = this.state.sortedByCostObject.length - 1;
      this.setState({ createMultipleInvoiceCounter: incremental });
    }
    else {
      incremental = this.state.createMultipleInvoiceCounter - 1;
      this.setState({ createMultipleInvoiceCounter: incremental });
    }

    console.log(incremental);

    let items = this.state.sortedByCostObject[incremental].map((row, i) => {
      return {
        "ItemNo": (i + 1).toString(),
        "Description": row.Description,
        "Amount": row.Amount,
        "VATTaxable": row.VATTaxable,
        "FICostObject": row.FICostObject.value
      }
    });

    let data = {
      InvoiceReferenceId: this.state.sortedByCostObject[incremental][0].invoiceReferenceId,
      InvoiceCurrency: this.state.invoice.invoiceCurrency.value,
      BankName: this.state.checkedBankDetails.bankName,
      BankAccountNumber: this.state.checkedBankDetails.accountNumber,
      FICategoryId: this.state.invoice.fiCategory.value,
      SubsidiaryId: this.state.invoice.tepNgCompany.value,
      vendorCode: this.state.selectedVendor.vendorCode,
      Items: items,
    };

    Preloader();
    
    ajaxCall('Invoice/UpdateFIInvoice/' + this.state.invoice.id,'json', 'patch', data).then(res => {
      if (cansign === 'saveSign') {
        tinyAlert('success', 'Invoice ' + data.InvoiceReferenceId + ' is created, please upload relevant documents?');
      }
      else {
        tinyAlert('success', 'Invoice ' + data.InvoiceReferenceId + ' is saved to draft!');

        let multi_jv_data = this.state.multiJVInvoice;
        multi_jv_data.push(res.data.data);
        this.setState({ multiJVInvoice: multi_jv_data });
      }

      if (incremental === 0) {
        this.setState({ createMultipleInvoiceCounter: null });
        if (cansign === 'saveSign') {
          this.props.routerNavigate('/sign-fi-invoice/' + res.data.data.id);
        }
        else {
          // this.setState({ isFinalView: true });
          // check if its multijv
          this.props.routerNavigate("/history");
        }
      }
      else {
        this.CreateFIInvoiceMultiJV(cansign);
      }

      closeAlert();
    })
      .catch(error => {
        errorAction(error);
      })
  }

  updateSortedRefNo = (e, i) =>{
    let invoiceReferenceId = e.target.value;
    let prevdata = this.state.sortedByCostObject;
    prevdata[i][0].invoiceReferenceId = invoiceReferenceId.replace(/\s/g, "");   
    this.setState({ sortedByCostObject : prevdata });
  }






  render() {    
    const __this__ = this;
    const page_info = { page: 'Update Non-PO Invoice', breadcrumb: 'Create New Non-PO Invoice' };
    const store = this.state;

    return (
      <div className='mainApp'>
        <div className='container-fluid'>
          <div className='row'>
            {/* fixed side nav area */}
            <SideNav />

            <div className='main-content-wrap'>
              {/* fixed top area */}
              <TopNav />

              {/* Page title and breadcrumb */}
              <PageTitle pagedata={page_info} />

              <div className='container-fluid mb-5'>
                <div className='row'>

                  <div className='col-12 px-4 mb-5 sm-px-4'>

                    {/* form elements */}
                    { store.isShowInvoiceTable && 
                      <div className="mt-4 mt-sm">
                        <h6 className='py-3 px-4 mb-0 lead-txt'>
                          Update Non-PO Invoice 
                        </h6>
                        <hr className='m-0' />                        
                        
                        {/* NonPOForm section */}
                        <NonPOForm parent={__this__} />

                      </div>
                    }


                    { this.state.isShowBankRecord && 
                      <div className="mt-4 mt-sm bg-white">                        

                        {/* SelectBank section */}
                        <SelectBank parent={__this__} />


                        <div className='row pt-2 py-3 px-4'>
                          <div className='col-sm-8 col-12'></div>
                          <div className='col-sm-2 col-12 mb-3'>
                              <button type='button'  title="go back"  onClick={this.HideBank}className='btn btn-danger font-12 border-radius-sm form-control'> <PrevIcon /> Back</button>
                          </div>
                          <div className='col-sm-2'>
                            <div className="form-group mb-3">
                              <button type='button' title="Next" onClick={this.ShowSortedCostObject}  disabled={this.state.checkedBankDetails.accountNumber === ''} className='btn btn-primary font-12 border-radius-sm form-control'>
                                Next <NextIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    }                    


                    { this.state.isSortCostObject && 
                      <div className="mt-4 mt-sm bg-white">
                        <div className="mt-3">
                          <h6 className='py-3 px-4 mb-0 lead-txt'>
                            Updated Invoice Summary
                          </h6>                        
                          <hr className='m-0' />
                          
                          {
                            this.state.sortedByCostObject.length > 1 &&
                            <div className='info-alert font-14 p-2 mt-1 p-4'>VIMP System have split your invoice into multiple invoices because your invoice is a Multi-JV</div>
                          }                      
                        </div>

                        <div className="mt-1 mt-sm">
                          {
                            this.state.sortedByCostObject.map((data_row, i) => {
                              return (
                                <div className='mt-2' style={{border: '3px dotted #f3f2f2', borderTop:'10px solid #f7f7f7'}} key={i}>
                                  <div className="mt-0 mb-0 mt-sm bg-white" >
                                    <div className='pt-3 py-3 px-4'>
                                      <div className='row mt-0 sm-mt-1 mb-1'>
                                        
                                        <div className='col-12 col-sm-12'>
                                          <p><u>Invoice {i + 1} of {this.state.sortedByCostObject.length}</u></p>
                                        </div>
                                        <div className='col-12 col-sm-2'>
                                          <label>Reference number:</label>
                                        </div>
                                        <div className='col-12 col-sm-10'>
                                          <div className="form-group mb-0">
                                            <input type="text" value={data_row[0].invoiceReferenceId} className="form-control input-font bold" onChange={(e) => { this.updateSortedRefNo(e, i) }} />
                                          </div>
                                        </div>
                                      </div>

                                      <div className='row mt-0 sm-mt-1 mb-1'>
                                        <div className='col-12 col-sm-2'>
                                          <label>Bank name:</label>
                                          {/* <span>Bank name:</span> */}
                                        </div>
                                        <div className='col-12 col-sm-10'>
                                          <div className="form-group mb-0">
                                            <input type="text" value={this.state.checkedBankDetails.bankName} className="form-control input-font bold" disabled />
                                          </div>
                                        </div>
                                      </div>

                                      <div className='row mt-0 sm-mt-1 mb-1'>
                                        <div className='col-12 col-sm-2'>
                                          <label>Account number:</label>
                                        </div>
                                        <div className='col-12 col-sm-10'>
                                          <div className="form-group mb-0">
                                            <input type="text" value={this.state.checkedBankDetails.accountNumber} className="form-control input-font bold" disabled />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="table-responsive">
                                        <table className="table font-12 table-striped table-hover mb-0 table-bordered">
                                          <thead className='text-white'>
                                            <tr>
                                              <th className='text-center' style={{width:'63px'}}>Item No</th>
                                              <th className="w-220">Description</th>
                                              <th style={{width:'180px'}}>Amount [ {this.state.invoice.invoiceCurrency.value.replace(/[0-9]/g, '')} ]</th>
                                              <th style={{width:'180px'}}>Cost Object</th>
                                              <th style={{width:'50px'}}>Vat</th>
                                            </tr>
                                          </thead>
                                          <tbody className='font-12'>
                                          {
                                            data_row.map((item, i) => {
                                              return(
                                                <tr key={'inner' + i}>
                                                    <td className='text-center'><i className='w-60'>{i+1}</i></td>
                                                    <td> 
                                                      <input type="text" name='Description' disabled value={item.Description} className="w-220 form-control input-font p-1" />
                                                    </td>
                                                    <td>
                                                      <input type="number" name='Amount' disabled value={item.Amount} className="form-control input-font p-1 w-100" />
                                                    </td>
                                                    <td>
                                                      <input type="text" name='FICostObject' disabled value={item.FICostObject.value} className="form-control input-font p-1 w-100" />
                                                    </td>
                                                    <td className='text-center'>
                                                      <div className="form-check form-switch">                              
                                                        <input className="form-check-input" disabled type="checkbox" name='VATTaxable' checked={item.VATTaxable} />
                                                      </div>
                                                    </td>
                                                </tr>
                                              )
                                            })
                                          }
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }

                          <div className='row py-3 px-4'>
                            <div className={this.state.sortedByCostObject.length === 1 ? 'col-sm-6 col-12' : 'col-sm-8 col-12' }></div>

                            <div className='col-sm-2 col-12 mb-3'>
                                <button type='button'  title="go back"  onClick={this.HideSortCostObject}className='btn btn-danger font-12 border-radius-sm form-control'> <PrevIcon /> Back</button>
                            </div>              
                                        
                            <div className='col-sm-2 col-6'>
                              <div className="form-group mt-0 mb-5">
                                <button type='button' disabled={this.state.checkedBankDetails.length < 1} onClick={()=> confirmPrompt("Do you want to save to draft?").then( (r) => r.isConfirmed ? this.CreateFIInvoiceMultiJV('saveDraft') : false)} name="saveDraft" title="Save invoice to sign later" className='savedraftbtn btn btn-primary font-12 border-radius-sm form-control'>{this.state.sortedByCostObject.length > 1 ? 'Save all to draft' : 'Save as draft'}</button>
                              </div>
                            </div>

                            {
                            this.state.sortedByCostObject.length === 1 &&
                            <div className='col-sm-2 col-6'>
                              <div className="form-group mt-0 mb-5">
                                <button type='button' disabled={this.state.checkedBankDetails.length < 1} onClick={()=>confirmPrompt("Do you want to save and procced to upload relevant documents?").then( (r) => r.isConfirmed ? this.CreateFIInvoiceMultiJV('saveSign') : false)} name="saveSign" title="Save invoice" className='btn btn-primary font-12 border-radius-sm form-control'>Submit</button>
                              </div>
                            </div>
                            }

                          </div>
                        </div>
                      </div>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UpdateFIInvoice);