import React, { Component } from 'react';
import withRouter from '../../../src/WithRouter';
import SideNav from '../../layouts/SideNav';
import TopNav from '../../layouts/TopNav';
import PrevIcon from '../../layouts/PrevIcon';
import NextIcon from '../../layouts/NextIcon';
import PageTitle from '../../layouts/PageTitle';

import InvoiceReferenceInput from '../../components/InvoiceReferenceInput';
import SESLineItem from '../../components/SESLineItem';
import GRLineItem from '../../components/GRLineItem';
import SelectBank from '../../components/SelectBank';
import {recordPerRows, setRecordsForCurrentPage, updateTablePagination, filterSESTable, GetPreviouslyUsedInvoiceReferenceIds, GetVendor, getInvoiceByID, handleInvoiceReferenceInputChange, ajaxCall, alertMessage, closeAlert, confirmPrompt, errorAction, Preloader, successAlert, tinyAlert, formatAmount } from '../../helpers/Helpers'






// ! React class component
class UpdateInvoice extends Component {
  state = {
    invoice:{
      invoiceReferenceId:''
    },
    UsedInvoice: new Set([]),
    invoiceLineItem: [],

    currentPageSESs: [],
    filteredSESs: [],
    availableSESs: [],
    currentPageGRs: [],
    filteredGRs: [],
    availableGRs: [],
    bankDetails: [],
    checkedBankDetails: {accountNumber:''},
    vendor:{partInvoicingEnabled:false},
    numRecordsPerPage: 5,
    currentPage: 1,
    numOfPages: 1,
    referenceReponseStatus:true,
    referenceshortMsg:'Available!',
  }

  // ! onLoad functions
  componentDidMount() {
    this.getInvoiceByID();
  }

  // ! get invoice by unique ID
  getInvoiceByID = () => { getInvoiceByID(this.props.routerParams.id, this, this.GroupFunction) }

  // !! combine fn call
  GroupFunction = () => {
    this.GetVendor();
    this.getInvoiceLineItem();
    this.GetPreviouslyUsedInvoiceReferenceIds();
  }

  // ! get vendor for selected vendor ID
  GetVendor = () => { GetVendor(this.state.invoice.vendorCode, this) }

  // !vendor_invoices reference
  GetPreviouslyUsedInvoiceReferenceIds = () => { GetPreviouslyUsedInvoiceReferenceIds(this.state.invoice.vendorCode, this, this.state.invoice.invoiceReferenceId) }

  // ! get invoice line item
  getInvoiceLineItem = () => {
    Preloader();
    let url_ext = '';

    if (this.state.invoice.invoiceType === "Material PO") 
      url_ext = 'GetMaterialInvoiceGRs/' + this.state.invoice.id;
    else
      url_ext = 'GetServiceInvoiceSESs/' + this.state.invoice.id;

    ajaxCall('Invoice/' + url_ext).then(res => {
      if (this.state.invoice.invoiceType === "Material PO") {
        this.setState({ invoiceLineItem: res.data.data });
        this.GetAvailabelGR(res.data.data[0].poNo);
      }
      else {
        this.setState({ invoiceLineItem: res.data.data });
        this.GetAvailabelSES(res.data.data[0].poNo);
      }

      closeAlert();
    })
    .catch(error => {
      errorAction(error)
    })
  }

  // get all available invoice for selected PO
  GetAvailabelSES = (PoNumber) => {
    ajaxCall('Invoice/GetAvailableSES/'+ this.state.invoice.vendorCode + '/' + PoNumber + '/' + this.state.invoice.id).then(res => {
      this.setState({
        availableSESs: res.data.data.filter(i => this.state.invoiceLineItem.find(r => r.sesItemNo === i.sesItemNo && r.sesNo === i.sesNo) == null),
        invoiceLineItem: this.state.invoiceLineItem.map(i => ({...i,  MaxAmount: res.data.data.find(r => r.sesItemNo === i.sesItemNo && r.sesNo === i.sesNo).sesAmount}))
        }, this.filterSESTable);

        closeAlert();
    })
  }

  // ! filter SES table
  filterSESTable = (e) => { filterSESTable(e, this, this.updateTablePagination) }

  // ! update table pagination
  updateTablePagination = () => { updateTablePagination(this, this.setRecordsForCurrentPage)}

  // ! set record to display for current page
  setRecordsForCurrentPage = () => { setRecordsForCurrentPage(this) }

  // *! form input reference controller - binds data into state
  /** @param {any} e */
  handleInvoiceReference = (e) => { handleInvoiceReferenceInputChange(e, this) }


  GetAvailabelGR = (PoNumber) => {
    ajaxCall('Invoice/GetAvailableGR/'+ this.state.invoice.vendorCode + '/'+ PoNumber + '/' + this.state.invoice.id).then(res => {
      this.setState({
        availableGRs: res.data.data.filter(i => this.state.invoiceLineItem.find(r => r.grItemNo===i.grItemNo && r.grNo === i.grNo) == null),

        invoiceLineItem: this.state.invoiceLineItem.map(i => ({...i, MaxQuantity: res.data.data.find(r => r.grItemNo === i.grItemNo && r.grNo === i.grNo).grQuantity, MaxAmount: res.data.data.find(r => r.grItemNo === i.grItemNo && r.grNo === i.grNo).grAmount}))
        }, this.filterGRTable);

        closeAlert();
    })
  }
  filterGRTable = (e) => {
    let result
    if (e != null) {
      let search = e.target.value.toLowerCase();
      result = this.state.availableGRs.filter((data) => data.materialDescription.toLowerCase().includes(search) || data.grNo.includes(search) || data.grAmount.toString().includes(search));
    }
    else
      result = this.state.availableGRs
    this.setState({ filteredGRs: result.map((r, i) => ({ ...r, index: i + 1 })) }, this.updateTablePagination);
  }
  setNumRecordsPerPage = (e) => {
    this.setState({ numRecordsPerPage: Number(e.target.value) }, this.updateTablePagination);
  }
  nextPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) + 1 }, this.setRecordsForCurrentPage);
  }
  previousPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) - 1 }, this.setRecordsForCurrentPage);
  }


  /**  
   * Update GR Object
   */
  handleChangeGRQty = (e, GR) => {
    let grData = this.state.invoiceLineItem;
    let list = [];
    let qty = Number(e.target.value);

    for (let i = 0; i < grData.length; i++) {
      if (grData[i].grNo === GR.grNo && grData[i].grItemNo === GR.grItemNo) {
        grData[i].partQty = Number(qty.toFixed(2));
        grData[i].partAmount = Number(((qty * grData[i].MaxAmount) / grData[i].MaxQuantity).toFixed(2))

        if (grData[i].partQty > grData[i].MaxQuantity) {
          grData[i].partQty = grData[i].MaxQuantity;
          grData[i].partAmount = grData[i].MaxAmount;
        }

        list.push(grData[i]);
      }
      else
        list.push(grData[i]);
    }
    this.setState({ invoiceLineItem: list });
  }
  handleChangeGRAmount = (e, GR) => {
    let grData = this.state.invoiceLineItem;
    let list = [];
    let amt = Number(e.target.value);

    for (let i = 0; i < grData.length; i++) {
      if (grData[i].grNo === GR.grNo && grData[i].grItemNo === GR.grItemNo) {
        grData[i].partAmount = Number(amt.toFixed(2));
        grData[i].partQty = Number(((amt * grData[i].MaxQuantity) / grData[i].MaxAmount).toFixed(2))

        if (grData[i].partAmount > grData[i].MaxAmount) {
          grData[i].partAmount = grData[i].MaxAmount;
          grData[i].partQty = grData[i].MaxQuantity;
        }

        list.push(grData[i]);
      }
      else
        list.push(grData[i]);
    }
    this.setState({ invoiceLineItem: list });
  }
  handleChangeGRDescription = (e, GR) => {
    let grData = this.state.invoiceLineItem;
    let list = [];
    let description = e.target.value;

    for (let i = 0; i < grData.length; i++) {
      if (grData[i].grNo === GR.grNo && grData[i].grItemNo === GR.grItemNo) {
        grData[i].vendorMaterialDescription = description;
        list.push(grData[i]);
      }
      else
        list.push(grData[i]);
    }
    this.setState({ invoiceLineItem: list });
  }
  handleCheckedGRVAT = (e, GR) => {
    let checked = e.target.checked;
    this.setState({
      invoiceLineItem: this.state.invoiceLineItem.map(g => g.grNo === GR.grNo && g.grItemNo === GR.grItemNo ? { ...g, vatTaxable: checked } : g)
    })
  }
  handleRemoveGR = (GR) => {
    this.setState({
      invoiceLineItem: this.state.invoiceLineItem.filter(g => !(g.grNo === GR.grNo && g.grItemNo === GR.grItemNo))
    })
  }
  handleAddGR = (GR) => {
    GR.vatTaxable = true;
    GR.MaxAmount = GR.grAmount;
    GR.MaxQuantity = GR.grQuantity;
    GR.invoiceId = this.state.invoiceLineItem[0].invoiceId
    
    let newRecord = this.state.invoiceLineItem
    newRecord.push(GR);

    this.setState({
      currentPageGRs: this.state.currentPageGRs.filter(g => !(g.grNo === GR.grNo && g.grItemNo === GR.grItemNo)),
      filteredGRs: this.state.filteredGRs.filter(g => !(g.grNo === GR.grNo && g.grItemNo === GR.grItemNo)),
      availableGRs: this.state.availableGRs.filter(g => !(g.grNo === GR.grNo && g.grItemNo === GR.grItemNo)),
      invoiceLineItem: newRecord,
    })
  }


  /**  
   * Update SES Object
   */
  handleChangeSESAmount = (e, SES) => {
    let sesData = this.state.invoiceLineItem;
    let list = [];
    let amt = Number(e.target.value);

    for (let i = 0; i < sesData.length; i++) {
      if (sesData[i].sesNo === SES.sesNo && sesData[i].sesItemNo === SES.sesItemNo) {
        sesData[i].partAmount = Number(amt.toFixed(2));

        if (sesData[i].partAmount > sesData[i].MaxAmount) {
          sesData[i].partAmount = sesData[i].MaxAmount;
        }

        list.push(sesData[i]);
      }
      else
        list.push(sesData[i]);
    }
    this.setState({ invoiceLineItem: list });
  }
  handleChangeSESDescription = (e, SES) => {
    let sesData = this.state.invoiceLineItem;
    let list = [];
    let description = e.target.value;

    for (let i = 0; i < sesData.length; i++) {
      if (sesData[i].sesNo === SES.sesNo && sesData[i].sesItemNo === SES.sesItemNo) {
        sesData[i].vendorSESItemDescription = description;
        list.push(sesData[i]);
      }
      else
        list.push(sesData[i]);
    }
    this.setState({ invoiceLineItem: list });
  }
  handleCheckedSESVAT = (e, SES) => {
    let checked = e.target.checked;
    this.setState({
      invoiceLineItem: this.state.invoiceLineItem.map(g => g.sesNo === SES.sesNo && g.sesItemNo === SES.sesItemNo ? { ...g, vatTaxable: checked } : g)
    })
  }
  handleRemoveSES = (SES) => {
    this.setState({
      invoiceLineItem: this.state.invoiceLineItem.filter(g => !(g.sesNo === SES.sesNo && g.sesItemNo === SES.sesItemNo))
    })
  }
  handleAddSES = (SES) => {
    SES.vatTaxable = true;
    SES.MaxAmount = SES.sesAmount;
    SES.invoiceId = this.state.invoiceLineItem[0].invoiceId
    
    let newRecord = this.state.invoiceLineItem
    newRecord.push(SES);

    this.setState({
      currentPageSESs: this.state.currentPageSESs.filter(g => !(g.sesNo === SES.sesNo && g.sesItemNo === SES.sesItemNo)),
      filteredSESs: this.state.filteredSESs.filter(g => !(g.sesNo === SES.sesNo && g.sesItemNo === SES.sesItemNo)),
      availableSESs: this.state.availableSESs.filter(g => !(g.sesNo === SES.sesNo && g.sesItemNo === SES.sesItemNo)),
      invoiceLineItem: newRecord,
    })
    
    this.setState({ isBankAccount: false });
  }


  // ! Bank and currencies logic section
  showAccontDetails = () => {
    // validate if all SES custom description has value;
    if(this.state.invoice.invoiceType !== 'Material PO'){
      const validation = this.state.invoiceLineItem.every(item => item.vendorSESItemDescription);
      if(!validation){
        tinyAlert('error','Custom description is requied')
        return;
      }
    }

    this.GetBankDetails();
  }
  GetBankDetails = () => {
    Preloader();
    ajaxCall('Invoice/GetVendorBankDetails/' + this.state.invoice.vendorCode).then(res => {
      closeAlert();
      let data = res.data.data;
      this.setState({ bankDetails: data })
      this.setState({ isBankAccount: true });

      let selectedBank = data.filter(g => g.accountNumber === this.state.invoice.bankAccountNumber)[0];
      this.setState({ checkedBankDetails: selectedBank });
    })
    .catch(error => {
      errorAction(error)
    })
  }
  /**
   * @param {String} x
   * @param {String} y
   * @return {boolean}
 */
  validateInvoiceCurrencies = (x,y) => {
    let invoiceCurrency = x.replace(/[0-9]/g, '');
    let currencyView = y.replace(/[0-9]/g, '');

    if(invoiceCurrency === currencyView)
      return false;

    else if((invoiceCurrency !== currencyView) && (invoiceCurrency === "NGN"))
      return true;

    else{     
      if(currencyView !== "NGN")
          return false; 

      return true;
    }
  }
  setBankAccount = (e) => {
    let i = e.target.value;
    let bankinfo = this.state.bankDetails[i];

    let currency = this.state.invoiceLineItem[0].currency.replace(/[0-9]/g, '');
    let selected_currency = bankinfo.currency.replace(/[0-9]/g, '');

    if (selected_currency !== currency)       
      alertMessage('You have selected '+ selected_currency +' for an invoice with '+ currency +' currency. <br> Are you sure you want to continue with '+ selected_currency +' currency?');

    this.setState({ checkedBankDetails: bankinfo });
  }
  

  // ! update invoice section
  handleSaveInvoice = (e) => {
    confirmPrompt('Do you want to update invoice?').then((r) => {
      if (r.isConfirmed) {
        if (this.state.invoice.invoiceType === 'Material PO') {
          this.UpdateMateriaInvoice();
        }
        else{
          this.UpdateServiceInvoice();
        }
      }
    })
  }
  UpdateServiceInvoice = () => {
    let checkedSESList = this.state.invoiceLineItem;
    let selectedSESs = [];

    for (let i = 0; i < checkedSESList.length; i++) {
      selectedSESs.push({ 
        SesNo: checkedSESList[i].sesNo, 
        vendorSESItemDescription:checkedSESList[i].vendorSESItemDescription, 
        SESAmount: checkedSESList[i].partAmount ? checkedSESList[i].partAmount : checkedSESList[i].sesAmount, 
        SESItemNo: checkedSESList[i].sesItemNo, 
        VATTaxable: checkedSESList[i].vatTaxable === undefined ? true : checkedSESList[i].vatTaxable === true ? true : false 
      });
    }

    let data = {
      InvoiceReferenceId: this.state.invoice.invoiceReferenceId,
      PONo: checkedSESList[0].poNo,
      BankName: this.state.checkedBankDetails.bankName,
      BankAccountNumber: this.state.checkedBankDetails.accountNumber,
      SelectedSESs: selectedSESs,
      vendorCode:this.state.invoice.vendorCode,
    };

    ajaxCall('Invoice/UpdateServiceInvoice/'+this.state.invoice.id, 'json', 'patch', data)
    .then(res => {
        successAlert('Invoice updated successfully');
    })
    .catch(error => {
      errorAction(error)
    });
  }
  UpdateMateriaInvoice = () => {
    let checkedGRList = this.state.invoiceLineItem;

    let selectedGRLists = [];
    for (let i = 0; i < checkedGRList.length; i++) {
      selectedGRLists.push({ GrNo: checkedGRList[i].grNo, vendorMaterialDescription:checkedGRList[i].vendorMaterialDescription, GRItemNo: checkedGRList[i].grItemNo, GRAmount: checkedGRList[i].partAmount ? checkedGRList[i].partAmount : checkedGRList[i].grAmount, VATTaxable: checkedGRList[i].vatTaxable === undefined ? true : checkedGRList[i].vatTaxable === true ? true : false });
    }

    let data = {
      InvoiceReferenceId: this.state.invoice.invoiceReferenceId,
      PONo: checkedGRList[0].poNo,
      BankName: this.state.checkedBankDetails.bankName,
      BankAccountNumber: this.state.checkedBankDetails.accountNumber,
      SelectedGRs: selectedGRLists,
      vendorCode:this.state.invoice.vendorCode,
    };

    ajaxCall('Invoice/UpdateMaterialInvoice/'+this.state.invoice.id, 'json', 'patch', data).then(res => {
      successAlert('Invoice was successfully updated to draft');
    })
    .catch(error => {
      errorAction(error)
    })
  }



  render() {
    const __this__ = this;
    const page_info = { page: 'Update Invoice', breadcrumb: 'Update Existing Invoice' };
    const store = this.state;

    return (
      <div className='mainApp'>
        <div className='container-fluid'>
          <div className='row'>
            {/* fixed side nav area */}
            <SideNav />

            <div className='main-content-wrap'>
              {/* fixed top area */}
              <TopNav />

              {/* Page title and breadcrumb */}
              <PageTitle pagedata={page_info} />

              <div className='container-fluid mb-5'>
                <div className='row'>

                  <div className='col-12 px-4 mb-5'>
                    {/* selected SES/GR line item area */}
                    <div className="mt-3">
                      <h6 className='py-3 px-4 mb-0 lead-txt'> Update Invoice </h6>                        
                      {
                      (store.invoice.invoiceType === 'Material PO') ?
                        <GRLineItem parent={__this__} />
                      :
                        <SESLineItem parent={__this__} />
                      }
                    </div>

                    {/* open SES/GR lineItem modal and view banks button */}
                    <div className='row'>
                      <div className="col-2">
                          <button type="button" className="btn btn-danger font-12 border-radius-sm form-control mt-1" data-bs-toggle="modal" data-bs-target='#line_item_modal'>
                              Manage List &nbsp;&nbsp; <i className='fa fa-plus' style={{ background: '#fff', color: '#dc3545', padding: '3px 6px', borderRadius: '1px', boxShadow: '0px 1px 5px 0px #80808057' }}></i>
                          </button>
                      </div>
                      <div className='col-sm-2 col-6'>
                          <div className="form-group mt-1 mb-5">
                              <button type='button' onClick={this.showAccontDetails} className='btn btn-primary font-12 border-radius-sm form-control'>Select Bank</button>
                          </div>
                      </div>
                    </div>

                    {/* update relevant bank details and update */}
                    <div>
                      { (this.state.isBankAccount) &&
                        <div>      
                          {/* select relevant bank component */}
                          <SelectBank parent={__this__} />

                          {/* Enter invoice refernce number */}
                          <InvoiceReferenceInput parent={__this__} />

                          <div className='row mt-3 mb-3'>
                            <div className='col-sm-2 col-12'>
                              <div className="form-group mt-0 mb-5">
                                <button type='button' disabled={this.state.checkedBankDetails.accountNumber === '' || this.state.invoice.invoiceReferenceId === ''} onClick={this.handleSaveInvoice} name="saveDraft" className='btn btn-primary font-12 border-radius-sm form-control'>Update</button>
                              </div>
                            </div>
                            <div className='col-sm-10'></div>
                          </div>
                        </div>   
                      }
                    </div>
                    
                    {/* MODAL :: Available SES ITEMS that can be added to invoice line items */}
                    <div className="modal" id="line_item_modal" tabIndex={-1} aria-labelledby="ses_item_modalLabel" aria-hidden="true">
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content mt-5">
                          <div className="modal-header">
                            <h5 className="modal-title font-14">{(store.invoice.invoiceType === 'Material PO') ? 'Available GR' : 'Available SES'}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          
                          <div className="modal-body">
                            <div className='mb-2 mt-1' style={{ position: 'relative' }}>
                                <input type="text" className="form-control input-font bold" placeholder='Search...' onInput={(store.invoice.invoiceType === 'Material PO') ? this.filterGRTable : this.filterSESTable} />
                                <i className='fa fa-search' style={{ position: 'absolute', right: '20px', top: '13px', color: '#939597' }}></i>
                            </div>

                            <div>
                              {
                              (store.invoice.invoiceType === 'Material PO') ?                              
                                <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                                  <thead className='text-white'>
                                    <tr>
                                      <th style={{ width: '30px' }}></th>
                                      <th>#</th>
                                      <th>GR Number</th>
                                      <th>Item Number</th>
                                      <th>Description</th>
                                      <th>Quantity</th>
                                      <th className='text-end'>Amount</th>
                                      <th>Currency</th>
                                    </tr>
                                  </thead>
                                  <tbody className=''>
                                    {
                                      store.currentPageGRs.map(object => { // changed from filteredGRs
                                        return <tr key={object.index}>
                                          <td className='text-center'>
                                            <i style={{fontSize: '18px'}} className='fa fa-plus text-primary btn btn-default'  title={'Add GR'} onClick={() => this.handleAddGR(object)}></i>
                                          </td>

                                          <td>{object.index}</td>
                                          <td>{object.grNo}</td>
                                          <td>{object.grItemNo}</td>
                                          <td>{object.materialDescription}</td>
                                          <td>{object.grQuantity}</td>
                                          <td className='text-end'>{formatAmount(object.grAmount)}</td>
                                          <td>{object.currency}</td>
                                        </tr>
                                      })
                                    }
                                  </tbody>
                                </table>
                                :
                                <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                                    <thead className='text-white'>
                                        <tr>
                                            <th style={{ width: '30px' }}></th>
                                            <th>#</th>
                                            <th>SES Number</th>
                                            <th>Item Number</th>
                                            <th>Description</th>
                                            <th className='text-end'>Amount</th>
                                            <th>Currency</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            store.currentPageSESs.map(object => {
                                                return <tr key={object.index}>
                                                    <td className='text-center'>
                                                        <i style={{fontSize: '18px'}} className='fa fa-plus text-primary btn btn-default' title={'Add SES'} onClick={() => this.handleAddSES(object)}></i>
                                                    </td>

                                                    <td>{object.index}</td>
                                                    <td>{object.sesNo}</td>
                                                    <td>{object.sesItemNo}</td>
                                                    <td>{object.sesItemDescription}</td>
                                                    <td className='text-end'>{formatAmount(object.sesAmount)}</td>
                                                    <td>{object.currency}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                              }
                            </div>

                            <div>
                              {
                                (store.filteredSESs.length === 0 && store.filteredGRs.length === 0) ? 
                                  <p className='font-12 text-center p-3'>No record found</p> 
                                  : 
                                  <></>
                              }
                            </div>

                            <div className="row mb-2 mt-2">
                                <div className='col-sm-8 col-12'></div>
                                <div className='col-2 d-flex'>
                                  <div className='font-12 bolder' style={{width: '100px', marginTop: '6px'}}>Show rows:</div>
                                  <select className='font-12 form-select border-radius-sm'  style={{width: '100px'}} value={store.numRecordsPerPage} onChange={this.setNumRecordsPerPage}>
                                    {
                                      recordPerRows.map(r => {
                                        return <option value={r}>{r}</option>                                        
                                      })
                                    }
                                  </select>
                                </div>
                                <div className='col-6 col-sm-1'>
                                    <button className='btn bg-light font-12 form-control border-radius-sm' disabled={store.currentPage === 1} onClick={this.previousPage}><PrevIcon /></button>
                                </div>
                                <div className='col-6 col-sm-1'>
                                    <button className='btn bg-light font-12 form-control border-radius-sm' disabled={store.currentPage === store.numOfPages} onClick={this.nextPage}><NextIcon /></button>
                                </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UpdateInvoice);