import React, { Component } from 'react';

class InvoiceReferenceInput extends Component {
    render() {
        let parent =this.props.parent
        let state = parent.state
        return (
            <div className='col-12 col-sm-12'>
                <div className='info-alert font-12 p-1 mt-1'>Invoice number cannot be more than sixteen (16) characters.</div>

                <div className="form-group mb-0 mt-1">
                    <input type="text" name='invoiceReferenceId' onChange={parent.handleInvoiceReference} value={state.invoice.invoiceReferenceId} maxLength={16} minLength={2} className="form-control input-font" autoComplete="off" placeholder="Enter Invoice Reference" required />

                    <i className={`fa input-icon ${state.referenceReponseStatus ? 'fa-check text-success' : 'fa-times text-danger'}`} style={{ top: '1px' }}></i>

                    <label className={`input-feedback font-12 animated fadeIn bold ${state.referenceReponseStatus === false ? ' text-danger' : ' text-success'}`}>
                        {state.referenceshortMsg}
                    </label>
                </div>
            </div>
        );
    }
}

export default InvoiceReferenceInput;