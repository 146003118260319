import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class PageTitle extends Component {
  render() {
    return (
      <div className='col-12 px-4 mt-5 py-4 page-lead-wrap'>
        <div className='d-flex justify-content-between'>
          <div className="mt-3">{this.props.pagedata.page}</div>
          <nav aria-label="breadcrumb font-12 mb-0">
            <ol className="breadcrumb mb-0 mt-3">
              <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">{this.props.pagedata.breadcrumb}</li>
            </ol>
          </nav>
        </div>
      </div>
    );
  }
}

export default PageTitle;