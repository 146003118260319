// *! IMPORTANT INFORMATION!!! == Route must be re-declared in ./staticwebapp.config.json 


import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route,  useLocation, Navigate } from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';


import { bearerToken, refreshToken, Preloader, closeAlert, inactivityTime, ENVIRONMENT } from './helpers/Helpers';


// views Authentication/
import Login from './views/authentication/Login';
import Registration from './views/authentication/Registration';
import RegistrationSuccess from './views/authentication/RegistrationSuccess';
import ResetPassword from './views/authentication/ResetPassword';
import PasswordUpdate from './views/authentication/PasswordUpdate';
import NoRouteMatch from './views/authentication/NoRouteMatch';


// views Profile
import ChangePassword from './views/profile/ChangePassword';
import Settings from './views/profile/Settings';
import Help from './views/profile/Help';
import FAQ from './views/profile/FAQ';


// views Invoice/
import Invoice from './views/invoice/Invoice';
import InvoiceHistory from './views/invoice/InvoiceHistory';
import FIInvoice from './views/invoice/FIInvoice';
import SignInvoice from './views/invoice/SignInvoice';
import SignFIInvoice from './views/invoice/SignFIInvoice';
import UpdateInvoice from './views/invoice/UpdateInvoice';
import UpdateFIInvoice from './views/invoice/UpdateFIInvoice';


function ProtectedRoute({ children }) {
  let location = useLocation();

  if (bearerToken) {
    let name = "rememberme=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    let rememberme;

    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {  c = c.substring(1); }
      if (c.indexOf(name) === 0) {  rememberme = c.substring(name.length, c.length);  }
    }

    if (rememberme === 'true')  inactivityTime();

    return children;
  }
  else{
    let redirectpath =  location.pathname + location.search;
    localStorage.setItem('loginRedirectedFromUrl', redirectpath);
    return <Navigate to="/login" />;
  }
};


class App extends Component {
  state = {
    pageMounted: false
  }

  componentDidMount() {
    Preloader();
    refreshToken(false).then(res => { closeAlert(); this.setState({ pageMounted: true });  })
  }


  render() {
    return (
      <Router>
        {
          ENVIRONMENT !== 'prod' &&
          <section style={{background: 'red', fontSize: '14px', color: '#fff', fontWeight: '600', padding: '5px', position: 'sticky',zIndex: '9000', top: '0'}}>
            <div className="text-center">This is a test environment - Invoice submitted on this link will not be treated!</div>
          </section>
        }
        
        <section>
          { this.state.pageMounted &&
          <Routes>
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Registration />} />
              <Route path='/successful' element={<RegistrationSuccess />} />
              <Route path='/reset-password' element={<ResetPassword />} />
              <Route path='/password-update' element={<PasswordUpdate />} />
              <Route path='/faq' element={<FAQ />} />


              <Route path='/' element={<ProtectedRoute><Invoice /></ProtectedRoute>}  />
              <Route path='/home' element={<ProtectedRoute><Invoice /></ProtectedRoute>} />
              <Route path='/invoice' element={<ProtectedRoute><Invoice /></ProtectedRoute>} />
              <Route path='/history' element={<ProtectedRoute><InvoiceHistory /></ProtectedRoute>} />


              <Route path='/fi-invoice/:id' element={<ProtectedRoute><FIInvoice /></ProtectedRoute>} />
              <Route path='/change-password' element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
              <Route path='/settings' element={<ProtectedRoute><Settings /></ProtectedRoute>} />
              <Route path='/help' element={<ProtectedRoute><Help /></ProtectedRoute>} />


              <Route path='/sign-invoice/:id' element={<ProtectedRoute><SignInvoice /></ProtectedRoute>} />
              <Route path='/sign-fi-invoice/:id' element={<ProtectedRoute><SignFIInvoice /></ProtectedRoute>} />
              <Route path='/update-invoice/:id' element={<ProtectedRoute><UpdateInvoice /></ProtectedRoute>} />
              <Route path='/update-fi-invoice/:id' element={<ProtectedRoute><UpdateFIInvoice /></ProtectedRoute>} />


              <Route path="*" element={<NoRouteMatch />} />
          </Routes>
          }

          <NotificationContainer/>
        </section>
      </Router>
    );
  }
}

export default App;