// Import react tools
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Import components
import LoginAside from '../../layouts/LoginAside';
import Copyright from '../../layouts/Copyright';
import withRouter from '../../../src/WithRouter';

// Import helpers
import {handleInputChange, ajaxCall, Login2FA, errorAction, htmlinputType, Spinner, bearerToken, vendorInfo, registerMessage} from '../../helpers/Helpers';


class Login extends Component {
  // *! this component store
  state = {
    form: {
      email: '',
      password: '',
      loginOTPCode: '',
      rememberMe: true
    },
    is2FAView: false,
  }
  
  // *! form input controller - binds data into state
  /** @param {any} e */
  handleInput = (e) => { handleInputChange(e, this); }

  // ! save vendor email in LS -> delete when process is completed
  SaveEmail = () => { localStorage.setItem('email', this.state.form.email); }

  // *! check if user can login
  /** @param {any} e */
  handleLogin = (e) => {
    e.preventDefault();
    let signIn_btn = e.target.signIn;
    let form = this.state.form;

    let button_name = signIn_btn.innerHTML;
    signIn_btn.disabled = true;
    signIn_btn.innerHTML = Spinner;

    let data = { email: form.email, password: form.password }

    ajaxCall('AuthenticateVendor/PreLogin', 'json', 'post', data, null).then(res => {
      signIn_btn.disabled = false;
      signIn_btn.innerHTML = button_name;
      this.setState({ is2FAView: true });
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data.message)
        if(error.response.data.message === 'New user? Please register first to reset your password!'){
          registerMessage("Please register to create your password as a first time user").then( (r) => r.isConfirmed ? this.props.routerNavigate("/register") : false );
          this.SaveEmail();
        }
        else{
          errorAction(error);
        }
      }
      else{        
        errorAction(error);
      }

      signIn_btn.disabled = false;
      signIn_btn.innerHTML = button_name;
    })
  }
  
  // *! login to app if 2FA
  /** @param {any} e */
  handle2FA = (e) => {
    e.preventDefault();
    let form = this.state.form;

    if (form.loginOTPCode.length === 6) {      
      let twoFA_btn = e.target.twoFA;
      let button_name = twoFA_btn.innerHTML;
      twoFA_btn.disabled = true;
      twoFA_btn.innerHTML = Spinner;

      Login2FA("AuthenticateVendor/Login", form).then(res => {
        twoFA_btn.disabled = false;
        twoFA_btn.innerHTML = 'Login Ok!';
   
        const loginRedirectedFromUrl = localStorage.getItem('loginRedirectedFromUrl');
        localStorage.removeItem('loginRedirectedFromUrl');

        document.cookie = "rememberme=" + form.rememberMe;
        this.props.routerNavigate(loginRedirectedFromUrl ?? "/home")
      })
      .catch(error => {
        errorAction(error);
        twoFA_btn.disabled = false;
        twoFA_btn.innerHTML = button_name;
      })
    }
  }


  render() {
    const form = this.state.form;

    return (
      <section className='loginBG'>
        <div className='container-fluid'>
          <div className='row'>

            {/* import */}
            <LoginAside />

            <div className='col-lg-5 form-aside'>
              <p className='text-end mt-2 px-3'>
                <Link to="/faq">
                  <u className='font-12'> Do you need help? </u>
                </Link>
              </p>

              <div className='mx-auto login-side-wrap'>
                { !this.state.is2FAView ? 
                  <div>
                    {
                      (bearerToken === null) ?
                        <div>
                          <h1 className='login-lead'>Sign In</h1>
                          <p className='font-12 d-none d-sm-block'>Enter your email and password to login.</p>
                          <p className='font-12 d-block d-sm-none text-center'>Sign In to Vendor Invoice Management Portal. </p>

                          <form className="login-form mt-3" autoComplete="off" method="post" onSubmit={this.handleLogin}>
                            <div className="form-group mb-0">
                              <span className='input-overlay'><b className="fa fa-asterisk text-danger font-12"></b> Email </span>

                              <input type="text" name='email' value={form.email} onChange={this.handleInput} className="form-control input-font p-3 curved-top" autoComplete="off" placeholder="Email Address" onPaste={(e)=>{e.preventDefault(); return false;}} onCopy={(e)=>{e.preventDefault(); return false;}} onDrop={(e)=>{e.preventDefault(); return false;}} onDragStart={(e)=>{e.preventDefault(); return false;}} />

                              <i className="fa fa-envelope-o input-icon"></i>
                            </div>

                            <div className="form-group">
                              <span className='input-overlay'><b className="fa fa-asterisk text-danger font-12"></b> Password </span>

                              <input type="password" value={form.password} onChange={this.handleInput} name="password" id="password" className="form-control input-font p-3 curved-bottom" autoComplete="off" placeholder="Password" required onPaste={(e)=>{e.preventDefault(); return false;}} onCopy={(e)=>{e.preventDefault(); return false;}} onDrop={(e)=>{e.preventDefault(); return false;}} onDragStart={(e)=>{e.preventDefault(); return false;}} />

                              <i className='fa input-icon fa-eye' id='inputEye' onClick={() => htmlinputType('password','inputEyeClose', 'inputEye')}></i>
                              <i className='fa input-icon fa-eye-slash' style={{display:'none'}} id='inputEyeClose' onClick={() => htmlinputType('password','inputEye', 'inputEyeClose')}></i>
                            </div>

                            <div className="form-group mt-3">
                              <input type="checkbox" checked={form.rememberMe} onChange={this.handleInput} className="checkbox uicheckbox" name='rememberMe' id='rememberMe' /> &nbsp;
                              <label className='font-12' htmlFor='rememberMe' style={{ top: '-3px', position: 'relative' }}>Remember Me</label>
                            </div>

                            <div className="form-group mt-1">
                              <button type='submit' className='form-control btn btn-primary p-3 font-12' name='signIn'>Sign In</button>
                            </div>

                            <div className="form-group mb-5 mt-1">
                              <span>Forgot Password? <Link to="/reset-password" className='bold-500'>Click here.</Link> </span>
                            </div>

                            <div className="form-group mt-5 mb-0">
                              <Link to='/register' className='form-control p-3 btn btn-register font-12' onClick={this.SaveEmail}>
                                <span>New user? Click here to register</span>         
                                <span className="btn-label">
                                    <div className="arrow">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </span>                    
                              </Link>
                            </div>
                          </form>
                        </div>
                        //-- end login form area --

                      : 

                      <div>
                        <h1 className='login-lead'>Welcome Back!</h1>


                        <div className='login-form mt-5'>
                          <p>Hello {vendorInfo.name}, you still have an active session click button below to go to home page</p>
                          
                          <Link to="/" className='form-control btn btn-primary p-3 mt-1 font-12'>Continue</Link>
                        </div>
                      
                      </div>
                      // --end welcome back view--

                    }
                  </div>
                  // --end login first screen

                  :

                  <div>
                    <h1 className='login-lead'>Enter OTP</h1>
                    <p className='font-12'>Enter the two factor authentication code sent to you. The code will expire in 30 minutes.</p>

                    <form className="login-form mt-3" autoComplete="off" method="post" onSubmit={this.handle2FA}>
                      <div className="form-group">
                        <input type="hidden" autoComplete="off" />
                        <span className='input-overlay'><b className="fa fa-asterisk text-danger font-12"></b> 2FA Code </span>
                        <input type="tel" value={form.loginOTPCode} maxLength={6} name="loginOTPCode" className="form-control input-font p-3" autoComplete="off" placeholder="******" required style={{ letterSpacing: '2.29rem' }} onChange={this.handleInput} />
                      </div>

                      <div className="form-group mt-3">
                        <button type='submit' name='twoFA' className='form-control btn btn-primary p-3 font-12'>Confirm Code</button>
                      </div>
                    </form>
                  </div>
                  // --end OTP view
                }
              </div>

              <Copyright />
            </div>

          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Login);
// CODE REF GIT @taiwowaje