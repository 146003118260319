import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ENVIRONMENT, APP_VERSION, APP_LAST_UPDATED, logout, confirmPrompt } from '../helpers/Helpers'
import Logo from "../assets/img/logo.png"


class SideNav extends Component {
  state = {
    path:window.location.pathname.split('/')[1]
  }
  render() {
    return (
      <>
        <div className='side-nav-wrap'>
          <div style={{width:'100%', background:'#fff', borderBottom:'1px solid #d8d8d852'}}>     
            <div style={{textAlign:'center'}}>
              <img src={Logo} alt='logo' style={{height: '69px'}} />              
            </div>             
          </div>  


          <div className='side-nav-area'>
            <ul>
              <li className={this.state.path === 'home' || this.state.path === 'invoice' || this.state.path === 'fi-invoice' || this.state.path === '' ? 'active' : ''}>
                <Link to="/invoice">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 384 512"><path d="M288 256H96v64h192v-64zm89-151L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zM64 72c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8V72zm0 64c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8v-16zm256 304c0 4.42-3.58 8-8 8h-80c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16zm0-200v96c0 8.84-7.16 16-16 16H80c-8.84 0-16-7.16-16-16v-96c0-8.84 7.16-16 16-16h224c8.84 0 16 7.16 16 16z"/></svg>                   
                  Create Invoice
                </Link>
              </li>

              <li className={this.state.path === 'history' || this.state.path === 'update-invoice' || this.state.path === 'sign-invoice' || this.state.path === 'sign-fi-invoice' || this.state.path === 'update-fi-invoice' ? 'active' : ''}>
                <Link to="/history" >
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z"/></svg>
                  Invoice History
                </Link>
              </li>
 
              <li className={this.state.path === 'settings' ? 'active' : ''}>
                <Link to="/settings">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4h54.1l109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109V104c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7H352c-8.8 0-16-7.2-16-16V102.6c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"/></svg>
                  Invoice Settings 
                </Link>
              </li>
 
              <li className={this.state.path === 'change-password' ? 'active' : ''}>
                <Link to="/change-password">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224 64c-44.2 0-80 35.8-80 80v48H384c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80V144C80 64.5 144.5 0 224 0c57.5 0 107 33.7 130.1 82.3c7.6 16 .8 35.1-15.2 42.6s-35.1 .8-42.6-15.2C283.4 82.6 255.9 64 224 64zm32 320c17.7 0 32-14.3 32-32s-14.3-32-32-32H192c-17.7 0-32 14.3-32 32s14.3 32 32 32h64z"/></svg>
                  Change Password 
                </Link>
              </li>
 
              <li className={this.state.path === 'help' ? 'active' : ''}>
                <Link to="/help">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M0 80C0 53.5 21.5 32 48 32h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80zM64 96v64h64V96H64zM0 336c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V336zm64 16v64h64V352H64zM304 32h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H304c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48zm80 64H320v64h64V96zM256 304c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s7.2-16 16-16s16 7.2 16 16v96c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s-7.2-16-16-16s-16 7.2-16 16v64c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V304zM368 480a16 16 0 1 1 0-32 16 16 0 1 1 0 32zm64 0a16 16 0 1 1 0-32 16 16 0 1 1 0 32z"/></svg>
                  Help 
                </Link>
              </li>
 
              <li className='mt-5' style={{position: 'absolute', bottom: '8%'}}>
                <div onClick={()=>confirmPrompt("Are you sure you want to logout?").then( (r) => r.isConfirmed ?  logout: false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"/></svg>
                  Logout 
                </div>
              </li>
            </ul>
          </div>

          <div style={{width:'100%', background:'#fff', borderTop:'1px solid #d8d8d852', bottom: '0', position: 'absolute'}}>
            <div style={{textAlign:'center'}} className='mb-3 mt-2' title={'Version: ' + APP_VERSION + '  ::::::   Last Updated On: ' + APP_LAST_UPDATED}>
              <span style={{fontSize:'10px', fontWeight:'600', textTransform:'uppercase'}}>{ENVIRONMENT}</span>
            </div>             
          </div> 
        </div>                
                
        {/* Mobile Nav */}
        <div className="modal" id="mobileNavModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content mobileNav">
              <div className="modal-header p-2">        
                <div>     
                  <div>
                    <img src={Logo} alt='logo' style={{height: '52px'}} />              
                  </div>             
                </div>  
                <button type="button" className="btn-close px-3 font-12" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body mobile-nav-area">
                <ul>
                  <li><Link to="/invoice" className={this.state.path === 'home' || this.state.path === 'sign-invoice' || this.state.path === 'update-invoice' || this.state.path === 'invoice' || this.state.path === '' ? 'active' : ''}><span data-bs-dismiss="modal"><i className="fa fa-file-text-o"></i><i className="fa fa-usd"></i>New Invoice</span></Link></li>

                  <li><Link to="/history" className={this.state.path === 'history' || this.state.path === 'sign-fi-invoice' ? 'active' : ''}>
                    <span data-bs-dismiss="modal"><i className="fa fa-history"></i> Invoice History</span></Link>
                  </li>
 
                  <li><Link to="/help" className={this.state.path === 'help' || this.state.path === 'change-password'  || this.state.path === 'settings' ? 'active' : ''}>
                    <i className="fa fa-wrench"></i> Help </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


export default SideNav;