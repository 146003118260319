import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import {vendorInfo, logout} from '../helpers/Helpers'



class TopNav extends Component {
  render() {
    return (
      <div className='fixed-top-nav'>                  
        <div className='p-2'>
          <div className='d-flex  justify-content-between'>  
            <div className=''>
              <h1 className='inappname d-none d-sm-block text-uppercase'>Vendor Invoice <span>Management Portal</span></h1>
              <h1 className='inappname d-block d-sm-none'>
                <i className='fa fa-bars' style={{paddingRight: '15px', color: '#4d63c4'}}  data-bs-toggle="modal" data-bs-target="#mobileNavModal"></i>                
                VIMP
              </h1>
            </div>          

            <div className='d-flex flex-row-reverse'>

              <div className="dropdown mr-13 navbar navbar-expand navbar-light">
                <span className="dropdown-toggle dropdown-toggle nav-link" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{cursor:'pointer',background: '#f2f2f2',padding: '5px 4px 0', borderRadius: '20px', marginTop:'9px'}}>
                  <svg className='user-icon-svg-topnav' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"/></svg>
                  {vendorInfo.email}
                </span>

                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><Link className="dropdown-item font-12" to="/change-password"><i className='fa fa-key topnav-list-right'></i> <span className='topnav-list-left'>Change Password</span></Link></li>
                  <li><hr className="dropdown-divider" /></li>

                  <li><Link className="dropdown-item font-12" to="/settings"><i className='fa fa-cogs topnav-list-right'></i> <span className='topnav-list-left'>Invoice Settings</span></Link></li>
                  <li><Link className="dropdown-item font-12" to="/help"><i className='fa fa-wrench topnav-list-right'></i> <span className='topnav-list-left'>Help</span></Link></li>
                  <li><hr className="dropdown-divider" /></li>
                  
                  {/* <li><Link className="dropdown-item font-12" to="#!" onClick={logout}><i className='fa fa-power-off topnav-list-right'></i> <span className='topnav-list-left'>Logout</span></Link></li> */}
                  <li><div className="dropdown-item font-12 logoutbtn" onClick={logout}><span className='btn btn-primary font-12 form-control'>Logout</span></div></li>
                </ul>
              </div>

              <div className='bell-wrap  d-none'>
                <i className='fa fa-bell-o bell-icon'></i> 
                <span className='bell-value'>0</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopNav;