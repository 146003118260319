// # import plugins;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// # import layouts, helpers, components;
import LoginAside from '../../layouts/LoginAside';
import Copyright  from '../../layouts/Copyright';
import withRouter from '../../../src/WithRouter';
import { ajaxCall, closeAlert, errorAction, successAlert, Spinner } from '../../helpers/Helpers'

class ResetPassword extends Component {
  state = {
    form:{
      email:'',
      resetPasswordLink: window.location.origin+'/password-update',
      isFirstTimeUser: false
    }
  }

  // # Event Hook;
  handleFormInputsChange = (e) =>{ this.setState(prevState => ({ form: {  ...prevState.form, [e.target.name] : e.target.type === 'checkbox' ? e.target.checked : e.target.value }}))}

  // Main Function
  handleReset = (e) =>{
    e.preventDefault();

    let button_name = e.target.resetBtn.innerHTML;
    e.target.resetBtn.disabled = true;
    e.target.resetBtn.innerHTML = Spinner;

    ajaxCall('AuthenticateVendor/SendResetPasswordToken', 'json', 'post', this.state.form, null).then(res => {    
      closeAlert();
      
      this.setState(prevState => ({ form: {  ...prevState.form, 'email' : '' }}))
      
      e.target.resetBtn.disabled = false;
      e.target.resetBtn.innerHTML = button_name;


      localStorage.setItem('title', 'Password Reset Link Sent!');
      localStorage.setItem('message',res.data.message);
      this.props.routerNavigate("/successful");
      successAlert(res.data.message)
    })
    .catch(error => { 
      errorAction(error);
      
      e.target.resetBtn.disabled = false;
      e.target.resetBtn.innerHTML = button_name;
    })
  }

  render() {
    return (
        <div className='loginBG'>
          <div className='container-fluid'>
            <div className='row'>
              <LoginAside />
                        
              <div className='col-sm-5 form-aside'>
                <div  className='mx-auto login-side-wrap'>

                  <h1 className='login-lead'>Reset Password</h1>
                  <p className='font-12'>Kindly enter your email address to continue.</p>
                  
                  <form className="login-form mt-3" autoComplete="off" method="post" onSubmit={this.handleReset}>
                      <div className="form-group">                        
                        <span className='input-overlay'><b className="fa fa-asterisk text-danger font-12"></b> Vendor Email </span>
                        <input type="email" name='email' value={this.state.form.email} onChange={this.handleFormInputsChange}  className="form-control input-font p-3 curved-top" placeholder="Enter your email" onPaste={(e)=>{e.preventDefault(); return false;}} onCopy={(e)=>{e.preventDefault(); return false;}} onDrop={(e)=>{e.preventDefault(); return false;}} onDragStart={(e)=>{e.preventDefault(); return false;}} />
                        <i className="fa fa-envelope input-icon"></i>
                      </div>
                      
                      <div className="form-group mt-3">  
                        <button type='submit' name='resetBtn' className='form-control btn btn-primary p-3 font-12'>Reset Password</button>
                      </div>

                      <div className="form-group mb-5 mt-2">                        
                        <span>Remembered your password? <Link to="/login" className='bold-500'>Log in.</Link> </span>
                      </div>
                  </form>
                  
                  <Copyright />
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(ResetPassword);