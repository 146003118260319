// # import plugins;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// # import layouts, helpers, components;
import LoginAside from '../../layouts/LoginAside';
import Copyright  from '../../layouts/Copyright';


class RegistrationSuccess extends Component {
  componentDidMount() {
    this.pageLoad();
  }

  state = {
    feedback:'',
    title:''
  }

  pageLoad = () => {
    if(localStorage.getItem('message') != null) 
      this.setState({feedback:localStorage.getItem('message'), title:localStorage.getItem('title')});

    localStorage.removeItem('message');
    localStorage.removeItem('title');
  }

  render() {
    return (
        <div className='loginBG'>
          <div className='container-fluid'>
            <div className='row'>
              <LoginAside />
                        
              <div className='col-sm-5 form-aside'>
                <div  className='mx-auto login-side-wrap'>

                  <div className='text-center'>
                    <div style={{marginTop:'30%'}}><i className='success-check fa fa-check easeout'></i><br /></div>

                    <h1 style={{marginTop:'10%', fontWeight:700, fontSize:'25px'}}>{this.state.title}</h1>
                    <p className='font-12'>{this.state.feedback}</p>

                    <p className='mt-5 text-center'>
                      <Link to="/login">Go to Login Page</Link>
                    </p>
                  </div>
                  
                  <Copyright />
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default RegistrationSuccess;