import React, { Component } from 'react';
import { Link } from 'react-router-dom';


// # import layouts, helpers, components;
import LoginAside from '../../layouts/LoginAside';
import Copyright from '../../layouts/Copyright';
import withRouter from '../../WithRouter';


class NoRouteMatch extends Component {
  render() {
    return (
      <div className='loginBG'>
        <div className='container-fluid'>
          <div className='row'>
            <LoginAside />

            <div className='col-lg-5 form-aside'>
              <br/><br/><br/><br/>
              <div className='login-form mt-3'>
                <div className='mx-auto login-side-wrap'>
                  <h1 className='login-lead'>Oops!</h1>
                  <div className='mb-5'> <h3> No Route match for <code>{window.location.pathname}</code> </h3> </div>
                    
                  <div className="form-group mb-5 mt-3 mt-2">                        
                      <span> <Link to="/login" replace className='bold-500'>Go to login page.</Link> </span>
                  </div> 
                </div>
              </div>

              <Copyright />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NoRouteMatch);