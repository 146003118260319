import React, { Component } from 'react';

import withRouter from '../../../src/WithRouter';
import Select from 'react-select'
import SideNav              from '../../layouts/SideNav';
import TopNav               from '../../layouts/TopNav';
import PageTitle            from '../../layouts/PageTitle';

import { ajaxCall, alertMessage, closeAlert, confirmPrompt, errorAction, Preloader, successAlert } from '../../helpers/Helpers'


class Settings extends Component {
  componentDidMount() {
    this.GetVendorsForLoggedOnVendorUser()  
  }

  state = {
    form:{
      themeColor: '',
      logo:'',
      vendorCode:[],
    },
    VendorList:[],
  }

  handleFormInputsChange = (e) =>{ this.setState(prevState => ({ form: {  ...prevState.form, [e.target.name] : e.target.type === 'checkbox' ? e.target.checked : e.target.value }}))}
  handleFormSelectChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.name]: [e] } })) }

  GetVendorsForLoggedOnVendorUser = () =>{
    Preloader();
    
    ajaxCall('Vendor/GetVendorsForLoggedOnVendorUser').then(res => {
      closeAlert();
      let vendors_list = res.data.data.map(i => i.active === true && { ...i, name: 'vendorCode', label: i.name, value: i.vendorCode });
      this.setState({ VendorList: vendors_list });
    })
    .catch(error => {
      errorAction(error);
    })
  }

  UploadThemeSettings = (e) =>{
    e.preventDefault();
    if(this.state.form.vendorCode.length === 0){
      alertMessage("Select your comapany first!"); 
      return;
    }

    let themeColor = this.state.form.themeColor;
    const formData = new FormData();
    formData.append("logoFormFile", e.target['logo'].files[0]);
    formData.append("invoiceColorCodeHex", themeColor);
    formData.append("VendorCode", this.state.form.vendorCode[0].value);

    confirmPrompt('Do you want to update invoice?').then((r) => {
      if (r.isConfirmed) {
        Preloader();

        ajaxCall('Vendor/UpdateVendorLogoAndColor/', 'json', 'patch', formData).then(res => {        
            successAlert('Invoice theme updated successfully for '+this.state.form.vendorCode[0].label);
        })
        .catch(error => {
          errorAction(error)
        })
      }
    })


 

  }

  render() {
    return (
        <div className='mainApp'>
          <div className='container-fluid'>
            <div className='row'>
              <SideNav />
              
              <div className='main-content-wrap'>
                <TopNav />
                <PageTitle pagedata={{page:'Settings',breadcrumb:'Manage Invoice Template'}}/>

                <div className='container mb-5'>
                  <div className='row'>

                    <div className='col-12 px-4 mb-5'>

                      <div className="mt-4 shadow bg-white">
                        <h6 className='py-3 px-4 mb-0 lead-txt'>
                          Upload Logo
                        </h6>
                        <hr className='m-0' />
                        
                        <form className="login-form py-3 px-4" autoComplete="off" method="post" onSubmit={this.UploadThemeSettings}>
                          <input type="hidden" autoComplete="off" />

                          <div className='row mt-2'>
                            <div className='col-sm-2 col-12'>
                              <label className='font-12 bold'>Select Your Company:</label>
                            </div>

                            <div className='col-sm-10 col-12'>
                              <div className="form-group mb-0">
                                <Select name='vendorCode'
                                  defaultValue={this.state.form.vendorCode.length > 0 ? this.state.form.vendorCode[0] : ''}
                                  onChange={this.handleFormSelectChange}
                                  isSearchable={false}
                                  placeholder="Select your company"
                                  options={this.state.VendorList}
                                />
                              </div>
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Select Invoice Color: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="color" name='themeColor' value={this.state.form.themeColor} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" placeholder="Select copany primary color" required style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Upload Logo: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="file" name='logo' value={this.state.form.logo} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" placeholder="Upload your logo" required style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-9'></div>
                            <div className='col-3'>
                              <div className="form-group mt-3 mb-5">
                                <button type='submit' className='btn btn-primary font-12 border-radius-sm form-control'>Submit</button>
                              </div> 
                            </div>
                          </div>
                        </form>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(Settings);