import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

class Copyright extends Component {
  state = {
    getFullYear: new Date().getFullYear()
  }

  render() {
    return (
      <div className="font-12 text-center copyright-area">  
          <hr />                      
          <span>Copyright &copy;<span>{this.state.getFullYear}</span> TotalEnergies | All rights reserved</span><br /><br />
      </div>
    );
  }
}

export default Copyright;