import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import SideNav from '../../layouts/SideNav';
import TopNav from '../../layouts/TopNav';
import PageTitle from '../../layouts/PageTitle';
import PrevIcon from '../../layouts/PrevIcon';
import NextIcon from '../../layouts/NextIcon';
import { formatAmount, formatDate, ajaxCall, errorAction, confirmPrompt, Preloader, closeAlert, tinyAlert, recordPerRows } from '../../helpers/Helpers'


class InvoiceHistory extends Component {
  componentDidMount() {
    this.GetExistingInvoicesForVendor();
  }


  state = {
    invoiceHistory: [],
    filteredHistory: [],
    currentPageHistory: [],

    numRecordsPerPage: 25,
    currentPage: 1,
    numOfPages: 1,

  }


  // [functions Setup]#+++++++++
  GetExistingInvoicesForVendor = () => {
    Preloader();
    ajaxCall('Invoice').then(res => {
      this.setState({ invoiceHistory: res.data.data }, this.filteredHistoryTable);
      closeAlert();
    })
      .catch(error => {
        errorAction(error);
      })
  }

  filteredHistoryTable = (e) => {
    let result
    if (e != null) {
      let search = e.target.value.toLowerCase();
      result = this.state.invoiceHistory.filter((data) => data.invoiceReferenceId.toLowerCase().includes(search) || data.invoiceType.toLowerCase().includes(search) || data.bankCurrency.toLowerCase().includes(search) || data.status.toLowerCase().includes(search) || data.createdBy.toLowerCase().includes(search) || data.grossAmount.toString().includes(search) || data.netAmount.toString().includes(search) || data.grossAmount.toString().includes(search) || (new Date(data.createdDate).toDateString()).includes(search));
    }
    else
      result = this.state.invoiceHistory;

    this.setState({ filteredHistory: result.map((r, i) => ({ ...r, index: i + 1 })) }, this.updateTablePagination);
  }

  updateTablePagination = () => {
    let recordCount = this.state.filteredHistory.length
    let numOfPages = Math.ceil(recordCount / this.state.numRecordsPerPage)
    let currentPage = this.state.currentPage
    if (currentPage < 1) currentPage = 1;
    if (currentPage > numOfPages) currentPage = numOfPages;
    this.setState({ currentPage: currentPage, numOfPages: numOfPages }, this.setRecordsForCurrentPage)
  }

  setRecordsForCurrentPage = () => {
    const offset = (this.state.currentPage - 1) * this.state.numRecordsPerPage;
    this.setState({ currentPageHistory: this.state.filteredHistory.slice(offset, offset + this.state.numRecordsPerPage) });
  }

  setNumRecordsPerPage = (e) => {
    this.setState({ numRecordsPerPage: Number(e.target.value) }, this.updateTablePagination);
  }

  nextPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) + 1 }, this.setRecordsForCurrentPage);
  }

  previousPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) - 1 }, this.setRecordsForCurrentPage);
  }

  DeleteInvoice = (id) => {
    Preloader();

    ajaxCall('/Invoice/' + id, 'json', 'delete').then(res => {
      tinyAlert('success', 'Invoice was successfully deleted');
      this.GetExistingInvoicesForVendor();
    })
      .catch(error => {
        errorAction(error);
      });
  }

  render() {
    // const __this__ = this;
    const page_info = { page: 'Invoice History', breadcrumb: 'Invoice History' };
    const store = this.state;

    return (
      <div className='mainApp'>
        <div className='container-fluid'>
          <div className='row'>
            {/* fixed side nav area */}
            <SideNav />

            <div className='main-content-wrap'>
              {/* fixed top area */}
              <TopNav />

              {/* Page title and breadcrumb */}
              <PageTitle pagedata={page_info} />

              <div className='container-fluid mb-5'>
                <div className='row'>
                  <div className='col-12 px-4 sm-px-4'>
                    <div className='d-flex mt-4 mb-3 shadow-sm bg-white px-2 py-2' style={{ border: '1px solid #ced4da' }}>
                      <div className='col-10' style={{ position: 'relative', marginRight: '10px', borderRight: '1px dotted #c7c7c7', paddingRight: '10px' }}>
                        <input type="text" className="form-control input-font" placeholder='Find by reference no., author, currency, amount and po-type' onInput={this.filteredHistoryTable} />
                        <i className='fa fa-search' style={{ position: 'absolute', right: '20px', top: '12px', color: '#939597' }}></i>
                      </div>

                      <select className='form-control input-font bold' onChange={this.filteredHistoryTable} style={{ height: '40px' }}>
                        {/* <option value="" disabled>filter by status</option> */}
                        <option value="">All Status</option>
                        <option value="Draft">Draft</option>
                        <option value="Awaiting Technical Rep. Review">Awaiting Technical Rep. Review</option>
                        <option value="Rejected by Technical Rep.">Rejected by Technical Rep.</option>
                        <option value="Accepted by Technical Rep.">Accepted by Technical Rep.</option>
                        <option value="Submitted">Submitted</option>
                        <option value="Undergoing Processing">Undergoing Processing</option>
                        <option value="Awaiting Payment">Awaiting Payment</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Rejected by Admin">Rejected by Admin</option>
                        <option value="Not Found">Not Found</option>
                        <option value="Paid">Paid</option>
                      </select>
                    </div>
                  </div>

                  <div className='col-12 px-4 sm-px-4'>
                    <div className='mb-2 bg-white'>
                      <div className="table-responsive">
                        <table className="table font-12 table-striped table-hover mb-0 table-bordered">
                          <thead className='text-white' style={{ background: '#0556de' }}>
                            <tr>
                              <th className='text-center' style={{ width: '50px' }}>SN</th>
                              <th style={{ width: '130px' }}>Reference No</th>
                              <th className='text-center'>PO No</th>
                              <th>PO Type</th>
                              <th className='text-end'>Amount</th>
                              <th>Date Created</th>
                              <th>Status</th>
                              <th>Author</th>
                              <th className='text-center' style={{ width: '120px' }}>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              store.currentPageHistory.map(object => {
                                return (
                                  <tr key={object.index}>
                                    <td className='text-center'>{object.index}</td>
                                    <td>{object.invoiceReferenceId}</td>
                                    <td className='text-center'>{object.poNo ? object.poNo : '-'}</td>
                                    <td className='text-uppercase'>{object.invoiceType}</td>
                                    <td className='text-end'>{object.bankCurrency.replace(/[0-9]/g, '')}  {formatAmount(object.grossAmount)}</td>
                                    <td>{formatDate(object.createdDate, 'date')}</td>
                                    <td className='text-uppercase'>{object.status}</td>
                                    <td className='text-break'>{object.createdBy}</td>
                                    <td>
                                      <Link to={object.invoiceType === "Non-PO" ? "/sign-fi-invoice/" + object.id : "/sign-invoice/" + object.id} className={`btn btn-sm btn-success bold border-radius-sm ${object.status !== 'Draft' && 'view_icon'}`}>
                                        {
                                          // object.status === "Submitted" || object.status === "Rejected by Technical Rep."?
                                          object.status !== "Draft" ?
                                            <i className='fa fa-eye font-14' title='View invoice'></i>
                                            :
                                            <i className='fa fa-paper-plane font-14' title='Submit invoice' style={{ transform: 'rotate(31deg)' }}> </i>
                                        }
                                      </Link>

                                      <Link to={object.invoiceType === "Non-PO" ? "/update-fi-invoice/" + object.id : "/update-invoice/" + object.id} className={`btn btn-sm bold btn-info border-radius-sm ${object.status === 'Submitted' && 'disabled'} ${object.status === 'Not Found' && 'disabled'} ${object.status === 'Awaiting Technical Rep. Review' && 'disabled'}`} style={{ marginLeft: '5px' }} title="Edit Invoice">
                                        <i className='fa fa-edit font-14'></i>
                                      </Link>

                                      <button disabled={object.status === 'Submitted' ? true : object.status === 'Not Found' ? true : false} className='btn btn-sm btn-danger bold border-radius-sm' onClick={() => confirmPrompt("Do you want to delete invoice " + object.invoiceReferenceId + "?").then((r) => r.isConfirmed ? this.DeleteInvoice(object.id) : false)} style={{ marginLeft: '3px' }} title="Delete Invoice">
                                        <i className='fa fa-trash font-14'></i>
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>

                      {this.state.invoiceHistory.length === 0 && <p className='font-12 text-center p-3'>Oops! No record available...</p>}
                    </div>
                  </div>

                  <div className='col-12 px-4 sm-px-4'>
                    <div className='mb-4' style={{ paddingBottom: '10px !important' }}>
                      <div className="row mb-2 mt-2">
                        <div className='col-sm-8'></div>

                        <div className='col-sm-2 d-flex col-12'>
                          <div className='font-12 bolder text-end' style={{ width: '100px', marginTop: '6px', paddingRight: '5px' }}>Show rows:</div>
                          <select className='font-12 form-select border-radius-sm' style={{ width: '100px' }} value={this.state.numRecordsPerPage} onChange={this.setNumRecordsPerPage}>
                            {
                              recordPerRows.map(r => {
                                return <option value={r}>{r}</option>
                              })
                            }
                          </select>
                        </div>

                        <div className='col-sm-1 col-6'>
                          <button className='btn bg-light font-12 border-radius-sm form-control' disabled={this.state.currentPage === 1} onClick={this.previousPage}>
                            <PrevIcon />
                          </button>
                        </div>
                        <div className='col-sm-1 col-6'>
                          <button className='btn bg-light font-12 border-radius-sm form-control border' disabled={this.state.currentPage === this.state.numOfPages} onClick={this.nextPage}>
                            <NextIcon />
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceHistory;