import React, { Component } from 'react';
import { formatAmount } from '../helpers/Helpers'

class GRLineItem extends Component {
    render() {
        let parent =this.props.parent
        let state = parent.state
        return (
            <div className='mb-2'>
              {state.vendor.partInvoicingEnabled && <div className='info-alert d-none'>Kindly note that you have part Material PO payment enabled for this account.</div>}

              <form autoComplete="off" method="post">
                {
                  state.invoiceLineItem.map((x, i) => {
                    return( 
                      <div key={i} className="bg-white px-4 pb-4 mb-2 shadow-sm">
                        <div style={{ background: "#f2f2f220" }} className="d-flex">
                            <div style={{width:'3%'}} className="d-non">
                                <i className='fa fa-times text-danger' style={{ marginTop: '23px', paddingTop: '12px', borderRight: '1px solid #f2f2f2', paddingRight: '10px'}} title={'Remove GR ' + x.materialNo} onClick={() => parent.handleRemoveGR(x)}></i>
                            </div>

                            <div className='row' style={{width:'100%'}}>
                              <div className='col-2'>
                                <div className="form-group mb-0">
                                  <label className='font-12 input-overlay'>GR Number</label>
                                  <div className="bold form-control input-font disable-bg">{x.grNo}</div>
                                </div>
                              </div>

                              <div className='col-1'>
                                <div className="form-group mb-0">
                                  <label className='font-12 input-overlay'>Item</label>
                                  <div className="bold form-control input-font disable-bg">{x.grItemNo}</div>
                                </div>
                              </div>

                              <div className='col-3'>
                                <div className="form-group mb-0">
                                  <label className='font-12 input-overlay disable-bg'>Description</label>
                                  <div className="bold form-control input-font disable-bg">{x.materialDescription}</div>
                                </div>
                              </div>

                              <div className='col-2'>
                                {!state.vendor.partInvoicingEnabled ?
                                  <div className="form-group mb-0">
                                    <label className='font-12 input-overlay'>Qty</label>
                                    <div className="bold form-control input-font disable-bg">{x.grQuantity}</div>
                                  </div>
                                  :
                                  <div className="form-group mb-0">
                                    <label className='font-12 input-overlay'>Qty: {formatAmount(x.MaxQuantity)}</label>
                                    <input type="number" step=".01" className="bold form-control input-font" value={x.partQty === '' ? '' : x.partQty === undefined ? x.grQuantity : x.partQty} onChange={(e) => parent.handleChangeGRQty(e, x)} max={x.MaxQuantity} />
                                  </div>
                                }
                              </div>

                              <div className='col-3'>
                                {!state.vendor.partInvoicingEnabled ?
                                  <div className="form-group mb-0">
                                    <label className='font-12 input-overlay'>Amount</label>
                                    <label className='input-currency bold'>{x.currency}</label>
                                    <div style={{ paddingLeft: '65px' }} className="bold form-control input-font disable-bg">{formatAmount(x.grAmount)}</div>
                                  </div>
                                  :
                                  <div className="form-group mb-0">
                                    <label className='font-12 input-overlay'>GR Amount: {formatAmount(x.MaxAmount)}</label>
                                    <label className='input-currency bold'>{x.currency}</label>
                                    <input type="number" step=".01" style={{ paddingLeft: '65px' }} className="bold form-control input-font" value={x.partAmount === '' ? '' : x.partAmount === undefined ? x.grAmount : x.partAmount} onChange={(e) => parent.handleChangeGRAmount(e, x)} min="1" max={x.MaxAmount} />
                                  </div>
                                }
                              </div>
                              
                              <div className='col-1'>
                                <div className="form-group mb-0">
                                  <label className='font-12 input-overlay'>Vat</label>                                          
                                  <div  style={{height:'40px' }} className="bold form-control input-font">
                                    <div className="form-check form-switch">
                                      <input className="form-check-input" type="checkbox" checked={x.vatTaxable === true ? true : false} onChange={(e) => parent.handleCheckedGRVAT(e, x)} />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='col-12'>
                                <div className="form-group mb-0">
                                  <label className='font-12 input-overlay'>Custom Description</label>
                                  <input type="text" className="bold form-control input-font" value={x.vendorMaterialDescription} onChange={(e) => parent.handleChangeGRDescription(e, x)} />
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    )
                  })
                }
              </form>

            </div>
        );
    }
}

export default GRLineItem;