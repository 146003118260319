import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
// import { createFilterOptions } from '@mui/material/Autocomplete';
// import Select from 'react-select'

import withRouter from '../../../src/WithRouter';
import SideNav from '../../layouts/SideNav';
import TopNav from '../../layouts/TopNav';
import NextIcon from '../../layouts/NextIcon';
import PrevIcon from '../../layouts/PrevIcon';
import PageTitle from '../../layouts/PageTitle';


import NonPOForm from '../../components/NonPOForm';
import SelectBank from '../../components/SelectBank';


import {handleInvoiceReferenceInputChange, GetPreviouslyUsedInvoiceReferenceIds, ajaxCall, alertMessage, alertPrompt, closeAlert, confirmPrompt, errorAction, errorAlert, Preloader, tinyAlert, successAlert, formatAmount, formatDate } from '../../helpers/Helpers';



// ! React class component
class FIInvoice extends Component {
  state = {
    form: {
      vat: true,
      description: '',
      amount: '',
      referenceNumber: '',
      fiCostObject: { label: '', value: null },
    },
    invoice:{
      invoiceCurrency: { label: 'Select invoice currency', value: '', name: '' },
      fiCategory: { label: 'Select non-po category', value: '', name: '', costObjectRequired: false },
      tepNgCompany: { label: 'Select TotalEnergies company', value: '', name: '' },
      invoiceReferenceId:''
    },

    selectedVendor: { name: '', vendorCode: '' },

    FICategories: [],
    FICostObjects: [],
    currencies: [],
    TepgCompanyData: [],
    TepgCompany: [],
    bankDetails: [],
    checkedBankDetails: { accountNumber: '' },
    invoiceItems: [],
    sortedByCostObject: [],
    costObjects: [],

    UsedInvoice: new Set([]),

    isShowInvoiceTable: true,
    isSortCostObject: false,
    isShowBankRecord: false,
    referenceReponseStatus: false,
    referenceshortMsg: 'Required',
    createMultipleInvoiceCounter: null,
    Setting: {},
    showNewItemRow: true,
    isShowAllTECompanies: false,
    isFinalView: false,

    multiJVInvoice: []
  }
  
  // ! onLoad functions
  componentDidMount() {
    this.GetVendorsForLoggedOnVendorUser();
  }

  GetVendorsForLoggedOnVendorUser = () => {
    Preloader();
    let vendorCode = this.props.routerParams.id;

    ajaxCall('Vendor/GetVendorsForLoggedOnVendorUser')
    .then(res => {
      let selectedVendor = res.data.data.filter(g => g.vendorCode === vendorCode)[0];

      if (selectedVendor.vendorCode === vendorCode) {
        this.setState({ selectedVendor: selectedVendor });

        this.GetBankDetails(vendorCode);
        this.GetPreviouslyUsedInvoiceReferenceIds(vendorCode);


        this.GetFICategories();
        this.GetTepngCompanies();
        this.GetAllFICostObjects();
        this.GetAllSetting();
      }
      else {
        errorAlert('Error detected in your vendor code, please select the correct vendor code.');
      }

      closeAlert();
    })
      .catch(error => {
        errorAction(error);
      })
  }

  // !vendor_invoices reference
  GetPreviouslyUsedInvoiceReferenceIds = (vendorCode) => { GetPreviouslyUsedInvoiceReferenceIds(vendorCode, this) }

  // *! form input reference controller - binds data into state
  /** @param {any} e */
  handleInvoiceReference = (e) => { handleInvoiceReferenceInputChange(e, this) }







  handleInvoiceInputsChange = (e) => { this.setState(prevState => ({ invoice: { ...prevState.invoice, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value } })) }
  handleInvoiceSelectChange = (e) => { this.setState(prevState => ({ invoice: { ...prevState.invoice, [e.name]: e } })) }






  handleFormInputsChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value } })) }
  handleFormSelectChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.name]: e } })) }


  handleFormAutocompleteChange = (e, v) => { this.setState(prevState => ({ form: { ...prevState.form, [v.name]: v } })) }









  /**
   * @param {String} vendorCode
  */
  GetBankDetails = (vendorCode) => {
    Preloader()
    ajaxCall('Invoice/GetVendorBankDetails/' + vendorCode).then(res => {
      this.setState({ bankDetails: res.data.data });
      closeAlert();
    })
      .catch(error => {
        errorAction(error);
      })
  }

  GetAllSetting = () => {
    Preloader();

    ajaxCall('Setting').then(res => {
      this.setState({ Setting: res.data.data });

      let currency = res.data.data.allowedFICurrencies.split(',');
      let currencies = currency.map((r) => ({ value: r, name: 'invoiceCurrency', label: r }));
      this.setState({ currencies: currencies });
      closeAlert();
    })
      .catch(error => {
        errorAction(error);
      })
  }

  GetTepngCompanies = () => {
    ajaxCall('TepngCompany').then(res => {
      // save original file;
      let tepgCompanyData = res.data.data;
      this.setState({ TepgCompanyData: tepgCompanyData });

      // filter record
      let activeTepngCompany = tepgCompanyData.filter(g => g.active === true);
      let TepgCompany = activeTepngCompany.map((r) => ({ value: r.companyCode, name: 'tepNgCompany', label: r.name }));

      this.setState({ TepgCompany: TepgCompany });
    })
      .catch(error => {
        errorAction(error)
      })
  }

  GetFICategories = () => {
    ajaxCall('FICategory').then(res => {
      let data = res.data.data.filter(g => (g.disabled === false))
      let FICategories = data.map((r) => ({ value: r.id, name: 'fiCategory', label: r.name, costObjectRequired: r.costObjectRequired }));
      this.setState({ FICategories: FICategories });
    })
      .catch(error => {
        errorAction(error)
      })
  }

  GetAllFICostObjects = () => {
    Preloader();

    ajaxCall('Invoice/GetAllFICostObjects').then(res => {
      closeAlert();
      this.setState({ FICostObjects: res.data.data });
    })
      .catch(error => {
        errorAction(error);
      })
  }

  /**
   * @param {any} x
   * @param {String} y
   * @return {boolean}
 */
  validateInvoiceCurrencies = (x, y) => {
    let currency = x?.value

    let invoiceCurrency = currency.replace(/[0-9]/g, '');
    let currencyView = y.replace(/[0-9]/g, '');

    if (invoiceCurrency === currencyView)
      return false;

    else if ((invoiceCurrency !== currencyView) && (invoiceCurrency === "NGN"))
      return true;

    else {
      if (currencyView !== "NGN")
        return false;

      return true;
    }
  }
  





  setBankAccount = (e) => {
    if (this.state.invoice.invoiceCurrency.value === '') {
      errorAlert('Please select invoice currency first');

      /** @type HTMLInputElement */
      let input = document.querySelector('#' + e.target.id);
      input.checked = false;

      return;
    }

    let bankinfo = this.state.bankDetails[e.target.value];

    let currency = this.state.invoice.invoiceCurrency.value.replace(/[0-9]/g, '');
    let selected_currency = bankinfo.currency.replace(/[0-9]/g, '');

    if (selected_currency !== currency)
      alertMessage('You have selected ' + selected_currency + ' for an invoice with ' + currency + ' currency. <br> Are you sure you want to continue with ' + selected_currency + ' currency?');

    this.setState({ checkedBankDetails: bankinfo });
  }










  pushIntoInvoice = () => {
    if (this.state.referenceReponseStatus !== true) { alertPrompt('Please enter a valid invoice reference number!'); return; }
    if (this.state.invoice.invoiceCurrency.value === '') { alertPrompt('Please select your invoice currency'); return; }
    if (this.state.invoice.fiCategory.value === '') { alertPrompt('Please select Non-PO category'); return; }
    if (this.state.invoice.tepNgCompany.value === '') { alertPrompt('Please select TotalEnergies company'); return; }

    let allItems = this.state.invoiceItems;
    let newItem = this.state.form;

    let Item = {};
    Item.ItemNo = (allItems.length + 1).toString();
    Item.Description = newItem.description;
    Item.Amount = Number(newItem.amount);
    Item.VATTaxable = newItem.vat;
    Item.FICostObject = newItem.fiCostObject.value !== '' ? newItem.fiCostObject : { "label": 'Select TotalEnergies Company First', "value": '' };

    if (Item.Description === '' || Item.Amount === 0) { alertPrompt('Fields cannot be empty'); return; }
    
    if (this.state.invoice.fiCategory.costObjectRequired && this.state.form.fiCostObject.value === null) {
      alertPrompt('Cost Object is required'); return;
    }

    allItems.push(Item);
    this.setState({ invoiceItems: allItems });
    this.setState(prevState => ({ form: { ...prevState.form, 'vat': true, 'amount': '', 'description': '', 'fiCostObject': { label: 'Select TotalEnergies Company First', value: null } } }));

    this.setState({ showNewItemRow: false });
  }

  addNewItemRow = () => {
    this.setState({ showNewItemRow: true });
  }

  removeInvoice = (id) => {
    this.setState({ invoiceItems: this.state.invoiceItems.filter(g => !(g.ItemNo === id)) }, this.refreshItems);
  }

  refreshItems = () => {
    this.setState({ invoiceItems: this.state.invoiceItems.map((r, i) => ({ ...r, ItemNo: i + 1 })) })
  }

  handleChangeInfo = (e, x) => {
    if (e.name === 'fiCostObject') {
      this.setState({
        invoiceItems: this.state.invoiceItems.map(g => g.ItemNo === x.ItemNo ? { ...g, 'FICostObject': e } : g)
      })
    }
    else {
      this.setState({
        invoiceItems: this.state.invoiceItems.map(g => g.ItemNo === x.ItemNo ? { ...g, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value } : g)
      })
    }
  }

  ShowSortedCostObject = () => {
    console.log(this.state.invoiceItems);

    let invoiceItems = this.state.invoiceItems.reduce((acc, item) => {
      let foo = acc[item.FICostObject.jointVentureCode] ? acc[item.FICostObject.jointVentureCode] : [];
      return {
        ...acc,
        [item.FICostObject.jointVentureCode]: [
          ...foo,
          item
        ],
      };
    }, {});

    let data = Object.values(invoiceItems);

    let dataRef = data.map((e, i) => {
      e.map((x) => {
        x.invoiceReferenceId = i === 0 ? this.state.invoice.invoiceReferenceId : this.state.invoice.invoiceReferenceId + '-' + i;
        return null
      })

      return [...e]
    });


    this.setState({ isShowInvoiceTable: false, isSortCostObject: true, isShowBankRecord: false, sortedByCostObject: dataRef });
  }

  HideSortCostObject = () => {
    this.setState({ isShowInvoiceTable: false, isSortCostObject: false, isShowBankRecord: true });
  }

  ShowBank = () => {
    this.setState({ isShowBankRecord: true, isShowInvoiceTable: false });
  }

  HideBank = () => {
    this.setState({ isShowBankRecord: false, isShowInvoiceTable: true });
  }

  showAllTepngCompany = () => {
    // call original file;
    let tepgCompanyData = this.state.TepgCompanyData;
    let TepgCompany = tepgCompanyData.map((r) => ({ value: r.companyCode, name: 'tepNgCompany', label: r.name }));

    this.setState({ TepgCompany: TepgCompany, isShowAllTECompanies: true });
  }


  filterFICostObject = (e) => {
    this.handleInvoiceSelectChange(e);

    let sortedData = this.state.FICostObjects.filter(g => g.companyCode === e.value);
    let costObjects = sortedData.map((r) => ({ value: r.costObjectNo, label: r.costObjectNo, jointVentureCode: r.jointVentureCode, name: 'fiCostObject' }));
    this.setState({ costObjects: costObjects });
  }

  updateSortedRefNo = (e, i) => {
    let invoiceReferenceId = e.target.value;
    let prevdata = this.state.sortedByCostObject;
    prevdata[i][0].invoiceReferenceId = invoiceReferenceId.replace(/\s/g, "");
    this.setState({ sortedByCostObject: prevdata });
  }

  CreateNonPO = (cansign, data, i) => {
    let items = [];

    let paylaod = {
      InvoiceReferenceId: i === 0 ? this.state.invoice.invoiceReferenceId : this.state.invoice.invoiceReferenceId + '-' + i,
      InvoiceCurrency: this.state.invoice.invoiceCurrency.value,
      BankName: this.state.checkedBankDetails.bankName,
      BankAccountNumber: this.state.checkedBankDetails.accountNumber,
      FICategoryId: this.state.invoice.fiCategory.value,
      SubsidiaryId: this.state.invoice.tepNgCompany.value,
      vendorCode: this.state.selectedVendor.vendorCode,
      Items: items,
    };

    data.map((row, i) => {
      let item = {
        "ItemNo": (i + 1).toString(),
        "Description": row.Description,
        "Amount": row.Amount,
        "VATTaxable": row.VATTaxable,
        "FICostObject": row.FICostObject.value
      }
      items.push(item);
      return null;
    });

    console.log(paylaod);

    Preloader();
    ajaxCall('Invoice/CreateFIInvoice', 'json', 'post', paylaod).then(res => {
      if (cansign === 'saveSign') {
        tinyAlert('success', 'Invoice was created successfully, kindly upload supporting documents.');
        let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
        window.open('/sign-fi-invoice/' + res.data.data.id, 'test', params);
      }
      else {
        tinyAlert('success', 'Invoice was successfully save to draft');
      }

      closeAlert();
    })
      .catch(error => {
        errorAction(error);
      })
  }

  handleCreateFIInvoice = (cansign) => {
    if (this.state.sortedByCostObject.length < 1) {
      confirmPrompt('Please complete your form to continue.');
      return;
    }

    this.state.sortedByCostObject.map((data_row, i) => {
      let items = [];

      let data = {
        InvoiceReferenceId: i === 0 ? this.state.invoice.invoiceReferenceId : this.state.invoice.invoiceReferenceId + '-' + i,
        InvoiceCurrency: this.state.invoice.invoiceCurrency.value,
        BankName: this.state.checkedBankDetails.bankName,
        BankAccountNumber: this.state.checkedBankDetails.accountNumber,
        FICategoryId: this.state.invoice.fiCategory.value,
        SubsidiaryId: this.state.invoice.tepNgCompany.value,
        vendorCode: this.state.selectedVendor.vendorCode,
        Items: items,
      };

      this.CreateFIInvoice(cansign, data);
      return data_row.map((row, i) => {
        let item = {
          "ItemNo": (i + 1).toString(),
          "Description": row.Description,
          "Amount": row.Amount,
          "VATTaxable": row.VATTaxable,
          "FICostObject": row.FICostObject.value
        }
        items.push(item);
        return '';
      });
    });
  }

  CreateFIInvoice = (cansign, data) => {
    console.log(data);

    Preloader();
    ajaxCall('Invoice/CreateFIInvoice', 'json', 'post', data).then(res => {
      if (cansign === 'saveSign') {
        // let invoiceResponse = res.data.data.invoice;
        // this.props.routerNavigate("/sign-fi-invoice?id=" + invoiceResponse.id + "&category=" + invoiceResponse.fiCategoryId);
      }
      else {
        successAlert('Invoice was successfully save to draft');
        // this.props.routerNavigate("/history");
      }
    })
      .catch(error => {
        errorAction(error);
      })
  }

  CreateFIInvoiceMultiJV = (cansign) => {
    if (this.state.sortedByCostObject.length < 1) { errorAlert('Please complete your form to continue.'); return; }

    let incremental;
    if (this.state.createMultipleInvoiceCounter === null) {
      incremental = this.state.sortedByCostObject.length - 1;
      this.setState({ createMultipleInvoiceCounter: incremental });
    }
    else {
      incremental = this.state.createMultipleInvoiceCounter - 1;
      this.setState({ createMultipleInvoiceCounter: incremental });
    }

    console.log(incremental);

    let items = this.state.sortedByCostObject[incremental].map((row, i) => {
      return {
        "ItemNo": (i + 1).toString(),
        "Description": row.Description,
        "Amount": row.Amount,
        "VATTaxable": row.VATTaxable,
        "FICostObject": row.FICostObject.value
      }
    });

    let data = {
      InvoiceReferenceId: this.state.sortedByCostObject[incremental][0].invoiceReferenceId,
      InvoiceCurrency: this.state.invoice.invoiceCurrency.value,
      BankName: this.state.checkedBankDetails.bankName,
      BankAccountNumber: this.state.checkedBankDetails.accountNumber,
      FICategoryId: this.state.invoice.fiCategory.value,
      SubsidiaryId: this.state.invoice.tepNgCompany.value,
      vendorCode: this.state.selectedVendor.vendorCode,
      Items: items,
    };

    Preloader();
    ajaxCall('Invoice/CreateFIInvoice', 'json', 'post', data).then(res => {
      if (cansign === 'saveSign') {
        tinyAlert('success', 'Invoice ' + data.InvoiceReferenceId + ' is created, please upload relevant documents?');
      }
      else {
        tinyAlert('success', 'Invoice ' + data.InvoiceReferenceId + ' is saved to draft!');

        let multi_jv_data = this.state.multiJVInvoice;
        multi_jv_data.push(res.data.data);
        this.setState({ multiJVInvoice: multi_jv_data });
      }

      if (incremental === 0) {
        this.setState({ createMultipleInvoiceCounter: null });
        if (cansign === 'saveSign') {
          this.props.routerNavigate('/sign-fi-invoice/' + res.data.data.id);
        }
        else {
          this.setState({ isFinalView: true });
          // check if its multijv
          // this.props.routerNavigate("/history");
        }
      }
      else {
        this.CreateFIInvoiceMultiJV(cansign);
      }

      closeAlert();
    })
      .catch(error => {
        errorAction(error);
      })
  }


  //!!! remove
  DeleteInvoice = (id) => {
    Preloader();

    ajaxCall('/Invoice/' + id, 'json', 'delete').then(res => {
      tinyAlert('success', 'Invoice was successfully deleted');
    })
      .catch(error => {
        errorAction(error);
      });
  }








  render() {
    const __this__ = this;
    const page_info = { page: `New Invoice ${this.state.selectedVendor.vendorCode !== '' ? ' - ' + this.state.selectedVendor.name + ' (' + this.state.selectedVendor.vendorCode + ')' : ''}`, breadcrumb: 'Create New Non-PO Invoice' };
    const store = this.state;

    return (
      <div className='mainApp'>
        <div className='container-fluid'>
          <div className='row'>
            {/* fixed side nav area */}
            <SideNav />

            <div className='main-content-wrap'>
              {/* fixed top area */}
              <TopNav />

              {/* Page title and breadcrumb */}
              <PageTitle pagedata={page_info} />

              <div className='container-fluid mb-5'>
                <div className='row'>
                  <div className='col-12 px-4 mb-5 sm-px-4'>
                    {(store.isShowInvoiceTable && !store.isFinalView) &&
                      <div className="mt-4 mt-sm">
                        <h6 className='py-3 px-4 mb-0 lead-txt'> Non-PO Invoice </h6>
                        <hr className='m-0' />
                        
                        {/* NonPOForm section */}
                        <NonPOForm parent={__this__} />

                      </div>
                    }


                    {this.state.isShowBankRecord && !this.state.isFinalView &&
                      <div className="mt-4 mt-sm  bg-white">

                        {/* SelectBank section */}
                        <SelectBank parent={__this__} />

                        <div className='row pt-2 py-3 px-4'>
                          <div className='col-sm-8 col-12'></div>
                          <div className='col-sm-2 col-12 mb-3'>
                            <button type='button' title="go back" onClick={this.HideBank} className='btn btn-danger font-12 border-radius-sm form-control'> <PrevIcon /> Back</button>
                          </div>
                          <div className='col-sm-2'>
                            <div className="form-group mb-3">
                              <button type='button' title="Next" onClick={this.ShowSortedCostObject} disabled={this.state.checkedBankDetails.accountNumber === ''} className='btn btn-primary font-12 border-radius-sm form-control'>
                                Next <NextIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    }


                    {this.state.isSortCostObject && !this.state.isFinalView &&
                      <div className="mt-4 mt-sm  bg-white">
                        <div className="mt-3">
                          <h6 className='py-3 px-4 mb-0 lead-txt'>
                            Invoice Summary
                          </h6>
                          <hr className='m-0' />

                          {
                            this.state.sortedByCostObject.length > 1 &&
                            <div className='info-alert font-14 p-2 mt-1 p-4'>VIMP System have split your invoice into multiple invoices because your invoice is a Multi-JV</div>
                          }
                        </div>

                        <div className="mt-1 mt-sm">
                          {
                            this.state.sortedByCostObject.map((data_row, i) => {
                              return (
                                <div className='mt-2' style={{ border: '3px dotted #f3f2f2', borderTop: '10px solid #f7f7f7' }} key={i}>
                                  <div className="mt-0 mb-0 mt-sm bg-white" >
                                    <div className='pt-3 py-3 px-4'>
                                      <div className='row mt-0 sm-mt-1 mb-1'>

                                        <div className='col-12 col-sm-12'>
                                          <p><u>Invoice {i + 1} of {this.state.sortedByCostObject.length}</u></p>
                                        </div>
                                        <div className='col-12 col-sm-2'>
                                          <label>Reference number:</label>
                                        </div>
                                        <div className='col-12 col-sm-10'>
                                          <div className="form-group mb-0">
                                            <input type="text" value={data_row[0].invoiceReferenceId} className="form-control input-font bold" onChange={(e) => { this.updateSortedRefNo(e, i) }} />
                                          </div>
                                        </div>
                                      </div>

                                      <div className='row mt-0 sm-mt-1 mb-1'>
                                        <div className='col-12 col-sm-2'>
                                          <label>Bank name:</label>
                                        </div>
                                        <div className='col-12 col-sm-10'>
                                          <div className="form-group mb-0">
                                            <input type="text" value={this.state.checkedBankDetails.bankName} className="form-control input-font bold" disabled />
                                          </div>
                                        </div>
                                      </div>

                                      <div className='row mt-0 sm-mt-1 mb-1'>
                                        <div className='col-12 col-sm-2'>
                                          <label>Account number:</label>
                                        </div>
                                        <div className='col-12 col-sm-10'>
                                          <div className="form-group mb-0">
                                            <input type="text" value={this.state.checkedBankDetails.accountNumber} className="form-control input-font bold" disabled />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="table-responsive">
                                        <table className="table font-12 table-striped table-hover mb-0 table-bordered">
                                          <thead className='text-white'>
                                            <tr>
                                              <th className='text-center' style={{ width: '63px' }}>Item No</th>
                                              <th className="w-220">Description</th>
                                              <th style={{ width: '180px' }}>Amount [ {this.state.invoice.invoiceCurrency.value.replace(/[0-9]/g, '')} ]</th>
                                              <th style={{ width: '180px' }}>Cost Object</th>
                                              <th style={{ width: '50px' }}>Vat</th>
                                            </tr>
                                          </thead>
                                          <tbody className='font-12'>
                                            {
                                              data_row.map((item, i) => {
                                                return (
                                                  <tr key={'inner' + i}>
                                                    <td className='text-center'><i className='w-60'>{i + 1}</i></td>
                                                    <td>
                                                      <input type="text" name='Description' disabled value={item.Description} className="w-220 form-control input-font p-1" />
                                                    </td>
                                                    <td>
                                                      <input type="number" name='Amount' disabled value={item.Amount} className="form-control input-font p-1 w-100" />
                                                    </td>
                                                    <td>
                                                      <input type="text" name='FICostObject' disabled value={item.FICostObject.value} className="form-control input-font p-1 w-100" />
                                                    </td>
                                                    <td className='text-center'>
                                                      <div className="form-check form-switch">
                                                        <input className="form-check-input" disabled type="checkbox" name='VATTaxable' checked={item.VATTaxable} />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              })
                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }

                          <div className='row py-3 px-4'>
                            <div className={this.state.sortedByCostObject.length === 1 ? 'col-sm-6 col-12' : 'col-sm-8 col-12'}></div>

                            <div className='col-sm-2 col-12 mb-3'>
                              <button type='button' title="go back" onClick={this.HideSortCostObject} className='btn btn-danger font-12 border-radius-sm form-control'> <PrevIcon /> Back</button>
                            </div>

                            <div className='col-sm-2 col-6'>
                              <div className="form-group mt-0 mb-5">
                                <button type='button' disabled={this.state.checkedBankDetails.accountNumber === ''} onClick={() => confirmPrompt("Do you want to save to draft?").then((r) => r.isConfirmed ? this.CreateFIInvoiceMultiJV('saveDraft') : false)} name="saveDraft" title="Save invoice to sign later" className='savedraftbtn btn btn-primary font-12 border-radius-sm form-control'>{this.state.sortedByCostObject.length > 1 ? 'Save all to draft' : 'Save as draft'}</button>
                              </div>
                            </div>

                            {
                              this.state.sortedByCostObject.length === 1 &&
                              <div className='col-sm-2 col-6'>
                                <div className="form-group mt-0 mb-5">
                                  <button type='button' disabled={this.state.checkedBankDetails.accountNumber === ''} onClick={() => confirmPrompt("Do you want to save and procced to upload relevant documents?").then((r) => r.isConfirmed ? this.CreateFIInvoiceMultiJV('saveSign') : false)} name="saveSign" title="Save & send for review" className='btn btn-primary font-12 border-radius-sm form-control'>Next</button>
                                </div>
                              </div>
                            }

                          </div>
                        </div>
                      </div>
                    }

                    {this.state.isFinalView &&
                      <div className="table-responsive mt-3">
                        <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                          <thead className='text-white' style={{ background: '#0556de' }}>
                            <tr>
                              <th className='text-center'>SN</th>
                              <th>Reference No</th>
                              <th className='text-center'>Amount</th>
                              <th>Date Created</th>
                              <th>Status</th>
                              <th style={{ width: '150px' }}>Author</th>
                              <th className='text-center' style={{ width: '120px' }}>Action</th>
                            </tr>
                          </thead>
                          <tbody className='font-12'>
                            {
                              this.state.multiJVInvoice.map((object, i) => {
                                return (
                                  <tr key={i}>
                                    <td className='text-center'>{i + 1}</td>
                                    <td>{object.invoiceReferenceId}</td>
                                    <td className='text-end'>{object.bankCurrency.replace(/[0-9]/g, '')}  {formatAmount(object.grossAmount)}</td>
                                    <td>{formatDate(object.createdDate, 'date')}</td>
                                    <td>{object.status}</td>
                                    <td className='text-break'>{object.createdBy}</td>
                                    <td>
                                      <Link to={object.invoiceType === "Non-PO" ? "/sign-fi-invoice/" + object.id : "/sign-invoice/" + object.id} className={`btn btn-sm btn-success bold border-radius-sm ${object.status === 'Not Found' && 'disabled'}`} target="_blank">
                                        {
                                          object.status === "Submitted" || object.status === "Rejected by Technical Rep." ?
                                            <i className='fa fa-eye font-14' title='View invoice'></i>
                                            :
                                            <i className='fa fa-paper-plane font-14' title='Submit invoice' style={{ transform: 'rotate(31deg)' }}> </i>
                                        }
                                      </Link>

                                      <Link to={object.invoiceType === "Non-PO" ? "/update-fi-invoice/" + object.id : "/update-invoice/" + object.id} className={`btn btn-sm bold btn-info border-radius-sm ${object.status === 'Submitted' && 'disabled'} ${object.status === 'Not Found' && 'disabled'} ${object.status === 'Awaiting Technical Rep. Review' && 'disabled'}`} style={{ marginLeft: '5px' }} title="Edit Invoice" target="_blank">
                                        <i className='fa fa-edit font-14'></i>
                                      </Link>

                                      <button disabled={object.status === 'Submitted' ? true : object.status === 'Not Found' ? true : false} className='btn btn-sm btn-danger bold border-radius-sm d-none' onClick={() => confirmPrompt("Do you want to delete invoice " + object.invoiceReferenceId + "?").then((r) => r.isConfirmed ? this.DeleteInvoice(object.id) : false)} style={{ marginLeft: '3px' }} title="Delete Invoice">
                                        <i className='fa fa-trash font-14'></i>
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FIInvoice);