import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { NotificationManager } from 'react-notifications';

/* Config */
axios.defaults.withCredentials = true;
const MySwal = withReactContent(Swal);

export const SERVER_URL = process.env.REACT_APP_SERVER_URL + '/api/';
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const ADMINEND_URL = process.env.REACT_APP_ADMINEND_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_LAST_UPDATED = new Date(Number(process.env.REACT_APP_LAST_UPDATED) * 1000).toLocaleString();

console.log('SERVER_URL:' + SERVER_URL)
console.log('FRONTEND_URL:' + FRONTEND_URL)
console.log('ADMINEND_URL:' + ADMINEND_URL)
console.log('ENVIRONMENT:' + ENVIRONMENT)
console.log('APP_VERSION:' + APP_VERSION)
console.log('APP_LAST_UPDATED:' + APP_LAST_UPDATED)

export var bearerToken = null;
export var bearerTokenExpiration = null;
export var vendorInfo = {};
export const Spinner = '<i class="fa fa-spin fa-circle-o-notch"></i> Loading..';
export const recordPerRows = [5,10,15,25,100,250];

var timerRefno

/**
 * @param {number} price
 * @return {string}
 */
export function formatAmount(price) {
  if (price)
    return price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  else
    return '0'
}

/**
 * @param {number} value
 * @return {string}
 */
export function twoDecimalPlace(value) {
  if (value)
    return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else
    return '0';
}

/**
 * @param {String} date
 * @param {String} format
 * @return {string}
 */
export function formatDate(date, format) {
  let d = new Date(date);

  if (format === 'time') {
    return d.toLocaleTimeString('en-US');
  }
  else if (format === 'date') {
    return d.toDateString();
  }
  else {
    return d.toDateString() + ' ' + d.toLocaleTimeString('en-US');
  }
}

/**
 * @param {String} message
 */
export function errorAlert(message) {
  MySwal.fire({
    icon: 'error',
    timer: 150000,
    timerProgressBar: true,
    html: `<span style="font-size:14px">${message}</span>`,
    footer: '<a href="/faq" style="font-size:12px">Why do I have this issue?</a>',
    showDenyButton: true,
    denyButtonText: `<small>Ok</small>`,
    showConfirmButton: false,
  })
}

/**
 * @param {String} message
 */
export function successAlert(message) {
  MySwal.fire({
    icon: 'success',
    html: `<span style="font-size:14px">${message}</span>`
  })
}

/**
 */
export function Preloader() {
  MySwal.fire({
    title: "",
    html: '<div style="height:120px;width:120px;margin: 0 auto;"><img style="width: 80px;" src="../loading-icon-red.gif" /><br> <strong class="font-12"> Please Wait... </strong> </div>',
    showConfirmButton: false,
    showCancelButton: false,
    allowOutsideClick: false,
  })
}

/**
 *
 */
export function closeAlert() {
  MySwal.close();
}

/**
 * @param {String} message
 */
export function confirmPrompt(message) {
  return MySwal.fire({
    html: `<span style="font-size:14px">${message}</span>`,
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: 'red',
    confirmButtonText: `<span>Yes</span>`,
    cancelButtonText: `<span>No</span>`
  })
}

/**
 * @param {String} message
 */
export function alertPrompt(message) {
  return MySwal.fire({
    html: `<span style="font-size:14px">${message}</span>`,
    icon: 'info',
    showCancelButton: false,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: 'red',
    confirmButtonText: 'Ok'
  })
}

/**
 * @param {String} message
 */
export function alertMessage(message) {
  return MySwal.fire({
    html: `<span class="bold" style="font-size:16px">${message}</span>`,
    icon: 'info',
    showCancelButton: false,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Ok'
  })
}


/**
 * @param {String} message
 */
export function registerMessage(message) {
  return MySwal.fire({
    html: `<span class="bold" style="font-size:14px">${message}</span>`,
    icon: 'info',
    showCancelButton: false,
    confirmButtonColor: 'green',
    confirmButtonText: 'Register'
  })
}



/**
 * @param {object} error
 */
export function errorAction(error) {
  if (error?.response?.status) {
    if (error.response.status === 401) {
      alert("Error Detected! please reload your page!");
      // window.location.reload();
      closeAlert();
    }
    else {
      if (error.response?.data?.message == null){
        if (error.response.data instanceof Blob)
          errorAlert('No documents have been uploaded.');
        else
          errorAlert(error.message);
      }
      else
        errorAlert(error.response.data.message);
    }
  }
  else {
    errorAlert(error.message);
  }
}

/**
 * @param {boolean} requiresToken
 * @return {boolean}
 */
export function isAccessTokenValid(requiresToken) {
  if (requiresToken !== true)
    return true;
  else {
    if (bearerTokenExpiration === '')
      return false;

    let expiration = new Date(bearerTokenExpiration);
    let current = new Date();
    let time_extras = 3 * 60 * 1000;

    if ((expiration.getTime() - time_extras) > current.getTime())
      return true;
    else
      return false;
  }
}

/**
 * @param {string} password
 * @return {boolean}
 */
export function passwordRules(password) {
  if (!password.match(/^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,}$/)) {
    return true;
  }
}

/**
 */
export function logout() {
  Preloader();
  return axios({
    method: "post",
    url: SERVER_URL + "AuthenticateVendor/logout",
    withCredentials: true,
    data: {},
    headers: { "Content-Type": "application/json", "Authorization": `Bearer ${bearerToken}` }
  })
    .then(res => {
      closeAlert();

      bearerToken = null;
      bearerTokenExpiration = null;
      vendorInfo = null;
      localStorage.clear();
      window.location.href = '/login';
    })
}


// *! refreshToken controller - extends bearerToken
/**
 * @param {boolean} redirectToLogin
 */
export async function refreshToken(redirectToLogin) {
    await axios({
      method: "post",
      url: SERVER_URL + "AuthenticateVendor/RefreshToken",
      withCredentials: true,
      data: {},
      headers: { "Content-Type": "application/json" }
    })
    .then(res => {
      let data              = res.data.data.vendorAccessToken;
      bearerToken           = data.token;
      bearerTokenExpiration = data.expiration;
      vendorInfo            = res.data.data.vendor;
    })
    .catch(error => {
      bearerToken           = null;
      bearerTokenExpiration = null;
      vendorInfo            = null;
      
      if (redirectToLogin) {
        tinyAlert("error","Your session has expired, you will be redirected to login page");
        const navigate = useNavigate();
        navigate('/login');
      }
    })

  return await new Promise(async (resolve, reject) => { resolve() })
}

/**
 * @param {import("axios").Method} method
 * @param {string} endpoint
 * @param {any} data
 * @param {boolean} requireToken
 * @param {import("axios").ResponseType} responseType
 * @return {Promise<import("axios").AxiosResponse<any>>}
 */
export async function ajaxCall(endpoint, responseType = 'json', method = 'get', data = {}, requireToken = true) {
  if (!isAccessTokenValid(requireToken))
    await refreshToken(true);

  return axios({
    method: method,
    url: SERVER_URL + endpoint,
    data: data,
    withCredentials: true,
    headers: { "Content-Type": "application/json", "Authorization": `Bearer ${bearerToken}` },
    responseType: responseType
  })
}

/**
 * @param {string} endpoint
 * @param {object} data
 */
export function Login2FA(endpoint, data = {}) {
  return axios({
    method: 'post',
    url: SERVER_URL + endpoint,
    data: data,
    withCredentials: true,
    headers: { "Content-Type": "application/json" },
    responseType: 'json'
  })
    .then(res => {
      let data = res.data.data.vendorAccessToken;
      bearerToken = data.token;
      bearerTokenExpiration = data.expiration;
      vendorInfo = res.data.data.vendor;
    })
}

/**
 * @param {string} id
 * @param {string} show
 * @param {string} hide
 */
export function htmlinputType(id, show, hide) {
  /** @type HTMLInputElement */
  let input = document.querySelector("#" + id);

  /** @type HTMLInputElement */
  let btnHide = document.querySelector("#" + hide);

  /** @type HTMLInputElement */
  let btnShow = document.querySelector("#" + show);

  if (input.type === "password")
    input.type = "text";
  else
    input.type = "password";

  btnShow.style.display = 'block'
  btnHide.style.display = 'none'
}

/**
 * @param {string} type
 * @param {string} message
 */
export function tinyAlert(type, message) {
  switch (type) {
    case 'info':
      NotificationManager.info(message, '', 5000);
      break;
    case 'success':
      NotificationManager.success(message, '', 5000);
      break;
    case 'warning':
      NotificationManager.warning(message, '', 5000);
      break;
    case 'error':
      NotificationManager.error(message, '', 5000);
      break;
    default:
      NotificationManager.error(message, '', 5000);
  }
}

export const displayExtendLoginSession = () => {
  MySwal.fire({
    icon: 'info',
    timer: 150000,
    timerProgressBar: true,
    html: `<span style="font-size:14px"><b>Your current session is inactive</b><br /> Click OK to stay signed in.</span>`,
    showDenyButton: false,
    denyButtonText: `<span>Yes</span>`,
    showConfirmButton: true,
    allowOutsideClick: false,
  })
    .then(function (r) {
      if (!r.isConfirmed)
        logout();
    })
}

const resetTimer = () => {
  if (timerRefno != null) 
    clearTimeout(timerRefno)

  timerRefno = setTimeout(displayExtendLoginSession, (10 * 60 * 1000));
}

export function inactivityTime() {
  document.onmousemove = resetTimer;
  document.onkeydown = resetTimer;
  document.onkeyup = resetTimer;
  document.onclick = resetTimer;
}






// *! form input controller - binds data into component-form state
/**
 * @param {object} e
 * @param {object} component
 */
export const handleInputChange = (e, component, type = 'input') => {
  const input = e.target;
  component.setState(prevState => ({ 
    form : (type === 'input') ? {  ...prevState.form, [input.name] : (input.type === 'checkbox') ? input.checked : input.value } : { ...prevState.form, [e.name]: e }
  }))
};

// *! change invoice reference
/**
 * @param {object} e
 * @param {object} component
 */
export const handleInvoiceReferenceInputChange = (e, component) => {  
  component.setState({ 
    referenceReponseStatus: false, 
    referenceshortMsg: '' 
  });

  

  let invoice_reference = e.target.value.replace(/\s/g, "");

  component.setState(prevState => ({ 
    invoice: { ...prevState.invoice, [e.target.name]: invoice_reference } 
  }))

  if (invoice_reference.length > 1) {
    if(component.state.UsedInvoice.has(invoice_reference.toLocaleUpperCase())){      
      component.setState({
        referenceReponseStatus: false,
        referenceshortMsg: 'Already Used!'
      });
    }
    else{
      component.setState({
        referenceReponseStatus: true,
        referenceshortMsg: 'Available!'
      });
    }
  }
  else {
    component.setState({
      referenceReponseStatus: false,
      referenceshortMsg: 'Required'
    });
  }
}

// *! get invoice by ID
export const getInvoiceByID = (id, component, callack) => {
  Preloader();

  ajaxCall('Invoice/'+id, 'json')
  .then(res => {
    component.setState({invoice : res.data.data}, callack);
  })
  .catch(error => {
    errorAction(error)
  })
}

// *! get selected vendor info
export const GetVendor = (id, component) =>{
  ajaxCall('Vendor/GetVendorsForLoggedOnVendorUser').then(res => {
    let vendor = res.data.data.filter(i => i.vendorCode === id);
    component.setState({ vendor: vendor[0] });
  })
  .catch(error => {
    errorAction(error);
  })
}

// *!!! get previously used reference number
export const GetPreviouslyUsedInvoiceReferenceIds = (id, component, exclude='') => {
  ajaxCall('Invoice/GetPreviouslyUsedInvoiceReferenceIds/' + id).then(res => {
    let used_invoices = res.data.data.filter(i => i !== exclude);
    component.setState({ UsedInvoice: new Set(used_invoices)});
    closeAlert();
  })
  .catch(error => {
    errorAction(error);
  })
}

export const filterSESTable = (e, component, callack) => {
  let result
  if (e != null) {
    let search = e.target.value.toLowerCase();
    result = component.state.availableSESs.filter((data) => data.sesItemDescription.toLowerCase().includes(search) || data.sesNo.includes(search) || data.sesAmount.toString().includes(search));
  }
  else
    result = component.state.availableSESs
    
  component.setState({ filteredSESs: result.map((r, i) => ({ ...r, index: i + 1 })) }, callack);
}

export const updateTablePagination = (component, callack) => {
  let recordCount = component.state.invoice.invoiceType === 'Material PO' ? component.state.filteredGRs.length : component.state.filteredSESs.length
  let numOfPages = Math.ceil(recordCount / component.state.numRecordsPerPage)
  let currentPage = component.state.currentPage
  if (currentPage < 1) currentPage = 1;
  if (currentPage > numOfPages) currentPage = numOfPages;

  component.setState({ currentPage: currentPage, numOfPages: numOfPages }, callack)
}

export const setRecordsForCurrentPage = (component) => {
  const offset = (component.state.currentPage - 1) * component.state.numRecordsPerPage;
  if (component.state.invoice.invoiceType === 'Material PO')
    component.setState({ currentPageGRs: component.state.filteredGRs.slice(offset, offset + component.state.numRecordsPerPage) });
  else
    component.setState({ currentPageSESs: component.state.filteredSESs.slice(offset, offset + component.state.numRecordsPerPage) });
}