import React, { Component } from 'react';
import Logo from '../assets/img/logo.png';
// import VimpLogo from '../assets/img/vimp-logo.png';
import SideImg from '../assets/img/login-side-img.png';
import NoImg from '../assets/img/nopage.png';

class LoginAside extends Component {
  state = {
    webpage: ['/login', '/reset-password', '/password-update', '/register', '/successful']
  }

  render() {
    return (
      <>
        <div className="col-sm-7 d-none d-lg-block">
          <div className='mx-auto login-side-wrap'>
            <div className='d-flex auth-logo-area'>          
              <img src={Logo} alt='logo' style={{height: '100px'}} /> 
              {/* <img src={VimpLogo} className="mt-4" alt='logo' style={{height: '60px', marginLeft:'30px', borderLeft: '1px solid #bfbfbf', paddingLeft:'40px' }} />    */}

              <h1 className='appname'>Vendor Invoice <span style={{color:'rgb(249 0 0)', fontWeight:'400', textShadow:'rgb(106 51 51 / 63%) 0px 0px 1px', fontSize: '14px'}}>Management Portal</span></h1>
            </div>


            {
              this.state.webpage.includes(window.location.pathname) ?              
                <img src={SideImg} className="col-12" alt="bg" /> :              
                <img src={NoImg} className="col-12" alt="bg" />            
            }
          </div>
        </div>
             
        <div className='text-center d-block d-lg-none'>
          <img className='col-6 col-sm-4' src={Logo} alt='logo' />
        </div> 
      </>
    );
  }
}

export default LoginAside;