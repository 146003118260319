import React, { Component } from 'react';


import LoginAside from '../../layouts/LoginAside';
import Copyright from '../../layouts/Copyright';
import withRouter from '../../../src/WithRouter';
import {handleInputChange, errorAlert, ajaxCall, passwordRules, errorAction, Spinner, htmlinputType, successAlert } from '../../helpers/Helpers'


class ResetUpdate extends Component {
  componentDidMount() {
    this.pageLoad();
  }

  state = {
    passwordToken:'',
    email:'',
    form:{    
      newPassword:'',
      reNewPassword:'',
    },
  }

  
  // *! form input controller - binds data into state
  /** @param {any} e */
  handleInput = (e) => { handleInputChange(e, this); }

  pageLoad = () => {
    let queryString   = window.location.search;
    let urlParams     = new URLSearchParams(queryString);
    let email  = urlParams.get('Email');
    let passwordToken = urlParams.get('ResetPasswordToken');

    if(email && passwordToken)
      this.setState({email:email, passwordToken:passwordToken});
  }


  handleReset = (e) =>{
    e.preventDefault();
    let form = this.state.form;

    let newPassword   = form.newPassword;
    let renewPassword = form.reNewPassword;

    if(newPassword === ""){  errorAlert("Password cannot be empty");  return;  }
    if(passwordRules(newPassword)){ errorAlert("Password must contain atleast one Uppercase, Lowercase, Digits and cannot be less than Eight Characters");  return; }
    if(newPassword !== renewPassword){ errorAlert("Password and confirm password did not match!");  return; }


    let form_data = {
      email: this.state.email,
      resetPasswordToken: this.state.passwordToken,
      NewPassword: form.newPassword,
    }

    let updateBtn = e.target.updateBtn;
    let button_name = updateBtn.innerHTML;

    updateBtn.disabled = true;
    updateBtn.innerHTML = Spinner;

    ajaxCall('AuthenticateVendor/ResetPassword', 'json', 'post', form_data, null).then(res => {
      this.setState(prevState => ({ form: {  ...prevState.form, 'VendorCode' : '' }}))
      
      updateBtn.disabled = false;
      updateBtn.innerHTML = button_name;

      successAlert(res.data.message);
      this.props.routerNavigate("/login");  
    })
    .catch(error => { 
      errorAction(error);
      
      updateBtn.disabled = false;
      updateBtn.innerHTML = button_name;
    })
  }


  render() {
    const form = this.state.form;
    return (
        <div className='loginBG'>
          <div className='container-fluid'>
            <div className='row'>
              <LoginAside />
                        
              <div className='col-sm-5 form-aside'>
                <div  className='mx-auto login-side-wrap'>

                  <h1 className='login-lead'>Password Update</h1>
                  <p className='font-12'>Hello <span className='text-success'>{this.state.email}</span>. Kindly enter your new preferred password to reset your password. This page will expire in thirty minutes</p>
                  
                  
                  <form className="login-form mt-3" autoComplete="off" method="post" onSubmit={this.handleReset}>
                    <label className='info-txt animated flipInY mb-1'>Password must contain atleast one Uppercase, Lowercase, Digits and cannot be less than Eight Characters</label> 

                    <input type="hidden" autoComplete="off" />
                    <div className="form-group mb-0">
                      <span className='input-overlay'><b className="fa fa-asterisk text-danger font-12"></b> New Password </span>
                      <input type="password" name='newPassword' id='newPassword' value={form.newPassword} onChange={this.handleInput} autoFocus className="form-control input-font p-3 curved-top" autoComplete="off" placeholder="Enter your new password" required onPaste={(e)=>{e.preventDefault(); return false;}} onCopy={(e)=>{e.preventDefault(); return false;}} onDrop={(e)=>{e.preventDefault(); return false;}} onDragStart={(e)=>{e.preventDefault(); return false;}} />

                      <i className='fa input-icon fa-eye' id='inputEye' onClick={() => htmlinputType('newPassword','inputEyeClose', 'inputEye')}></i>
                      <i className='fa input-icon fa-eye-slash' style={{display:'none'}} id='inputEyeClose' onClick={() => htmlinputType('newPassword','inputEye', 'inputEyeClose')}></i>
                    </div>

                    <div className="form-group">
                      <span className='input-overlay'><b className="fa fa-asterisk text-danger font-12"></b> Re-Enter New Password </span>
                      <input type="password" name='reNewPassword' id='reNewPassword' value={form.reNewPassword} onChange={this.handleInput} className="form-control input-font p-3 curved-bottom" autoComplete="off" placeholder="Re-enter your new password" required onPaste={(e)=>{e.preventDefault(); return false;}} onCopy={(e)=>{e.preventDefault(); return false;}} onDrop={(e)=>{e.preventDefault(); return false;}} onDragStart={(e)=>{e.preventDefault(); return false;}} />

                      <i className='fa input-icon fa-eye' id='inputEye2' onClick={() => htmlinputType('reNewPassword','inputEyeClose2', 'inputEye2')}></i>
                      <i className='fa input-icon fa-eye-slash' style={{display:'none'}} id='inputEyeClose2' onClick={() => htmlinputType('reNewPassword','inputEye2', 'inputEyeClose2')}></i>
                    </div>

                    <div className="form-group mt-3">
                      <button type='submit' name='updateBtn' className='form-control btn btn-primary p-3 font-12'>Update Password</button>
                    </div>
                  </form>

                  <Copyright />
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(ResetUpdate);