import React, { Component } from 'react';

import NextIcon from '../layouts/NextIcon';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Select from 'react-select'
const filterOptions = createFilterOptions({ limit: 100 });

class NonPOForm extends Component {
    render() {
        let parent =this.props.parent
        let state = parent.state
        return (
            <form className="py-3 px-4 bg-white" autoComplete="off" method="post">
                <div className='row mt-3  sm-mt-1'>
                    <div className='col-sm-2 col-12'>
                        <label className='font-14 bolder'>Vendor's Company</label>
                    </div>
                    <div className='col-sm-10 col-12'>
                        <div className="form-group mb-0">
                            <input type="text" value={state.selectedVendor.name} className="form-control input-font bolder" placeholder="" disabled />
                        </div>
                    </div>
                </div>

                <div className='row mt-3 sm-mt-1'>
                    <div className='col-sm-2 col-12'>
                        <label className='font-14 bolder'>Invoice Reference</label>
                    </div>
                    <div className='col-12 col-sm-10'>
                        <div className='info-alert font-12 p-1 mt-1'>Invoice number cannot be more than sixteen (16) characters.</div>
                        <div className="form-group mb-0 mt-1">
                            <input type="text" name='invoiceReferenceId' onChange={parent.handleInvoiceReference} value={state.invoice.invoiceReferenceId}  maxLength={16} minLength={2} className="form-control input-font bolder" autoComplete="off" placeholder="Enter Invoice Reference" required />


                            <i className={`fa input-icon ${state.referenceReponseStatus ? 'fa-check text-success' : 'fa-times text-danger'}`} style={{ top: '1px' }}></i>

                            <label className={`input-feedback font-12 animated fadeIn bold ${state.referenceReponseStatus === false ? ' text-danger' : ' text-success'}`}>{state.referenceshortMsg}</label>
                        </div>
                    </div>
                </div>

                <div className='row mt-3 sm-mt-1'>
                    <div className='col-sm-2 col-12'>
                        <label className='font-14 bolder'>Currency</label>
                    </div>
                    <div className='col-sm-10 col-12'>
                        <div className="form-group mb-0">
                        <Select name='invoiceCurrency'
                            value={state.invoice.invoiceCurrency}
                            onChange={parent.handleInvoiceSelectChange}
                            isSearchable={false}
                            options={state.currencies}
                        />
                        </div>
                    </div>
                </div>

                <div className='row mt-3 sm-mt-1'>
                    <div className='col-sm-2 col-12'>
                        <label className='font-14 bolder'>Non-PO Category</label>
                    </div>
                    <div className='col-sm-10 col-12'>
                        <div className="form-group mb-0">
                        <Select name='fiCategory'
                            value={state.invoice.fiCategory}
                            onChange={parent.handleInvoiceSelectChange}
                            isSearchable={true}
                            placeholder="Select Non-PO Category..."
                            options={state.FICategories}
                        />
                        </div>
                    </div>
                </div>

                <div className='row mt-3 sm-mt-1'>
                    <div className='col-sm-2 col-12'>
                        <label className='font-14 bolder'>TotalEnergies Company</label>
                    </div>
                    <div className='col-sm-10 col-12'>
                        <div className="form-group mb-0">
                            <Select name='tepNgCompany'
                                value={state.invoice.tepNgCompany}
                                onChange={e => { parent.filterFICostObject(e) }}
                                isSearchable={true}
                                placeholder="Select Company Name..."
                                options={state.TepgCompany}
                            />
                        </div>
                        <div className='text-end'>
                        {
                            !state.isShowAllTECompanies ?
                            <button type='button' className='font-12 text-primary btn p-0' onClick={parent.showAllTepngCompany}>Tep ng company not listed? View all</button>
                            :
                            <button type='button' className='font-12 text-success btn p-0'>List updated!</button>
                        }
                        </div>
                    </div>
                </div>

                {/* invoice line item input area  */}
                <div className='bg-white mt-sm'>
                    <div className="">
                        <table className="table font-12 table-striped table-hover mb-0 table-bordered">
                            <thead className='text-white'>
                                <tr>
                                    <th className='text-center' style={{ width: '63px' }}>Item No</th>
                                    <th className="w-220">Description</th>
                                    <th style={{ width: '180px' }}>Amount</th>
                                    { state.invoice.fiCategory.costObjectRequired && <th style={{ width: '180px' }}>Cost Object Required</th> }
                                    <th style={{ width: '50px' }}>Vat</th>
                                    <th style={{ width: '100px' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody className='font-12'>
                            { 
                                state.invoiceItems.map(object => {
                                    return (
                                        <tr key={object.ItemNo}>
                                            <td className='text-center'><span className='w-60'>{object.ItemNo}</span></td>
                                            <td>
                                                <input type="text" name='Description' value={object.Description} onChange={(e) => parent.handleChangeInfo(e, object)} className="w-220 form-control input-font p-2" />
                                            </td>
                                            <td>
                                                <input type="number" name='Amount' value={object.Amount} onChange={(e) => parent.handleChangeInfo(e, object)} className="form-control input-font p-2 w-100" />
                                            </td>
                                            {
                                            state.invoice.fiCategory.costObjectRequired &&
                                            <td>
                                                <Autocomplete
                                                    filterOptions={filterOptions}
                                                    disableClearable={true}
                                                    onChange={(e, v) => parent.handleChangeInfo(v, object)}
                                                    id="combo-box-demo"
                                                    options={state.costObjects}
                                                    sx={{ width: 300 }}
                                                    value={object.FICostObject}
                                                    renderInput={(params) => <TextField placeholder='Select TotalEnergies Company First' variant='standard' size='small' {...params} />} />
                                            </td>
                                            }
                                            <td className='text-center'>
                                                <div className="form-check form-switch mt-2">
                                                <input className="form-check-input" type="checkbox" name='VATTaxable' checked={object.VATTaxable} onChange={(e) => parent.handleChangeInfo(e, object)} />
                                                </div>
                                            </td>
                                            <td><button type='button' className='btn btn-sm p-2 btn-danger bold border-radius-sm' onClick={() => parent.removeInvoice(object.ItemNo)} style={{ width: '70px' }}>Remove</button></td>
                                        </tr>
                                    )
                                })
                            }

                            {
                                state.showNewItemRow &&
                                <tr>
                                    <td className='text-center'><span className='w-60'>#</span></td>
                                    <td>
                                        <input type="text" placeholder='Description...' className="form-control input-font p-2 w-220" name='description' value={state.form.description} onChange={parent.handleFormInputsChange} />
                                    </td>
                                    <td>
                                        <input type="number" step=".01" placeholder='Amount' className="form-control input-font p-2 w-100" name='amount' value={state.form.amount} onChange={parent.handleFormInputsChange} />
                                    </td>
                                    {
                                        state.invoice.fiCategory.costObjectRequired &&
                                        <td>
                                            <Autocomplete
                                                value={state.form.fiCostObject}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                filterOptions={filterOptions}
                                                disableClearable={true}
                                                onChange={parent.handleFormAutocompleteChange}
                                                id="combo-box-demo"
                                                options={state.costObjects}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField placeholder='Select TotalEnergies Company First' variant='standard' size='small' {...params} />} />
                                        </td>
                                    }
                                    <td className='text-center'>
                                        <div className="form-check form-switch mt-2">
                                        <input className="form-check-input" type="checkbox" name='vat' checked={state.form.vat} onChange={parent.handleFormInputsChange} />
                                        </div>
                                    </td>
                                    <td><button  type='button' className='btn btn-sm btn-primary bold border-radius-sm p-2' style={{ width: '68px' }} onClick={() => parent.pushIntoInvoice()}>Save</button></td>
                                </tr>
                            }

                            <tr>
                                <td><button type="button" className='btn btn-sm btn-primary bold border-radius-sm p-2' style={{ width: '68px' }} onClick={() => parent.addNewItemRow()}>Add new</button></td>
                                <td></td>
                                <td></td>
                                { state.invoice.fiCategory.costObjectRequired && <td></td>  }
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* select bank next button */}
                <div className='row py-3 bg-white m-0'>
                    <div className='col-10'></div>
                    <div className='col-sm-2 p-0'>
                    <div className="form-group mb-3">
                        <button type='button' title="Next" onClick={parent.ShowBank} disabled={state.invoiceItems.length > 0 ? false : true} className='btn btn-primary font-12 border-radius-sm form-control'>
                        Next <NextIcon />
                        </button>
                    </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default NonPOForm;