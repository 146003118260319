import React, { Component } from 'react';


class SelectBank extends Component {
    render() {
        let parent =this.props.parent
        let state = parent.state
        return (
            <div className="mt-1 bg-white">
                <h6 className='py-3 px-4 mb-0'> Bank Details </h6>
                <hr className='p-0 m-0' />

                <div className='pt-3 px-4'>
                    <form className="pb-3 mt-2" autoComplete="off" method="post">
                        {
                            state.bankDetails.map((object, i) => {
                            return( 
                                <div key={i}>
                                    <div className="bank-account-item active mb-4">
                                        <div className='row mb-0'>
                                            <div className='col-sm-4 col-12'>
                                                <div className="form-group mb-0">
                                                    <label className='font-12 input-overlay'>Bank Account Name</label>
                                                    <div className="form-control input-font bold">{object.accountName}</div>
                                                </div>
                                            </div>
                                            <div className='col-sm-4 col-12'>
                                                <div className="form-group mb-0">
                                                    <label className='font-12 input-overlay'>Bank Name</label>
                                                    <div className="form-control input-font bold">{object.bankName}</div>
                                                </div>
                                            </div>
                                            <div className='col-sm-4 col-12'>
                                                <div className="form-group mb-0">
                                                    <label className='font-12 input-overlay'>Bank Account Number</label>
                                                    <div className="form-control input-font bold">{object.accountNumber}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mb-0'>
                                            <div className='col-sm-4 col-12'>
                                                <div className="form-group mb-0">
                                                    <label className='font-12 input-overlay'>Bank Sort Code</label>
                                                    <div className="form-control input-font bold">{object.sortCode}</div>
                                                </div>
                                            </div>
                                            <div className='col-sm-4 col-12'>
                                                <div className="form-group mb-0">
                                                    <label className='font-12 input-overlay'>Swift Code</label>
                                                    <div className="form-control input-font bold">{object.swiftCode}</div>
                                                </div>
                                            </div>
                                            <div className='col-sm-4 col-12'>
                                                <div className="form-group mb-0">
                                                    <label className='font-12 input-overlay'>Bank Currency</label>
                                                    <div className="form-control input-font bold">{object.currency}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <input onChange={parent.setBankAccount} disabled={parent.validateInvoiceCurrencies(state.invoice.invoiceCurrency, object.currency)} type="radio" value={i} id={'bankCheck' + i} name='bankCheck' className='bankCheckbox' checked={state.checkedBankDetails.accountNumber !== '' ? state.checkedBankDetails.accountNumber === object.accountNumber : false} />
                                        <label htmlFor={'bankCheck' + i} className='bankChecklabel'></label>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </form>
                </div>
          </div>
        );
    }
}

export default SelectBank;