import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import 'react-notifications/lib/notifications.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


// import '../node_modules/animate.css/animate.css'  // you need to require the css somewhere
 



const root = ReactDOM.createRoot(document.getElementById('App'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();