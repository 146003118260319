import React, { Component } from 'react';

import withRouter from '../../../src/WithRouter';
import Select from 'react-select'
import SideNav from '../../layouts/SideNav';
import TopNav from '../../layouts/TopNav';
import PrevIcon from '../../layouts/PrevIcon';
import NextIcon from '../../layouts/NextIcon';
import PageTitle from '../../layouts/PageTitle';

import {handleInputChange, Preloader, ajaxCall, closeAlert, errorAction, confirmPrompt, formatAmount, Spinner, alertMessage, tinyAlert} from '../../helpers/Helpers';

class Invoice extends Component {
  componentDidMount() {
    this.GetVendorsForLoggedOnVendorUser()
  }

  state = {
    form: {
      vendorCode: { name: '', label: 'Select your company', value: '' },
      InvoiceType: { name: '', label: 'Select invoice type', value: '' },
      POType: {name: '', label: 'Select PO type', value: ''},
      PONumber: {name: '', label: 'Select PO number', value: ''},

      referenceNumber: '',
    },

    InvoiceType : [
      { value: 'POI', name: 'InvoiceType', label: 'Purchase Order Invoice' },
      { value: 'FOI', name: 'InvoiceType', label: 'Non Purchase Order Invoice' }
    ],
    POTypeList :[
      { value: 'material_po', name: 'POType', label: 'Material PO' },
      { value: 'service_po', name: 'POType', label: 'Service PO' }
    ],

    VendorList:[],
    UsedInvoice: new Set([]),

    servicePO: [],
    materialPO: [],
    POList: [],

    referenceReponseStatus: false,
    referenceshortMsg: 'Required',
    invoiceCurrency:'',


    currentPageSESs: [],
    filteredSESs: [],
    availableSESs: [],

    currentPageGRs: [],
    filteredGRs: [],
    availableGRs: [],

    numRecordsPerPage: 5,
    currentPage: 1,
    numOfPages: 1,


    bankDetails: [],
    checkedGR: [],
    checkedGRI: [],

    checkedSESList: [],
    checkedGRList: [],
    checkedBankDetails: {accountNumber:''},

    hasSelectedCompanyAndInvoiceType: false,
    canShowPOform: false,


    isVerifyReferenceid: false,
    isSESInvoice: false,
    isMPOInvoice: false,

    canShowPreview: false,
    canShowSigning: false,

    signingURL: '',
    invoiceNo: '',

    isCheckedAllGRs:false,
    isCheckedAllSESs:false,
    Setting:{}
  }

  
  // *! form input controller - binds data into state
  /** @param {any} e */
  handleInput = (e) => { handleInputChange(e, this); }

  /** @param {any} e */
  handleSelect = (e) => { handleInputChange(e, this, 'select'); }

  
  GetVendorsForLoggedOnVendorUser = () =>{
    Preloader();

    ajaxCall('Vendor/GetVendorsForLoggedOnVendorUser').then(res => {
      closeAlert();
      let vendors_list = res.data.data.map(i => i.active === true && { ...i, name: 'vendorCode', label: i.name, value: i.vendorCode });
      this.setState({ VendorList: vendors_list });
    })
    .catch(error => {
      errorAction(error);
    })
  }

  checkInvoiceType = (e) => {
    e.preventDefault();

    if(this.state.form.InvoiceType.value === 'POI')
      this.GetServicePOs();
    else
      this.props.routerNavigate('/fi-invoice/' + this.state.form.vendorCode.value);


    this.setState(prevState => ({ form: { ...prevState.form, 'POType': {value: '', label: 'Select PO type...', name: ''} } }));
    this.setState(prevState => ({ form: { ...prevState.form, 'PONumber': {value: '', label: 'Select PO number...', name: ''} } }));
    this.setState({ POList: [] });
  }

  GetServicePOs = () => {
    Preloader();
    ajaxCall('Invoice/GetServicePOs/' + this.state.form.vendorCode.value).then(res => {
      this.setState({ servicePO: res.data.data })
      this.GetMaterialPOs();
    })
    .catch(error => {
      errorAction(error);
    })
  }

  GetMaterialPOs = () => {
    ajaxCall('Invoice/GetMaterialPOs/' + this.state.form.vendorCode.value).then(res => {
      this.setState({ materialPO: res.data.data });
      this.setState({ hasSelectedCompanyAndInvoiceType: true });
      this.setState({ canShowPOform: true });
      this.GetPreviouslyUsedInvoiceReferenceIds();
      this.GetAllSetting();
    })
    .catch(error => {
      errorAction(error);
    })
  }

  GetPreviouslyUsedInvoiceReferenceIds = () => {
    ajaxCall('Invoice/GetPreviouslyUsedInvoiceReferenceIds/' + this.state.form.vendorCode.value).then(res => {
      this.setState({ UsedInvoice: new Set(res.data.data) })
      closeAlert();
    })
    .catch(error => {
      errorAction(error);
    })
  }

  GetAvailabelSES = (e) => {
    let button_name = e.target.viewItemBtn.innerHTML;
    e.target.viewItemBtn.disabled = true;
    e.target.viewItemBtn.innerHTML = Spinner;

    ajaxCall('Invoice/GetAvailableSES/' + this.state.form.vendorCode.value + '/' + this.state.form.PONumber.value + '/0').then(res => {
      e.target.viewItemBtn.disabled = false;
      e.target.viewItemBtn.innerHTML = button_name;
      this.setState({ availableSESs: res.data.data }, this.filterSESTable);
      if(res.data.data.length === 0)
        alertMessage('You do not have any available SES for this PO.');

      else
         closeAlert();
    })
    .catch(error => {
      e.target.viewItemBtn.disabled = false;
      e.target.viewItemBtn.innerHTML = button_name;
      errorAction(error)
    })
  }

  GetAvailabelGR = (e) => {
    let button_name = e.target.viewItemBtn.innerHTML;
    e.target.viewItemBtn.disabled = true;
    e.target.viewItemBtn.innerHTML = Spinner;

    ajaxCall('Invoice/GetAvailableGR/' + this.state.form.vendorCode.value + '/' + this.state.form.PONumber.value + '/0').then(res => {
      e.target.viewItemBtn.disabled = false;
      e.target.viewItemBtn.innerHTML = button_name;
      this.setState({ availableGRs: res.data.data }, this.filterGRTable);

      if(res.data.data.length === 0)
        alertMessage('You do not have any available GRs for this PO.');

      else
        closeAlert();
    })
    .catch(error => {
      e.target.viewItemBtn.disabled = false;
      e.target.viewItemBtn.innerHTML = button_name;
      errorAction(error);
    })
  }




  /**
   * Get BANK details, if:
   * every SES description is not empty
   */
  GetBankDetails = () => {
    let currency = this.state.form.POType.value === "service_po" ? this.state.checkedSESList[0].currency : this.state.checkedGRList[0].currency;
    this.setState({ invoiceCurrency: currency });

    console.log(this.state.checkedSESList,this.state.checkedGRList)

    // validate if aall SES custom description has value;
    if(this.state.form.POType.value === "service_po"){
      const validation = this.state.checkedSESList.every(item => item.vendorCustomDescription);
      if(!validation){
        tinyAlert('error','Custom description is requied')
        return;
      }
    }

    Preloader();
    ajaxCall('Invoice/GetVendorBankDetails/'+this.state.form.vendorCode.value).then(res => {
      this.setState({ bankDetails: res.data.data });

      this.setState({ canShowPOform: false });
      this.setState({ isSESInvoice: false });
      this.setState({ isMPOInvoice: false });
      this.setState({ isBankAccount: true });

      closeAlert();
    })
    .catch(error => {
      errorAction(error)
    })
  }



  /**
   * @param {String} x
   * @param {String} y
   * @return {boolean}
 */
  validateInvoiceCurrencies = (x,y) => {
    let invoiceCurrency = x.replace(/[0-9]/g, '');
    let currencyView = y.replace(/[0-9]/g, '');

    if(invoiceCurrency === currencyView)
      return false;

    else if((invoiceCurrency !== currencyView) && (invoiceCurrency === "NGN"))
      return true;

    else{
      if(currencyView !== "NGN")
          return false;

      return true;
    }



  }

  setBankAccount = (e) => {
    let i = e.target.value
    let bankinfo = this.state.bankDetails[i];

    let currency = this.state.invoiceCurrency.replace(/[0-9]/g, '')
    let selected_currency = bankinfo.currency.replace(/[0-9]/g, '');

    if (selected_currency !== currency)
      alertMessage('You have selected '+ selected_currency +' for an invoice with '+ currency +' currency. <br> Are you sure you want to continue with '+ selected_currency +' currency?');

    this.setState({ checkedBankDetails: bankinfo });
  }

  checkInvoiceReferenceNumber = () => {
    this.setState({ referenceReponseStatus: false, referenceshortMsg: '' });

    /** @type HTMLInputElement */
    let refElement    = document.querySelector("#referenceNumber");
    var inputValue = refElement.value;
    var inputValueWithoutSpaces = inputValue.replace(/\s/g, "");
    refElement.value = inputValueWithoutSpaces;
    
    if (inputValueWithoutSpaces.length > 1) {
      if(this.state.UsedInvoice.has(refElement.value.toLocaleUpperCase())){
        this.setState({
          referenceReponseStatus: false,
          referenceshortMsg: 'Already Used!'
        });
      }
      else{
        this.setState({
          referenceReponseStatus: true,
          referenceshortMsg: 'Available!'
        });

        this.setState(prevState => ({ form: { ...prevState.form, referenceNumber: inputValueWithoutSpaces } }));
      }
    }
    else {
      this.setState({
        referenceReponseStatus: false,
        referenceshortMsg: 'Required'
      });
    }
  }

  

  // checkbox to select SES to invoice
  handleCheckedSES = (e, SES) => {
    let checked = e.target.checked;
    this.setState({
      currentPageSESs: this.state.currentPageSESs.map(s => s.sesNo === SES.sesNo && s.sesItemNo === SES.sesItemNo ? { ...s, isChecked: checked, vendorCustomDescription: '' } : s),
      filteredSESs: this.state.filteredSESs.map(s => s.sesNo === SES.sesNo && s.sesItemNo === SES.sesItemNo ? { ...s, isChecked: checked, vendorCustomDescription: '' } : s),
      availableSESs: this.state.availableSESs.map(s => s.sesNo === SES.sesNo && s.sesItemNo === SES.sesItemNo ? { ...s, isChecked: checked, vendorCustomDescription: '' } : s)
    }, this.setSESCheckBox)
  }
  handleCheckedAllSES = (e) => {
    let checked = e.target.checked;

    this.setState({
      currentPageSESs: this.state.currentPageSESs.map(g => ({ ...g, isChecked: checked, vendorCustomDescription: '' })),
      filteredSESs: this.state.filteredSESs.map(g => ({ ...g, isChecked: checked, vendorCustomDescription: '' })),
      availableSESs: this.state.filteredSESs.map(g => ({ ...g, isChecked: checked, vendorCustomDescription: '' }))
    }, this.checkALLFilteredSESs )
  }



  // checkbox to select GR to invoice
  handleCheckedGR = (e, GR) => {
    let checked = e.target.checked
    this.setState({
      currentPageGRs: this.state.currentPageGRs.map(g => g.grNo === GR.grNo && g.grItemNo === GR.grItemNo ? { ...g, isChecked: checked, vendorCustomDescription: '' } : g),
      filteredGRs: this.state.filteredGRs.map(g => g.grNo === GR.grNo && g.grItemNo === GR.grItemNo ? { ...g, isChecked: checked, vendorCustomDescription: '' } : g),
      availableGRs: this.state.availableGRs.map(g => g.grNo === GR.grNo && g.grItemNo === GR.grItemNo ? { ...g, isChecked: checked, vendorCustomDescription: '' } : g)
    }, this.setGRCheckBox)
  }
  handleCheckedAllGR = (e) => {
    let checked = e.target.checked;

    this.setState({
      currentPageGRs: this.state.currentPageGRs.map(g => ({ ...g, isChecked: checked, vendorCustomDescription: '' })),
      filteredGRs: this.state.filteredGRs.map(g => ({ ...g, isChecked: checked, vendorCustomDescription: '' })),
      availableGRs: this.state.filteredGRs.map(g => ({ ...g, isChecked: checked, vendorCustomDescription: '' }))
    }, this.checkALLFilteredGRs )
  }




  checkALLFilteredGRs = () =>{
    this.setState({
      availableGRs: this.state.availableGRs.map(g => ({ ...g, isChecked: !this.state.filteredGRs.find(r => r.grItemNo === g.grItemNo && r.grNo === g.grNo && r.isChecked) ? false : this.state.filteredGRs.find(r => r.grItemNo === g.grItemNo && r.grNo === g.grNo).isChecked}))
    }, this.setGRCheckBox)
  }

  checkALLFilteredSESs = () =>{
    this.setState({
      availableSESs: this.state.availableSESs.map(g => ({ ...g, isChecked: !this.state.filteredSESs.find(r => r.sesNo === g.sesNo && r.isChecked) ? false : this.state.filteredSESs.find(r => r.sesNo === g.sesNo).isChecked}))
    }, this.setSESCheckBox)
  }

  gobackToPage = (e) => {
    let page = e.target.name;

    if (page === 'page_1') {
      this.setState({ canShowPOform: false });
      this.setState({ hasSelectedCompanyAndInvoiceType: false });
    }

    if (page === 'page_2') {
      this.setState({ canShowPOform: true });
      this.setState({ isSESInvoice: false });
      this.setState({ isMPOInvoice: false });
      this.setState({ hasSelectedCompanyAndInvoiceType: true });
    }

    if (page === 'page_3') {
      this.setState({ canShowPOform: false });
      this.setState({ isBankAccount: false });

      if (this.state.form.POType.value === "material_po") {
        this.setState({ isSESInvoice: false });
        this.setState({ isMPOInvoice: true });
      }

      else if (this.state.form.POType.value === "service_po") {
        this.setState({ isSESInvoice: true });
        this.setState({ isMPOInvoice: false });
      }
    }

    if (page === 'page_4') {
      this.setState({ isBankAccount: true });
      this.setState({ canShowPreview: false });
    }
  }

  changePOList = (e) => {
    this.setState(prevState => ({ form: { ...prevState.form, 'PONumber': {value: '', label: 'Select PO number...', name: ''} } }));

    this.setState(prevState => ({ form: { ...prevState.form, [e.name]: e } }), function () {
      let po_type = this.state.form.POType.value
      let po_list = [];
      if (po_type === "material_po") {
        for (let i = 0; i < this.state.materialPO.length; i++) {
          if (this.state.materialPO.length > 0) {
            po_list.push({
              name: "PONumber",
              label: this.state.materialPO[i],
              value: this.state.materialPO[i]
            })
          }
        }
        this.setState({ POList: po_list });
      }
      else if (po_type === "service_po") {
        for (let i = 0; i < this.state.servicePO.length; i++) {
          if (this.state.servicePO.length > 0) {
            po_list.push({
              name: "PONumber",
              label: this.state.servicePO[i],
              value: this.state.servicePO[i]
            })
          }
        }
        this.setState({ POList: po_list });
      }
    });
  }

  handleChangePOnumber = (e) => {
    Preloader();
    e.preventDefault();
    let po_type = this.state.form.POType.value;

    if (po_type === "material_po") {
      this.GetAvailabelGR(e);
    }
    else if (po_type === "service_po") {
      this.GetAvailabelSES(e);
    }
  }

  handleCheckedSESTAX = (e, SES) => {
    let checked = e.target.checked
    this.setState({
      currentPageSESs: this.state.currentPageSESs.map(s => s.sesNo === SES.sesNo && s.sesItemNo === SES.sesItemNo ? { ...s, isTax: checked } : s),
      filteredSESs: this.state.filteredSESs.map(s => s.sesNo === SES.sesNo && s.sesItemNo === SES.sesItemNo ? { ...s, isTax: checked } : s),
      availableSESs: this.state.availableSESs.map(s => s.sesNo === SES.sesNo && s.sesItemNo === SES.sesItemNo ? { ...s, isTax: checked } : s),
      checkedSESList: this.state.checkedSESList.map(s => s.sesNo === SES.sesNo && s.sesItemNo === SES.sesItemNo ? { ...s, isTax: checked } : s)
    })
  }

  addSESToInvoice = () => {
    this.setState({ checkedSESList: this.state.availableSESs.filter(g => g.isChecked) });
    this.setState({ canShowPOform: false });
    this.setState({ isMPOInvoice: false });
    this.setState({ isSESInvoice: true });
  }

  filterSESTable = (e) => {
    let result
    if (e != null) {
      let search = e.target.value.toLowerCase();
      result = this.state.availableSESs.filter((data) => data.sesItemDescription.toLowerCase().includes(search) || data.sesNo.includes(search) || data.sesAmount.toString().includes(search));
    }
    else
      result = this.state.availableSESs
    this.setState({ filteredSESs: result.map((r, i) => ({ ...r, index: i + 1 })) }, this.updateTablePagination);
  }


  handleChangeSESAmount = (e, SES) => {
    let sesData = this.state.checkedSESList;
    let list = [];
    let amt = Number(e.target.value);

    for (let i = 0; i < sesData.length; i++) {
      if (sesData[i].sesNo === SES.sesNo && sesData[i].sesItemNo === SES.sesItemNo) {
        sesData[i].partAmount = amt;

        if (sesData[i].partAmount > sesData[i].sesAmount)
          sesData[i].partAmount = sesData[i].sesAmount;

        list.push(sesData[i]);
      }
      else
        list.push(sesData[i]);
    }

    this.setState({ checkedSESList: list });
  }
  handleChangeSESDescription = (e, SES) => {
    let sesData = this.state.checkedSESList;
    let list = [];
    let description = e.target.value;

    for (let i = 0; i < sesData.length; i++) {
      if (sesData[i].sesNo === SES.sesNo && sesData[i].sesItemNo === SES.sesItemNo) {
        sesData[i].vendorCustomDescription = description;

        list.push(sesData[i]);
      }
      else
        list.push(sesData[i]);
    }

    this.setState({ checkedSESList: list });
  }



  filterGRTable = (e) => {
    let result
    if (e != null) {
      let search = e.target.value.toLowerCase();
      result = this.state.availableGRs.filter((data) => data.materialDescription.toLowerCase().includes(search) || data.grNo.includes(search) || data.grAmount.toString().includes(search));
    }
    else
      result = this.state.availableGRs

    this.setState({ filteredGRs: result.map((r, i) => ({ ...r, index: i + 1 })) }, this.updateTablePagination);
  }

  updateTablePagination = () => {
    let recordCount = this.state.form.POType.value === "material_po" ? this.state.filteredGRs.length : this.state.filteredSESs.length
    let numOfPages = Math.ceil(recordCount / this.state.numRecordsPerPage)
    let currentPage = this.state.currentPage
    if (currentPage < 1) currentPage = 1;
    if (currentPage > numOfPages) currentPage = numOfPages;
    this.setState({ currentPage: currentPage, numOfPages: numOfPages }, this.setRecordsForCurrentPage)
  }

  setRecordsForCurrentPage = () => {
    const offset = (this.state.currentPage - 1) * this.state.numRecordsPerPage;
    if (this.state.form.POType.value === "material_po")
      this.setState({ currentPageGRs: this.state.filteredGRs.slice(offset, offset + this.state.numRecordsPerPage) }, this.setGRCheckBox);
    else
      this.setState({ currentPageSESs: this.state.filteredSESs.slice(offset, offset + this.state.numRecordsPerPage) });
  }

  setNumRecordsPerPage = (e) => {
    this.setState({ numRecordsPerPage: Number(e.target.value) }, this.updateTablePagination);
  }

  nextPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) + 1 }, this.setRecordsForCurrentPage);
  }

  previousPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) - 1 }, this.setRecordsForCurrentPage);
  }

  setGRCheckBox = () =>{
    if(this.state.filteredGRs.every(v => v.isChecked === true) && this.state.currentPageGRs.every(v => v.isChecked === true))
      this.setState({ isCheckedAllGRs: true });
    else
      this.setState({ isCheckedAllGRs: false });
  }

  setSESCheckBox = () =>{
    if(this.state.filteredSESs.every(v => v.isChecked === true) && this.state.currentPageSESs.every(v => v.isChecked === true))
      this.setState({ isCheckedAllSESs: true });
    else
      this.setState({ isCheckedAllSESs: false });
  }

  addMPOToInvoice = () => {
    this.setState({ checkedGRList: this.state.availableGRs.filter(g => g.isChecked) });
    this.setState({ canShowPOform: false });
    this.setState({ isSESInvoice: false });
    this.setState({ isMPOInvoice: true });
  }



  // GR Handlers for VAT, AMOUNT, QTY & DESCRIPTION
  handleCheckedGRTAX = (e, GR) => {
    let checked = e.target.checked
    this.setState({
      currentPageGRs: this.state.currentPageGRs.map(g => g.grNo === GR.grNo && g.grItemNo === GR.grItemNo ? { ...g, isTax: checked } : g),
      filteredGRs: this.state.filteredGRs.map(g => g.grNo === GR.grNo && g.grItemNo === GR.grItemNo ? { ...g, isTax: checked } : g),
      availableGRs: this.state.availableGRs.map(g => g.grNo === GR.grNo && g.grItemNo === GR.grItemNo ? { ...g, isTax: checked } : g),
      checkedGRList: this.state.checkedGRList.map(g => g.grNo === GR.grNo && g.grItemNo === GR.grItemNo ? { ...g, isTax: checked } : g)
    })
  }
  handleChangeGRAmount = (e, GR) => {
    let grData = this.state.checkedGRList;
    let list = [];
    let amt = Number(e.target.value);

    for (let i = 0; i < grData.length; i++) {
      if (grData[i].grNo === GR.grNo && grData[i].grItemNo === GR.grItemNo) {
        grData[i].partAmount = Number(amt.toFixed(2));
        grData[i].partQty = Number(((amt * grData[i].grQuantity) / grData[i].grAmount).toFixed(2))

        if (grData[i].partAmount > grData[i].grAmount) {
          grData[i].partAmount = grData[i].grAmount;
          grData[i].partQty = grData[i].grQuantity;
        }

        list.push(grData[i]);
      }
      else
        list.push(grData[i]);
    }
    this.setState({ checkedGRList: list });
  }
  handleChangeGRQty = (e, GR) => {
    let grData = this.state.checkedGRList;
    let list = [];
    let qty = Number(e.target.value);

    for (let i = 0; i < grData.length; i++) {
      if (grData[i].grNo === GR.grNo && grData[i].grItemNo === GR.grItemNo) {
        grData[i].partQty = Number(qty.toFixed(2));
        grData[i].partAmount = Number(((qty * grData[i].grAmount) / grData[i].grQuantity).toFixed(2))

        if (grData[i].partQty > grData[i].grQuantity) {
          grData[i].partQty = grData[i].grQuantity;
          grData[i].partAmount = grData[i].grAmount;
        }

        list.push(grData[i]);
      }
      else
        list.push(grData[i]);
    }
    this.setState({ checkedGRList: list });
  }
  handleChangeGRDescription = (e, GR) => {
    let grData = this.state.checkedGRList;
    let list = [];
    let description = e.target.value;

    for (let i = 0; i < grData.length; i++) {
      if (grData[i].grNo === GR.grNo && grData[i].grItemNo === GR.grItemNo) {
        grData[i].vendorCustomDescription = description;

        list.push(grData[i]);
      }
      else
        list.push(grData[i]);
    }
    this.setState({ checkedGRList: list });
  }






  showPreview = () => {
    this.setState({ isSESInvoice: false });
    this.setState({ isMPOInvoice: false });
    this.setState({ canShowPOform: false });
    this.setState({ isBankAccount: false });
    this.setState({ canShowPreview: true });
  }

  handleSaveInvoice = (e) => {
    confirmPrompt(`Do you want to ${e.target.name === 'saveSign' ? 'generate final invoice' : 'save invoice to draft'} ?`).then((result) => {
      if (result.isConfirmed) {
        let cansign = e.target.name;
        let po_type = this.state.form.POType.value

        if (po_type === "material_po") {
          this.CreateMateriaInvoice(cansign);
        }
        else if (po_type === "service_po") {
          this.CreateServiceInvoice(cansign);
        }
      }
    })
  }



  /**
   * create service invoice
   * determine with cansign if:
   * it to save to draft or save and submit
   */
  CreateServiceInvoice = (cansign) => {
    Preloader();

    let checkedSESList = this.state.checkedSESList;
    let selectedSESs = [];

    for (let i = 0; i < checkedSESList.length; i++) {
      selectedSESs.push({ SesNo: checkedSESList[i].sesNo, SESAmount: checkedSESList[i].partAmount ? checkedSESList[i].partAmount : checkedSESList[i].sesAmount, SESItemNo: checkedSESList[i].sesItemNo, VATTaxable: checkedSESList[i].isTax === undefined ? true : checkedSESList[i].isTax === true ? true : false, VendorSESItemDescription:checkedSESList[i].vendorCustomDescription });
    }

    let data = {
      invoiceReferenceId: this.state.form.referenceNumber,
      poNo: this.state.form.PONumber.value,
      bankName: this.state.checkedBankDetails.bankName,
      bankAccountNumber: this.state.checkedBankDetails.accountNumber,
      selectedSESs: selectedSESs,
      vendorCode:this.state.form.vendorCode.value
    };

    ajaxCall('Invoice/CreateServiceInvoice','json','post', data).then(res => {
      if (cansign === 'saveSign') {
        tinyAlert('success', 'Invoice ' +this.state.form.referenceNumber +' is created!');
        this.props.routerNavigate("/sign-invoice/"+ res.data.data.id)
      }
      else {
        tinyAlert('success', 'Invoice "' +this.state.form.referenceNumber +'" is successfully saved to draft');
        this.props.routerNavigate("/history");
      }
    })
    .catch(error => {
      errorAction(error)
    })
  }
  /**
   * create material invoice
   * determine with cansign if:
   * it to save to draft or save and submit
   */
  CreateMateriaInvoice = (cansign) => {
    Preloader();

    let checkedGRList = this.state.checkedGRList;
    let selectedGRLists = [];

    for (let i = 0; i < checkedGRList.length; i++) {
      selectedGRLists.push({ GrNo: checkedGRList[i].grNo, GRItemNo: checkedGRList[i].grItemNo, GRAmount: checkedGRList[i].partAmount ? checkedGRList[i].partAmount : checkedGRList[i].grAmount, VATTaxable: checkedGRList[i].isTax === undefined ? true : checkedGRList[i].isTax === true ? true : false, vendorMaterialDescription:checkedGRList[i].vendorCustomDescription });
    }

    let data = {
      invoiceReferenceId: this.state.form.referenceNumber,
      poNo: this.state.form.PONumber.value,
      bankName: this.state.checkedBankDetails.bankName,
      bankAccountNumber: this.state.checkedBankDetails.accountNumber,
      selectedGRs: selectedGRLists,
      vendorCode:this.state.form.vendorCode.value
    };

    ajaxCall('Invoice/CreateMaterialInvoice', 'json', 'post', data).then(res => {
      if (cansign === 'saveSign') {
        tinyAlert('success', 'Invoice ' +this.state.form.referenceNumber +' is created!');
        this.props.routerNavigate("/sign-invoice/"+ res.data.data.id)
      }
      else {
        tinyAlert('success', 'Invoice "' +this.state.form.referenceNumber +'" is successfully saved to draft');
        this.props.routerNavigate("/history");
      }
    })
    .catch(error => {
      errorAction(error);
    })
  }





  GetAllSetting= () =>{
    ajaxCall('Setting').then(res => {
      this.setState({ Setting: res.data.data });
    })
    .catch(error => {
      errorAction(error);
    })
  }


  render() {
    return (
      <div className='mainApp'>
        <div className='container-fluid'>
          <div className='row'>
            <SideNav />

            <div className='main-content-wrap'>
              <TopNav />
              <PageTitle pagedata={{ page: `New Invoice ${this.state.form.vendorCode.value !== '' ? ' - ' + this.state.form.vendorCode.label + ' ('+this.state.form.vendorCode.value +')' : ''}`, breadcrumb: 'Create new invoice' }} />

              <div className='container-fluid mb-5'>
                <div className='row'>

                  <div className='col-12 px-4 mb-5 sm-px-4'>
                    {/* [1 of 4] select invoice type */}
                    {!this.state.hasSelectedCompanyAndInvoiceType &&
                      <div className="mt-3 shadow-sm mt-sm">
                        <h6 className='py-3 px-4 mb-0 lead-txt'>
                          Create New Invoice
                          <span className='mt-1 font-12 bold' style={{ float: 'right' }}>1 of 4 </span>
                        </h6>
                        <hr className='m-0' />

                        <form className="py-3 px-4 bg-white" autoComplete="off" method="post">
                          <input type="hidden" autoComplete="off" />

                          <div className='row mt-2'>
                            <div className='col-sm-2 col-12'>
                              <label className='bolder font-14'>Select your company:</label>
                            </div>
                            <div className='col-sm-10 col-12'>
                              <div className="form-group mb-0">
                                <Select name='vendorCode'
                                  value={this.state.form.vendorCode}
                                  onChange={this.handleSelect}
                                  isSearchable={false}
                                  options={this.state.VendorList}
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row mt-3 sm-mt-1'>
                            <div className='col-sm-2 col-12'>
                              <label className='font-14 bolder'>Select invoice type:</label>
                            </div>
                            <div className='col-sm-10 col-12'>
                              <div className="form-group mb-0">
                                <Select name='InvoiceType'
                                  value={this.state.form.InvoiceType}
                                  onChange={this.handleSelect}
                                  isSearchable={false}
                                  options={this.state.InvoiceType}
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row mb-5'>
                            <div className='col-9'></div>
                            <div className='col-sm-3'>
                              <div className="form-group mt-3 mb-5">
                                <button type='button' id="nextToPO"
                                  onClick={this.checkInvoiceType} disabled={this.state.form.InvoiceType.value === '' || this.state.form.vendorCode.value === ''}
                                  className='btn btn-primary font-12 py-2 border-radius-sm form-control'>
                                    Next <NextIcon />
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    }
                    {/* =============> !END SCREEN 1 of 4 */}



                    {/* [2 of 4] Display SES & GR */}
                    {this.state.hasSelectedCompanyAndInvoiceType && this.state.canShowPOform &&
                      <div>

                        {/* FORM TO SELECT PO TYPE & NOS */}
                        <div className="mt-4 shadow-sm bg-white mt-sm">
                          <h6 className='py-3 px-4 mb-0 lead-txt'>
                            Purchase Order Invoice
                            <span className='font-12 mt-1 bold' style={{ float: 'right' }}>Page 2 of 4</span>
                          </h6>
                          <hr className='m-0' />

                          <div className='py-2 px-4 '>
                            <form className="" autoComplete="off" method="post" onSubmit={this.handleChangePOnumber}>
                              <input type="hidden" autoComplete="off" />

                              <div className='row mt-3  sm-mt-1'>
                                <div className='col-sm-2 col-12'>
                                  <label className='font-12 bold'>Vendor's Company</label>
                                </div>
                                <div className='col-sm-10 col-12'>
                                  <div className="form-group mb-0">
                                    <input type="text" value={this.state.form.vendorCode.label} className="form-control input-font bold" disabled />
                                  </div>
                                </div>
                              </div>

                              <div className='row mt-3 sm-mt-1'>
                                <div className='col-sm-2 col-12'>
                                  <label className='font-12 bold'>PO Type:</label>
                                </div>
                                <div className='col-sm-10 col-12'>
                                  <div className="form-group mb-0">
                                    <Select name='POType'
                                      value={this.state.form.POType}
                                      onChange={this.changePOList}
                                      isSearchable={false}
                                      options={this.state.POTypeList}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='row mt-3 sm-mt-1'>
                                <div className='col-sm-2 col-12'>
                                  <label className='font-12 bold'>PO Number:</label>
                                </div>
                                <div className='col-sm-10 col-12'>                                  
                                  <div className='info-alert font-12 p-1 mb-1'>
                                    If your PO, GR or JPC is not found, please reach out to your Decentralised buyer/Contract Engineer or Technical Rep.
                                  </div>
                                  <div className="form-group mb-0" id='ponumber'>
                                    <Select name='PONumber'
                                      value={this.state.form.PONumber}
                                      onChange={this.handleSelect}
                                      isSearchable={false}
                                      options={this.state.POList}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='row mb-3'>
                                <div className='col-sm-6'></div>
                                <div className='col-sm-3 col-6'>
                                  <div className="form-group mt-3">
                                    <button type='button' onClick={this.gobackToPage} name="page_1" className='btn btn-danger font-12 border-radius-sm form-control py-2'><PrevIcon /> Back</button>
                                  </div>
                                </div>

                                <div className='col-sm-3 col-6'>
                                  <div className="form-group mt-3">
                                    <button type='submit' title="Click to view PO Items" id="viewItemBtn" disabled={this.state.form.PONumber.value === ''} className='btn btn-primary font-12 border-radius-sm form-control py-2'>
                                      View Items
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>


                        {/* SES LIST TABLE */}
                        <div className="mt- shadow-sm bg-white" id='poTable'>
                          <div className='px-4'>

                            {this.state.form.POType.value !== '' &&
                              this.state.form.POType.value === "service_po" && this.state.availableSESs.length > 0 && !this.state.isSESInvoice &&
                                <>

                                  <div className='mb-2 mt-3' style={{ position: 'relative', paddingTop: '30px' }}>
                                    <input type="text" className="form-control input-font bold" placeholder='Search with SES number, description or amount' onInput={this.filterSESTable} />
                                    <i className='fa fa-search' style={{ position: 'absolute', right: '20px', top: '40px', color: '#939597' }}></i>
                                  </div>
                                  <label className="alert alert-danger font-14 mt-1 mb-1 p-1 col-12 text-center rounded-0 bolder">
                                    Only JPC / SES that has been received by mail should be selected. Don't invoice for a SES you haven't received by email, even if it shows up in VIMP
                                  </label>
                                  <div className="table-responsive" id='poTableArea'>
                                    <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                                      <thead className='text-white' style={{ background: '#0556de' }}>
                                        <tr>
                                          <th style={{ width: '30px' }}>
                                            <input type="checkbox" checked={this.state.isCheckedAllSESs ? true : false} onChange={(e) => this.handleCheckedAllSES(e)} />
                                          </th>
                                          <th>#</th>
                                          <th>SES Number</th>
                                          <th>Item Number</th>
                                          <th>PO Item Description</th>
                                          <th style={{ textAlign: "right" }} className="px-5">Amount</th>
                                          <th>Currency</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {
                                          this.state.currentPageSESs.map(object => {
                                            return( 
                                            <tr key={object.index}>
                                              <td className='text-center'><input type="checkbox" checked={object.isChecked === undefined ? false : object.isChecked === true ? true : false} onChange={(e) => this.handleCheckedSES(e, object)} /></td>
                                              <td>{object.index}</td>
                                              <td>{object.sesNo}</td>
                                              <td>{object.sesItemNo}</td>
                                              <td>{object.sesItemDescription}</td>
                                              <td className="px-5" style={{ textAlign: "right" }}>{formatAmount(object.sesAmount)}</td>
                                              <td>{object.currency}</td>
                                            </tr>
                                            )
                                          })
                                        }
                                      </tbody>
                                    </table>
                                  </div>

                                  { this.state.filteredSESs.length === 0 && <p className='font-12 text-center p-3'>Oops! No record available...</p> }


                                  <div className="row mb-2 mt-2">
                                    <div className='col-sm-9'></div>
                                    <div className='col-sm-1 col-3'>
                                      <select className='font-12 form-select border-radius-sm' value={this.state.numRecordsPerPage} onChange={this.setNumRecordsPerPage}>
                                        <option value='3'>3</option>
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='25'>25</option>
                                        <option value='100'>100</option>
                                        <option value='250'>250</option>
                                      </select>
                                    </div>
                                    <div className='col-sm-1 col-3 p-0'>
                                      <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === 1} onClick={this.previousPage}><PrevIcon /> Prev</button>
                                    </div>
                                    <div className='col-sm-1 col-3 p-0'>
                                      <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === this.state.numOfPages} onClick={this.nextPage}>Next <NextIcon /></button>
                                    </div>
                                  </div>


                                  <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='row mt-5 sm-mt-1'>
                                          <div className='col-12 col-sm-12'>
                                            <div className='info-alert font-12 p-1 mt-1'>Invoice number cannot be more than sixteen (16) characters.</div>
                                            <div className="form-group mb-0 mt-1">
                                              <input type="text" name='referenceNumber' value={this.state.form.referenceNumber} onChange={this.handleInput} id='referenceNumber' onKeyUp={this.checkInvoiceReferenceNumber} onPaste={this.checkInvoiceReferenceNumber} maxLength={16} minLength={2} className="form-control input-font" autoComplete="off" placeholder="Enter Invoice Reference" required />

                                              <i className={`fa input-icon ${this.state.referenceReponseStatus ? 'fa-check text-success' : 'fa-times text-danger'}`} style={{ top: '1px' }}></i>

                                              <label className={`input-feedback font-12 animated fadeIn bold ${this.state.referenceReponseStatus === false ? ' text-danger' : ' text-success'}`}>{this.state.referenceshortMsg}</label>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className='col-sm-6'></div>
                                    <div className='col-sm-3 col-6'>
                                      <div className="form-group mt-2">
                                        <button type='button' onClick={this.gobackToPage} name="page_1" className='btn btn-danger font-12 border-radius-sm form-control'><PrevIcon /> Back</button>
                                      </div>
                                    </div>

                                    <div className='col-sm-3 col-6'>
                                      <div className="form-group mt-2 mb-5">
                                        <button type='button' disabled={this.state.availableSESs.find(g => g.isChecked) == null || !this.state.referenceReponseStatus} title="Click to preview selected Items" onClick={this.addSESToInvoice} className='btn btn-primary font-12 border-radius-sm form-control'>Add to invoice <NextIcon /></button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                            }
                            {/* Display for SES table */}


                            {/*NNB [2B] display available MPO record */}
                            {this.state.form.POType.value !== '' &&
                              this.state.form.POType.value === "material_po" && this.state.availableGRs.length > 0 && !this.state.isMPOInvoice &&
                                <>
                                  <div className='mb-2 mt-3' style={{ position: 'relative', paddingTop: '30px' }}>
                                    <input type="text" className="form-control input-font bold" placeholder='Search with GR number, description or amount' onInput={this.filterGRTable} />
                                    <i className='fa fa-search' style={{ position: 'absolute', right: '20px', top: '40px', color: '#939597' }}></i>
                                  </div>

                                  {/* <label className="alert alert-danger font-14 mt-1 mb-1 p-1 col-12 text-center rounded-0 bolder">
                                    Only JPC/GR that has been received by mail should be selected. Don't invoice for a JPC you haven't received by email, even if it shows up in VIMP
                                  </label> */}

                                  <div className="table-responsive" id='poTableArea'>
                                    <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                                      <thead className='text-white'>
                                        <tr>
                                          <th style={{ width: '30px' }}>
                                            <input type="checkbox" checked={this.state.isCheckedAllGRs ? true : false} onChange={(e) => this.handleCheckedAllGR(e)} />
                                          </th>
                                          <th>#</th>
                                          <th>GR Number</th>
                                          <th>Item Number</th>
                                          <th>Description</th>
                                          <th className='text-end'>Quantity</th>
                                          <th className='text-end'>Amount</th>
                                          <th>Currency</th>
                                          {/* <th>Apply VAT?</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          this.state.currentPageGRs.map(object => { // changed from filteredGRs
                                            return <tr key={object.index}>
                                              <td className='text-center'>
                                                <input type="checkbox" checked={object.isChecked === undefined ? false : object.isChecked === true ? true : false} onChange={(e) => this.handleCheckedGR(e, object)} />
                                              </td>

                                              <td>{object.index}</td>
                                              <td>{object.grNo}</td>
                                              <td>{object.grItemNo}</td>
                                              <td>{object.materialDescription}</td>
                                              <td className='text-end'>{formatAmount(object.grQuantity)}</td>
                                              <td className='text-end'>{formatAmount(object.grAmount)}</td>
                                              <td>{object.currency}</td>
                                            </tr>
                                          })
                                        }
                                      </tbody>
                                    </table>
                                  </div>

                                  {this.state.filteredGRs.length === 0 ? <p className='font-12 text-center p-3'>Oops! No record available...</p> : <></>}


                                  <div className="row mb-2 mt-2">
                                    <div className='col-sm-9'></div>
                                    <div className='col-sm-1 col-3'>
                                      <select className='font-12 form-select border-radius-sm' value={this.state.numRecordsPerPage} onChange={this.setNumRecordsPerPage}>
                                        <option value='3'>3</option>
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='25'>25</option>
                                        <option value='100'>100</option>
                                        <option value='250'>250</option>
                                      </select>
                                    </div>
                                    <div className='col-sm-1 col-3 p-0'>
                                      <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === 1} onClick={this.previousPage}><PrevIcon /> Prev</button>
                                    </div>
                                    <div className='col-sm-1 col-3 p-0'>
                                      <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === this.state.numOfPages} onClick={this.nextPage}>Next <NextIcon /></button>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='row mt-3 sm-mt-1'>
                                          <div className='col-12 col-sm-12'>
                                            <div className='info-alert font-12 p-1 mt-1'>Invoice number cannot be more than sixteen (16) characters.</div>
                                            <div className="form-group mb-0 mt-1">
                                              <input type="text" name='referenceNumber' value={this.state.form.referenceNumber} onChange={this.handleInput} id='referenceNumber' onKeyUp={this.checkInvoiceReferenceNumber} maxLength={16} minLength={2} className="form-control input-font" autoComplete="off" placeholder="Enter Invoice Reference" required />
                                              <i className={`fa input-icon ${this.state.referenceReponseStatus ? 'fa-check text-success' : 'fa-times text-danger'}`} style={{ top: '1px' }}></i>
                                              <label className={`input-feedback font-12 animated fadeIn bold ${this.state.referenceReponseStatus === false ? ' text-danger' : ' text-success'}`}>{this.state.referenceshortMsg}</label>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className='col-sm-6'></div>
                                    <div className='col-sm-3 col-6'>
                                      <div className="form-group mt-3">
                                        <button type='button' onClick={this.gobackToPage} name="page_1" className='btn btn-danger font-12 border-radius-sm form-control'><PrevIcon /> Back</button>
                                      </div>
                                    </div>
                                    <div className='col-sm-3 col-6'>
                                      <div className="form-group mt-3 mb-5">
                                        <button type='button' disabled={this.state.availableGRs.find(g => g.isChecked) == null || !this.state.referenceReponseStatus} onClick={this.addMPOToInvoice} className='btn btn-primary font-12 border-radius-sm form-control'>Add to invoice</button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                            }
                            {/* Display for GR table */}
                          </div>
                        </div>
                      </div>
                    }
                    {/* =============> !END SCREEN 2 of 4 */}


                    {/* [3A] [3 of 4] Display selected SES added to invoice */}
                    {this.state.hasSelectedCompanyAndInvoiceType && this.state.isSESInvoice &&
                      <>
                        <div className="mt-3 shadow-sm bg-white">
                          <h6 className='py-3 px-4 mb-0 lead-txt'>
                            SES Invoice Details
                            <span className='font-12 mt-1' style={{ float: 'right' }}>Page 3 of 4</span>
                          </h6>
                          <hr className='m-0' />

                          <div className='px-4'>
                            {
                              this.state.form.vendorCode.partInvoicingEnabled && <div className='info-alert'>Kindly note that you have part {this.state.form.POType.label} payment enabled.</div>
                            }
                            <form className="" autoComplete="off" method="post">
                              {this.state.checkedSESList.map((object, i) => {
                                return <div className="invoice-record-row" key={i}>
                                  <div>
                                    <div className='row mb-3'>
                                      <div className='col-sm-2 col-6'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>SES Number</label>
                                          <div className="bold form-control input-font">{object.sesNo}</div>
                                        </div>
                                      </div>
                                      <div className='col-sm-1 col-6'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>Item</label>
                                          <div className="bold form-control input-font">{object.sesItemNo}</div>
                                        </div>
                                      </div>
                                      <div className='col-sm-5 col-12'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>Description</label>
                                          <div className="bold form-control input-font">{object.sesItemDescription}</div>
                                        </div>
                                      </div>
                                      <div className='col-sm-3 col-10'>
                                        {!this.state.form.vendorCode.partInvoicingEnabled ?
                                          <div className="form-group mb-0">
                                            <label className='font-12 input-overlay'>SES Amount</label>
                                            <label className='input-currency bold'>{object.currency}</label>
                                            <div style={{ paddingLeft: '65px' }} className="bold form-control input-font">{formatAmount(object.sesAmount)}</div>
                                          </div>
                                          :
                                          <div className="form-group mb-0">
                                            <label className='font-12 input-overlay'>SES Amount: {formatAmount(object.sesAmount)}</label>
                                            <label className='input-currency bold'>{object.currency}</label>
                                            <input type="number" style={{ paddingLeft: '65px', border: '1px solid #0556de' }} className="bold form-control input-font" value={object.partAmount === '' ? '' : object.partAmount === undefined ? object.sesAmount : object.partAmount} onChange={(e) => this.handleChangeSESAmount(e, object)} min="1" max={object.sesAmount} />
                                          </div>
                                        }
                                      </div>
                                      <div className='col-sm-1 col-2'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>Vat</label>

                                          <div  style={{border: '1px solid #0556de', height:'40px' }}className="bold form-control input-font">
                                            <div className="form-check form-switch d-none d-sm-block" style={{paddingLeft:'30px'}}>
                                              <input className="form-check-input" type="checkbox" checked={object.isTax === undefined ? true : object.isTax === true ? true : false} onChange={(e) => this.handleCheckedSESTAX(e, object)} />
                                            </div>

                                            <input className="form-check-input d-block d-sm-none" type="checkbox" checked={object.isTax === undefined ? true : object.isTax === true ? true : false} onChange={(e) => this.handleCheckedSESTAX(e, object)} />
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-12'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'><i className="fa fa-asterisk text-danger" title="Required Field"></i> Custom Description</label>                                          
                                          <input type="text" placeholder='Enter custom description' style={{ border: `${!object.vendorCustomDescription ? '1px solid red' : '1px solid #ced4da'}` }} className="bold form-control input-font" value={object.vendorCustomDescription} onChange={(e) => this.handleChangeSESDescription(e, object)} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr className='mt-0 mb-0' />
                                </div>
                              })
                              }
                            </form>

                            <div className='row'>
                              <div className='col-sm-6'></div>
                              <div className='col-sm-3 col-6'>
                                <div className="form-group mt-3 mb-5">
                                  <button type='button' onClick={this.gobackToPage} name="page_2" className='btn btn-danger font-12 border-radius-sm form-control'><PrevIcon /> Back</button>
                                </div>
                              </div>
                              <div className='col-sm-3 col-6'>
                                <div className="form-group mt-3 mb-5">
                                  <button type='button' onClick={this.GetBankDetails} className='btn btn-primary font-12 border-radius-sm form-control'>Next  <NextIcon /></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    {/* =============> !END SCREEN 3A of 4 */}


                    {/* [3B] Display selected MPO added to invoice */}
                    {this.state.hasSelectedCompanyAndInvoiceType && this.state.isMPOInvoice &&
                      <>
                        <div className="mt-3 shadow-sm bg-white">
                          <h6 className='py-3 px-4 mb-0 lead-txt'>
                            Material Invoice Details
                            <span className='font-12 mt-1' style={{ float: 'right' }}>Page 3 of 4</span>
                          </h6>
                          <hr className='m-0' />

                          <div className='pt-3 px-4'>
                            {
                              this.state.form.vendorCode.partInvoicingEnabled ? <div className='info-alert'>Kindly note that you have part {this.state.form.POType.label} payment enabled for this account.</div> : ''
                            }

                            <form className="" autoComplete="off" method="post">
                              {this.state.checkedGRList.map((object, i) => {
                                return <div key={i}>
                                  <div className="" style={{ background: "#f2f2f230" }}>
                                    <div className='row mb-3'>
                                      <div className='col-sm-2 col-6'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>GR Number</label>
                                          <div className="bold form-control input-font">{object.grNo}</div>
                                        </div>
                                      </div>
                                      <div className='col-sm-1 col-6'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>Item</label>
                                          <div className="bold form-control input-font text-center">{object.grItemNo}</div>
                                        </div>
                                      </div>
                                      <div className='col-sm-3 col-12'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>Description</label>
                                          <div className="bold form-control input-font">{object.materialDescription}</div>
                                        </div>
                                      </div>
                                      <div className='col-sm-2 col-4'>
                                        {!this.state.form.vendorCode.partInvoicingEnabled ?
                                          <div className="form-group mb-0">
                                            <label className='font-12 input-overlay'>Qty</label>
                                            <div className="bold form-control input-font">{object.grQuantity}</div>
                                          </div>
                                          :
                                          <div className="form-group mb-0">
                                            <label className='font-12 input-overlay'>Qty: {formatAmount(object.grQuantity)}</label>
                                            <input type="number" step=".01" style={{ border: '1px solid #0556de' }} className="bold form-control input-font" value={object.partQty === '' ? '' : object.partQty === undefined ? object.grQuantity : object.partQty} onChange={(e) => this.handleChangeGRQty(e, object)} max={object.grQuantity} />
                                          </div>
                                        }
                                      </div>

                                      <div className='col-sm-3 col-6'>
                                        {!this.state.form.vendorCode.partInvoicingEnabled ?
                                          <div className="form-group mb-0">
                                            <label className='font-12 input-overlay'>Amount</label>
                                            <label className='input-currency bold'>{object.currency}</label>
                                            <div style={{ paddingLeft: '65px' }} className="bold form-control input-font">{formatAmount(object.grAmount)}</div>
                                          </div>
                                          :
                                          <div className="form-group mb-0">
                                            <label className='font-12 input-overlay'>GR Amount: {formatAmount(object.grAmount)}</label>
                                            <label className='input-currency bold'>{object.currency}</label>
                                            <input type="number" step=".01" style={{ paddingLeft: '65px', border: '1px solid #0556de' }} className="bold form-control input-font" value={object.partAmount === '' ? '' : object.partAmount === undefined ? object.grAmount : object.partAmount} onChange={(e) => this.handleChangeGRAmount(e, object)} min="1" max={object.grAmount} />
                                          </div>
                                        }
                                      </div>

                                      <div className='col-sm-1 col-2'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>Vat</label>
                                          <div  style={{border: '1px solid #0556de', height:'40px' }}className="bold form-control input-font">

                                            <div className="form-check form-switch d-none d-sm-block" style={{paddingLeft:'30px'}}>
                                              <input className="form-check-input" type="checkbox" checked={object.isTax === undefined ? true : object.isTax === true ? true : false} onChange={(e) => this.handleCheckedGRTAX(e, object)} />
                                            </div>

                                            <input className="form-check-input d-block d-sm-none" type="checkbox" checked={object.isTax === undefined ? true : object.isTax === true ? true : false} onChange={(e) => this.handleCheckedGRTAX(e, object)} />
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-12'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>Custom Description</label>                                          
                                          <input type="text" style={{ border: '1px solid #ced4da' }} className="bold form-control input-font" value={object.vendorCustomDescription} onChange={(e) => this.handleChangeGRDescription(e, object)} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr className='mt-0 mb-0' />
                                </div>
                              })
                              }
                            </form>

                            <div className='row'>
                              <div className='col-sm-6'></div>
                              <div className='col-sm-3 col-6'>
                                <div className="form-group mt-3 mb-5">
                                  <button type='button' onClick={this.gobackToPage} name="page_2" className='btn btn-danger font-12 border-radius-sm form-control'><PrevIcon /> Back</button>
                                </div>
                              </div>
                              <div className='col-sm-3 col-6'>
                                <div className="form-group mt-3 mb-5">
                                  <button type='button' onClick={this.GetBankDetails} className='btn btn-primary font-12 border-radius-sm form-control'>Next <NextIcon /></button>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </>
                    }
                    {/* =============> !END SCREEN 3B of 4 */}


                    {/* [4] Display Bank details */}
                    {this.state.hasSelectedCompanyAndInvoiceType && this.state.isBankAccount && !this.state.canShowPOform &&
                      <>
                        <div className="mt-3 shadow-sm bg-white">
                          <h6 className='py-3 px-4 mb-0 lead-txt'>
                            Vendor Bank Details
                            <span className='font-12 mt-1' style={{ float: 'right' }}>Page 4 of 4</span>
                          </h6>
                          <hr className='m-0' />

                          <div className='pt-3 px-4'>

                            <div  className='info-alert font-12 p-1 mt-1 mb-2'>If you desire a change/update to your registered bank details with us, please contact <i>{this.state.Setting.bankAccountChangeContactEmail}</i></div>

                            <form className="pb-0" autoComplete="off" method="post">
                              {this.state.bankDetails.map((object, i) => {
                                return <div key={i}>
                                  <div className="bank-account-item active mb-4">

                                    <div className='row mb-0'>
                                      <div className='col-sm-4 col-12'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>Bank Account Name</label>
                                          <div className="form-control input-font bold">{object.accountName}</div>
                                        </div>
                                      </div>
                                      <div className='col-sm-4 col-12'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>Bank Name</label>
                                          <div className="form-control input-font bold">{object.bankName}</div>
                                        </div>
                                      </div>
                                      <div className='col-sm-4 col-12'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>Bank Account Number</label>
                                          <div className="form-control input-font bold">{object.accountNumber}</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='row mb-0'>
                                      <div className='col-sm-4 col-12'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>Bank Sort Code</label>
                                          <div className="form-control input-font bold">{object.sortCode}</div>
                                        </div>
                                      </div>
                                      <div className='col-sm-4 col-12'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>Swift Code</label>
                                          <div className="form-control input-font bold">{object.swiftCode}</div>
                                        </div>
                                      </div>
                                      <div className='col-sm-4 col-12'>
                                        <div className="form-group mb-0">
                                          <label className='font-12 input-overlay'>Bank Currency</label>
                                          <div className="form-control input-font bold">{object.currency}</div>
                                        </div>
                                      </div>
                                    </div>
                                    <input onChange={this.setBankAccount} disabled={this.validateInvoiceCurrencies(this.state.invoiceCurrency,object.currency)} type="radio" value={i} id={'bankCheck' + i} name='bankCheck' className='bankCheckbox' checked={this.state.checkedBankDetails.accountNumber === object.accountNumber}  />
                                    <label htmlFor={'bankCheck' + i} className='bankChecklabel'></label>

                                  </div>
                                </div>
                              })
                              }
                            </form>

                            <div className='row'>
                              <div className='col-sm-6'></div>
                              <div className='col-sm-3 col-6'>
                                <div className="form-group mt-0 mb-4">
                                  <button type='button' onClick={this.gobackToPage} name="page_3" className='btn btn-danger font-12 border-radius-sm form-control'><PrevIcon /> Back</button>
                                </div>
                              </div>
                              <div className='col-sm-3 col-6'>
                                <div className="form-group mt-0 mb-4">
                                  <button type='button' onClick={this.showPreview} disabled={this.state.checkedBankDetails.accountNumber === ''} className='btn btn-primary font-12 border-radius-sm form-control'>Invoice Summary</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }


                    {/* [5A] Invoice Preview */}
                    {this.state.hasSelectedCompanyAndInvoiceType && !this.state.isBankAccount && !this.state.canShowPOform && this.state.canShowPreview &&
                      <>
                        <div className="mt-3 shadow-sm bg-white">
                          <h6 className='py-3 px-4 mb-0 lead-txt'>
                            SUMMARY
                            <span className='font-12 mt-1' style={{ float: 'right' }}>{this.state.form.vendorCode.label}</span>
                          </h6>
                          <hr className='m-0' />

                          <div className='second_row px-4 d-flex justify-content-between mb-3 mt-3 row'>
                            <div className='p-3 bolder font-12 col-sm-4 col-12 mpr-0'>
                              <h6 className='p-2 px-3 text-white' style={{ background: '#007bc2' }}>Vendor Details</h6>
                              <table>
                                <tbody>
                                  <tr style={{ borderBottom: '1px solid #f2f2f2' }}>
                                    <td style={{ paddingTop: '4px' }}>Name</td>
                                    <td style={{ paddingLeft: '25px', paddingTop: '4px' }}>{this.state.form.vendorCode.label}</td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #f2f2f2' }}>
                                    <td style={{ paddingTop: '4px' }}>Address</td>
                                    <td style={{ paddingLeft: '25px', paddingTop: '4px' }}>{this.state.form.vendorCode.address}</td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #f2f2f2' }}>
                                    <td style={{ paddingTop: '4px' }}>Phone</td>
                                    <td style={{ paddingLeft: '25px', paddingTop: '4px' }}>{this.state.form.vendorCode.phone}</td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #f2f2f2' }}>
                                    <td style={{ paddingTop: '4px' }}>Vendor Code</td>
                                    <td style={{ paddingLeft: '25px', paddingTop: '4px' }}>{this.state.form.vendorCode.value}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className='bolder font-12 col-sm-5 col-12 mt-4'>
                              <table className='table table-bordered'>
                                <tbody>
                                  <tr>
                                    <td className="p-1">Invoice Date</td>
                                    <td className="p-1" style={{ paddingLeft: '25px' }}>{new Date().toDateString()}</td>
                                  </tr>
                                  <tr>
                                    <td className="p-1">Invoice Reference</td>
                                    <td className="p-1" style={{ paddingLeft: '25px' }}>{this.state.form.referenceNumber}</td>
                                  </tr>
                                  <tr>
                                    <td className="p-1">Purchase Order No</td>
                                    <td className="p-1" style={{ paddingLeft: '25px' }}>{this.state.form.PONumber.value}</td>
                                  </tr>
                                  <tr>
                                    <td className="p-1">Currency</td>
                                    <td className="p-1" style={{ paddingLeft: '25px' }}>{this.state.checkedBankDetails.currency}</td>
                                  </tr>
                                  <tr>
                                    <td className="p-1">Total</td>
                                    <td className="p-1" style={{ paddingLeft: '25px' }}>
                                      {this.state.form.POType.value === 'service_po' ?
                                        formatAmount(this.state.checkedSESList.map(x => x.partAmount ? x.partAmount : x.sesAmount).reduce((p, v) => Number(p) + Number(v))) :
                                        formatAmount(this.state.checkedGRList.map(x => x.partAmount ? x.partAmount : x.grAmount).reduce((p, v) => Number(p) + Number(v)))
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className='px-4 mb-4 mt-4'>
                            <h6 className='col-sm-4 col-12 p-2 px-3 text-white' style={{ background: '#007bc2' }}>Bank Details</h6>
                            <div className='col-8'></div>

                            <div className='col-sm-4 col-12'>
                              <p className='mb-2 font-12'>Payment would be paid into the bank account details below</p>
                              <table className='font-12'>
                                <tbody>
                                  <tr>
                                    <td style={{ width: '40%' }}>Bank Name</td>
                                    <td style={{ paddingLeft: '25px' }}>{this.state.checkedBankDetails.bankName}</td>
                                  </tr>
                                  <tr>
                                    <td>Account Number</td>
                                    <td style={{ paddingLeft: '25px' }}>{this.state.checkedBankDetails.accountNumber}</td>
                                  </tr>
                                  <tr>
                                    <td>BC / Swift Code</td>
                                    <td style={{ paddingLeft: '25px' }}>{this.state.checkedBankDetails.swiftCode}</td>
                                  </tr>
                                  <tr>
                                    <td>Sort Code</td>
                                    <td style={{ paddingLeft: '25px' }}>{this.state.checkedBankDetails.sortCode}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className='px-4 mb-3'>
                            <div className='table-responsive'>
                              {this.state.form.POType.value === 'service_po' ?
                                <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                                  <thead className='text-white' style={{ background: '#0556de' }}>
                                    <tr className='text-uppercase'>
                                      <th style={{ width: '55%' }}>Description</th>
                                      <th>SES No.</th>
                                      <th>Item No.</th>
                                      <th>VAT</th>
                                      <th style={{ textAlign: "right" }} className="">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      this.state.checkedSESList.map((object, i) => {
                                        return <tr key={i}>
                                          <td>{object.sesItemDescription}</td>
                                          <td>{object.sesNo}</td>
                                          <td>{object.sesItemNo}</td>
                                          <td>
                                            <div className="form-check form-switch">
                                              <input className="form-check-input" type="checkbox" checked={object.isTax === undefined ? true : object.isTax === true ? true : false} onChange={(e) => this.handleCheckedSESTAX(e, object)} />
                                            </div>
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            {
                                              object.partAmount ?
                                                <><small style={{ color: 'gray' }}><span className='strike'>{formatAmount(object.sesAmount)}</span></small>&nbsp; <span>{formatAmount(object.partAmount)}</span></>
                                                :
                                                formatAmount(object.sesAmount)
                                            }
                                          </td>
                                        </tr>
                                      })
                                    }
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className='bold text-white' style={{ background: '#0556de' }}>TOTAL</td>
                                      <td className='bold text-white' style={{ textAlign: "right", background: '#0556de' }} >

                                        {
                                          this.state.checkedSESList.map(x => x.partAmount ? x.partAmount : x.sesAmount).reduce((p, v) => Number(p) + Number(v)) !== this.state.checkedSESList.map(x => x.sesAmount).reduce((p, v) => Number(p) + Number(v)) ?
                                            <>
                                              <small style={{ color: '#d3d3d3' }}>
                                                <span className='strike'>
                                                  {formatAmount(this.state.checkedSESList.map(x => x.sesAmount).reduce((p, v) => Number(p) + Number(v)))}
                                                </span>
                                              </small>&nbsp;
                                            </>
                                            : ''
                                        }

                                        {formatAmount(this.state.checkedSESList.map(x => x.partAmount ? x.partAmount : x.sesAmount).reduce((p, v) => Number(p) + Number(v)))}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                :
                                <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                                  <thead className='text-white' style={{ background: '#0556de' }}>
                                    <tr className='text-uppercase'>
                                      <th style={{ width: '35%' }}>Description</th>
                                      <th>GR Number</th>
                                      <th>GR Item Number</th>
                                      <th>GR Quantity</th>
                                      <th>VAT</th>
                                      <th style={{ textAlign: "right" }} className="">GR Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      this.state.checkedGRList.map((object, i) => {
                                        return <tr key={i}>
                                          <td>{object.materialDescription}</td>
                                          <td>{object.grNo}</td>
                                          <td>{object.grItemNo}</td>
                                          <td>{
                                                object.partQty ?
                                                <>
                                                  <small style={{ color: 'gray' }}>
                                                    <span className='strike'>{formatAmount(object.grQuantity)}</span>
                                                  </small>
                                                  &nbsp; <span>{formatAmount(object.partQty)}</span>
                                                </>
                                                :
                                                formatAmount(object.grQuantity)
                                              }

                                          </td>
                                          <td>
                                            <div className="form-check form-switch">
                                              <input className="form-check-input" type="checkbox" checked={object.isTax === undefined ? true : object.isTax === true ? true : false} onChange={(e) => this.handleCheckedGRTAX(e, object)} />
                                            </div>

                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            {
                                              object.partAmount ?
                                                <><small style={{ color: 'gray' }}><span className='strike'>{formatAmount(object.grAmount)}</span></small>&nbsp; <span>{formatAmount(object.partAmount)}</span></>
                                                :
                                                formatAmount(object.grAmount)
                                            }
                                          </td>
                                        </tr>
                                      })
                                    }
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className='bold text-white' style={{ background: '#0556de' }}>TOTAL</td>
                                      <td className='bold text-white' style={{ textAlign: "right", background: '#0556de' }} >
                                        {
                                          this.state.checkedGRList.map(x => x.partAmount ? x.partAmount : x.grAmount).reduce((p, v) => Number(p) + Number(v)) !== this.state.checkedGRList.map(x => x.grAmount).reduce((p, v) => Number(p) + Number(v)) ?
                                            <>
                                              <small style={{ color: '#d3d3d3' }}>
                                                <span className='strike'>
                                                {formatAmount(this.state.checkedGRList.map(x => x.grAmount).reduce((p, v) => Number(p) + Number(v)))}
                                              </span></small>&nbsp;
                                            </>
                                            : ''
                                        }

                                        {formatAmount(this.state.checkedGRList.map(x => x.partAmount ? x.partAmount : x.grAmount).reduce((p, v) => Number(p) + Number(v)))}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              }
                            </div>
                          </div>

                          <div className='row px-4'>
                            <div className='col-sm-5'></div>
                            <div className='col-sm-2 col-6'>
                              <div className="form-group mt-0 mb-5">
                                <button type='button' onClick={this.gobackToPage} name="page_4" title="Go to previous page without saving" className='btn btn-danger font-12 border-radius-sm form-control'><PrevIcon /> Back</button>
                              </div>
                            </div>
                            <div className='col-sm-2 col-6'>
                              <div className="form-group mt-0 mb-5">
                                <button type='button' onClick={this.handleSaveInvoice} name="saveDraft" title="Save invoice to sign later" className='savedraftbtn btn btn-primary font-12 border-radius-sm form-control'>Save As Draft</button>
                              </div>
                            </div>
                            <div className='col-sm-3 col-12'>
                              <div className="form-group mt-0 mb-5">
                                <button type='button' onClick={this.handleSaveInvoice} name="saveSign" title="Save invoice" className='btn btn-primary font-12 border-radius-sm form-control'>Generate Final Invoice</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Invoice);