// # import plugins;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

// # import layouts, helpers, components;
import LoginAside from '../../layouts/LoginAside';
import Copyright  from '../../layouts/Copyright';
import withRouter from '../../../src/WithRouter';
import {handleInputChange, ajaxCall, closeAlert, errorAction, Spinner, htmlinputType, successAlert, errorAlert, passwordRules, Preloader } from '../../helpers/Helpers'


class Registration extends Component {
  componentDidMount() {
    this.GetAllSetting();
    this.pageLoad();
  }

  state = {
    isPasswordView:false,
    resetPasswordLink: window.location.origin+'/register',
    passwordToken:'',
    email:'',

    form:{
      email:'',
      newPassword:'',
      reNewPassword:'',
    },
    Setting:{},
  }

  // *! form input controller - binds data into state
  /** @param {any} e */
  handleInput = (e) => { handleInputChange(e, this); }

  pageLoad = () => {
    let queryString   = window.location.search;
    let urlParams     = new URLSearchParams(queryString);
    let email  = urlParams.get('Email');
    let passwordToken = urlParams.get('ResetPasswordToken');


    let preset_email = '';
    if(localStorage.getItem('email') != null){
      preset_email = localStorage.getItem('email');
      localStorage.removeItem('email');
    }

    this.setState(prevState => ({ form: {  ...prevState.form, email : preset_email }}))

    if(email && passwordToken)
      this.setState({email:email, passwordToken:passwordToken, isPasswordView:true});
  }

  // verify email address
  handleEmailVerification = (e) =>{
    e.preventDefault();

    let button_name = e.target.verifyBtn.innerHTML;
    e.target.verifyBtn.disabled = true;
    e.target.verifyBtn.innerHTML = Spinner;

    let data = {
      email: this.state.form.email,
      resetPasswordLink: this.state.resetPasswordLink,
      isFirstTimeUser: true
    }

    ajaxCall('AuthenticateVendor/SendResetPasswordToken', 'json', 'post', data, null).then(res => {
      closeAlert();
      e.target.verifyBtn.disabled = false;
      e.target.verifyBtn.innerHTML = button_name;
      successAlert(res.data.message);

      localStorage.setItem('title', 'Verification Successful!');
      localStorage.setItem('message',res.data.message);
      this.props.routerNavigate("/successful");
    })
    .catch(error => {
      errorAction(error);

      e.target.verifyBtn.disabled = false;
      e.target.verifyBtn.innerHTML = button_name;
    })
  }

  // create password
  handleCreateAccount = (e) =>{
    e.preventDefault();

    let newPassword   = this.state.form.newPassword;
    let renewPassword = this.state.form.reNewPassword;

    if(newPassword === ""){  errorAlert("password cannot be empty");  return;  }
    if(passwordRules(newPassword)){ errorAlert("Password must contain atleast one Uppercase, Lowercase, Digits and cannot be less than Eight Characters");  return; }
    if(newPassword !== renewPassword){ errorAlert("Password and confirm password did not match!");  return; }

    let form_data = {
      email: this.state.email,
      resetPasswordToken: this.state.passwordToken,
      newPassword: newPassword
    }

    let button_name = e.target.regBtn.innerHTML;
    e.target.regBtn.disabled = true;
    e.target.regBtn.innerHTML = Spinner;

    ajaxCall('AuthenticateVendor/ResetPassword', 'json', 'post', form_data, null).then(res => {

      e.target.regBtn.disabled = false;
      e.target.regBtn.innerHTML = button_name;

      successAlert('Your registration is successful, please login with your email and password!');
      this.props.routerNavigate("/login");
    })
    .catch(error => {
      errorAction(error);

      e.target.regBtn.disabled = false;
      e.target.regBtn.innerHTML = button_name;
    })
  }

  GetAllSetting= () =>{
    Preloader()
    ajaxCall('Setting', 'json', 'get', {}, null).then(res => {
      this.setState({ Setting: res.data.data });
      closeAlert();
    })
    .catch(error => {
      errorAction(error);
    })
  }


  render() {
    return (
        <div className='loginBG'>
          <div className='container-fluid'>
            <div className='row'>
              <LoginAside />

              <div className='col-sm-5 form-aside'>
                <div  className='mx-auto login-side-wrap'>

                  <h1 className='login-lead' style={{marginTop:'15%'}}>Register</h1>

                  {
                    !this.state.isPasswordView ?

                    <form className="login-form mt-4" autoComplete="off" method="post" onSubmit={this.handleEmailVerification}>
                        <h5>Step 1 of 2: Verify your email address</h5>
                        <hr />


                        <div className='info-alert font-12 p-2 mt-1' style={{textAlign:'justify'}}>
                          <u>What is the purpose of email verification?</u> <br />
                          <span>Email verification is necessary to ensure your email address has been confirmed and is ready for use. Please, contact support on <a href={'mailto:'+ DOMPurify.sanitize(this.state.Setting.supportContactEmail)}>{DOMPurify.sanitize(this.state.Setting.supportContactEmail)}</a> for any registration assistance.</span>
                        </div>

                        <div className="form-group">
                          <span className='input-overlay'><b className="fa fa-asterisk text-danger font-12"></b> Vendor Email </span>
                          <input type="email" name='email' value={this.state.form.email} onChange={this.handleInput} autoFocus className="form-control input-font p-3 curved-top" placeholder="Enter your email address"  required onPaste={(e)=>{e.preventDefault(); return false;}} onCopy={(e)=>{e.preventDefault(); return false;}} onDrop={(e)=>{e.preventDefault(); return false;}} onDragStart={(e)=>{e.preventDefault(); return false;}}/>
                          <i className="fa fa-envelope input-icon"></i>
                        </div>

                        <div className="form-group mt-3">
                          <button type='submit' name='verifyBtn' className='form-control btn btn-primary p-3 font-12'>Verify Email Address</button>
                        </div>
                    </form>

                    :

                    <form className="login-form mt-3" autoComplete="off" method="post" onSubmit={this.handleCreateAccount}>
                      <h5>Step 2 of 2: Create your account</h5>
                      <hr />
                      <label className='info-txt animated flipInY mb-1'>Password must contain at least one Uppercase, Lowercase, Digits and cannot be less than Eight Characters</label>


                        <div className="form-group">
                          <span className='input-overlay'><b className="fa fa-check text-success font-12"></b> Email Address </span>
                          <input type="email" disabled value={this.state.email} className="form-control input-font p-3 curved-top" placeholder="Enter your email"  onPaste={(e)=>{e.preventDefault(); return false;}} onCopy={(e)=>{e.preventDefault(); return false;}} onDrop={(e)=>{e.preventDefault(); return false;}} onDragStart={(e)=>{e.preventDefault(); return false;}} />
                          <i className="fa fa-check input-icon text-success"></i>
                        </div>

                        <div className="form-group mb-0">
                          <span className='input-overlay'><b className="fa fa-asterisk text-danger font-12"></b> Password </span>
                          <input type="password" name='newPassword' id='newPassword' value={this.state.form.newPassword} onChange={this.handleInput} autoFocus className="form-control input-font p-3 curved-top" autoComplete="off" placeholder="Enter your new password" required  onPaste={(e)=>{e.preventDefault(); return false;}} onCopy={(e)=>{e.preventDefault(); return false;}} onDrop={(e)=>{e.preventDefault(); return false;}} onDragStart={(e)=>{e.preventDefault(); return false;}} />

                          <i className='fa input-icon fa-eye' id='inputEye' onClick={() => htmlinputType('newPassword','inputEyeClose', 'inputEye')}></i>
                          <i className='fa input-icon fa-eye-slash' style={{display:'none'}} id='inputEyeClose' onClick={() => htmlinputType('newPassword','inputEye', 'inputEyeClose')}></i>
                        </div>

                        <div className="form-group">
                          <span className='input-overlay'><b className="fa fa-asterisk text-danger font-12"></b> Confirm Password </span>
                          <input type="password" name='reNewPassword' id='reNewPassword' value={this.state.form.reNewPassword} onChange={this.handleInput} className="form-control input-font p-3 curved-bottom" autoComplete="off" placeholder="Re-enter your new password" required onPaste={(e)=>{e.preventDefault(); return false;}} onCopy={(e)=>{e.preventDefault(); return false;}} onDrop={(e)=>{e.preventDefault(); return false;}} onDragStart={(e)=>{e.preventDefault(); return false;}} />

                          <i className='fa input-icon fa-eye' id='inputEye2' onClick={() => htmlinputType('reNewPassword','inputEyeClose2', 'inputEye2')}></i>
                          <i className='fa input-icon fa-eye-slash' style={{display:'none'}} id='inputEyeClose2' onClick={() => htmlinputType('reNewPassword','inputEye2', 'inputEyeClose2')}></i>
                        </div>

                        <div className="form-group mt-3">
                          <button type='submit' name='regBtn' className='form-control btn btn-primary p-3 font-12'>Register Account</button>
                        </div>
                    </form>
                  }

                  <div className="form-group mb-5 mt-2">
                      <span>Already have an account? <Link to="/login" className='bold-500'>Log in.</Link> </span>
                  </div>

                  <Copyright />
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(Registration);