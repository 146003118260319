import React, { Component } from 'react';

import SideNav              from '../../layouts/SideNav';
import TopNav               from '../../layouts/TopNav';
import PageTitle            from '../../layouts/PageTitle';


class Help extends Component {
  // # UI rendering;
  render() {
    return (
        <div className='mainApp'>
          <div className='container-fluid'>
            <div className='row'>
              <SideNav />
              
              <div className='main-content-wrap'>
                <TopNav />
                <PageTitle pagedata={{page:'Settings',breadcrumb:'Help'}}/>

                <div className='container mb-5'>
                  <div className='row'>

                    <div className='col-12 px-4 mb-5'>

                      <div className="mt-4 shadow bg-white">
                        <h6 className='py-3 px-4 mb-0 lead-txt'>
                          Quick Start Guide
                        </h6>
                        <hr className='m-0' />

                        <div className='py-3 px-4'>
                          <div className='row'>
                            <div className='col-sm-12'>
                              <p className='bold mb-0 font-14 bold'>1. View Quick Start Guide Document</p><hr className='p-0 m-0 mb-2' />
                              <a href='/pdf/VIMPPO.pdf' target='blank'>
                                <i className='fa fa-file-pdf-o' style={{fontSize: '16px', color:'blue'}}></i> &nbsp;                       
                                <i>Purchase Order Invoice Guide</i>
                              </a>, &nbsp;&nbsp;  
                              <a href='/pdf/VIMPFI.pdf' target='blank'>
                                <i className='fa fa-file-pdf-o' style={{fontSize: '16px', color:'blue'}}></i> &nbsp;                          
                                <i>Financial Invoice Guide</i>
                              </a>
                            </div>
                          </div>

                          <div className='row mt-5'>
                            <div className='col-12'>                          
                              <p className='bold mb-0 font-14 bold'>2. Watch Quick Start Video</p><hr className='p-0 m-0 mb-2' />
                            </div>
                            <div className='col-12 col-sm-12'>
                              <iframe width="100%" height="400" src="https://www.youtube.com/embed/M_RT2trXYx4" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                            <div className='col-12 col-sm-12'>
                              <iframe width="100%" height="400" src="https://www.youtube.com/embed/lMmw6bX1Ihs" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                            </div>
                          </div>
                        </div>
                        
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default Help;