import React, { Component } from 'react';

import withRouter from '../../../src/WithRouter';
import SideNav from '../../layouts/SideNav';
import TopNav from '../../layouts/TopNav';
import PageTitle from '../../layouts/PageTitle';
import { ajaxCall, errorAction, alertMessage, passwordRules, htmlinputType, alertPrompt, logout } from '../../helpers/Helpers'


class ChangePassword extends Component {
  state = {
    form:{
      OldPassword: '',
      NewPassword: '',
      ConfirmPassword:'',
    }
  }

  handleFormInputsChange = (e) =>{ this.setState(prevState => ({ form: {  ...prevState.form, [e.target.name] : e.target.type === 'checkbox' ? e.target.checked : e.target.value }}))}

  ChangePassword = (e) =>{
    e.preventDefault();

    let NewPassword = this.state.form.NewPassword;
    let OldPassword = this.state.form.OldPassword;
    let confirmpassword = this.state.form.ConfirmPassword;

    if(passwordRules(this.state.form.NewPassword)){ 
      alertMessage("Password must contain atleast an uppercase, lowercase, numbers and cannot be less than eight characters"); 
      return;
    }
    if(NewPassword !== confirmpassword){ 
      alertMessage("Password and confirm password do not match!"); 
      return;
    }

    if(NewPassword === confirmpassword){
      let form_data = {
        OldPassword: OldPassword,
        NewPassword: NewPassword
      }

      ajaxCall('AuthenticateVendor/ChangePassword', 'json', 'post', form_data).then(res => {
        alertPrompt(`${res.data.message} <br /> Click Ok to login`).then((result) => {
          if (result.isConfirmed) { 
            logout();           
          }
        })                        
      })
      .catch(error => {
        errorAction(error)
      })
    }
  }

  render() {
    return (
        <div className='mainApp'>
          <div className='container-fluid'>
            <div className='row'>
              <SideNav />
              
              <div className='main-content-wrap'>
                <TopNav />
                <PageTitle pagedata={{ page: 'Change Password', breadcrumb: 'Change Password' }} />

                <div className='container mb-5'>
                  <div className='row'>

                    <div className='col-12 px-4'>
                      <div className="mt-4 shadow-sm bg-white">

                        <h6 className='py-3 px-4 mb-0 lead-txt'>
                          Change your password
                        </h6>
                        <hr className='m-0' /> 

                        <div className='py-3 px-4'>               
                          <form className="login-form" autoComplete="off" method="post" onSubmit={this.ChangePassword}>
                              <input type="hidden" autoComplete="off" />

                              <div className='row mt-3'>
                                <div className='col-2'>
                                  <label className='font-12 bolder'>Enter old password: </label>
                                </div>
                                <div className='col-10'>
                                  <div className="form-group">
                                    <input type="password" name='OldPassword' id='OldPassword' value={this.state.form.OldPassword} onChange={this.handleFormInputsChange} className="form-control input-font" autoComplete="off" placeholder="Enter your old password" required onPaste={(e)=>{e.preventDefault(); return false;}} onCopy={(e)=>{e.preventDefault(); return false;}} onDrop={(e)=>{e.preventDefault(); return false;}} onDragStart={(e)=>{e.preventDefault(); return false;}} />

                                    <i className='fa input-icon fa-eye' id='inputEyeO' onClick={() => htmlinputType('OldPassword','inputEyeCloseO', 'inputEyeO')} style={{top:'5px'}}></i>
                                    <i className='fa input-icon fa-eye-slash' style={{display:'none',top:'5px'}} id='inputEyeCloseO' onClick={() => htmlinputType('OldPassword','inputEyeO', 'inputEyeCloseO')}></i>
                                  </div>
                                </div>
                              </div>
                              
                              <div className='row mt-3'>
                                <div className='col-2'>
                                  <label className='font-12 bolder'>New password:</label>
                                </div>
                                <div className='col-10'>
                                  <div className="form-group">
                                    <input type="password" name='NewPassword' id='NewPassword' value={this.state.form.NewPassword} onChange={this.handleFormInputsChange} className="form-control input-font" autoComplete="off" placeholder="Enter your new password" required onPaste={(e)=>{e.preventDefault(); return false;}} onCopy={(e)=>{e.preventDefault(); return false;}} onDrop={(e)=>{e.preventDefault(); return false;}} onDragStart={(e)=>{e.preventDefault(); return false;}} />

                                    <i className='fa input-icon fa-eye' id='inputEye' style={{top:'5px'}} onClick={() => htmlinputType('NewPassword','inputEyeClose', 'inputEye')}></i>
                                    <i className='fa input-icon fa-eye-slash' style={{display:'none',top:'5px'}} id='inputEyeClose' onClick={() => htmlinputType('NewPassword','inputEye', 'inputEyeClose')}></i>
                                  </div>
                                </div>
                              </div>
                              
                              <div className='row mt-3'>
                                <div className='col-2'>
                                  <label className='font-12 bolder'>Confirm new password:</label>
                                </div>
                                <div className='col-10'>
                                  <div className="form-group">
                                    <input type="password" name='ConfirmPassword' id='ConfirmPassword' value={this.state.form.ConfirmPassword} onChange={this.handleFormInputsChange} className="form-control input-font" autoComplete="off" placeholder="Re-enter your new password" required onPaste={(e)=>{e.preventDefault(); return false;}} onCopy={(e)=>{e.preventDefault(); return false;}} onDrop={(e)=>{e.preventDefault(); return false;}} onDragStart={(e)=>{e.preventDefault(); return false;}} />

                                    <i className='fa input-icon fa-eye' id='inputEye2' style={{top:'5px'}} onClick={() => htmlinputType('ConfirmPassword','inputEyeClose2', 'inputEye2')}></i>
                                    <i className='fa input-icon fa-eye-slash' style={{display:'none',top:'5px'}} id='inputEyeClose2' onClick={() => htmlinputType('ConfirmPassword','inputEye2', 'inputEyeClose2')}></i>
                                    
                                    <label className='info-txt form-control mt-1 animated fadeInUp'>Password must contain atleast one Uppercase, Lowercase, Digits and cannot be less than Eight Characters</label> 
                                  </div>
                                </div>
                              </div>

                              <div className='row'>
                                <div className='col-sm-9'></div>
                                <div className='col-12 col-sm-3'>
                                  <div className="form-group mt-3 mb-5">
                                    <button type='submit' className='btn btn-primary font-12 border-radius-sm form-control'>Change Password</button>
                                  </div> 
                                </div>
                              </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}


export default withRouter(ChangePassword);